import { call, all, put, takeLatest, take } from "redux-saga/effects";

import { setQuizById, quizTypes as types } from "../actions/quiz";
import { AxiosResponse } from "axios";
import {
  getQuizByIdService,
  submitQuizAnswersService,
} from "../../../services/learner/quiz";
import { EntityIdPayload } from "../../../utils";
import { getLearnerStatistics } from "../actions";

export default function* educatorQuizSagas() {
  yield all([
    takeLatest(types.GET_QUIZ_BY_ID, handleGetQuizById),
    takeLatest(types.SUBMIT_QUIZ_ANSWERS, handleSubmitQuizAnswers),
  ]);
}

function* handleGetQuizById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getQuizByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setQuizById({ quiz: data }));
  }
}

function* handleSubmitQuizAnswers(action: any) {
  const { data }: AxiosResponse = yield call(
    submitQuizAnswersService,
    action.payload.id,
    action.payload.answers
  );
  if (action.payload.isLastLesson && data.passed)
    action.payload.navigate(`/success/user/course`, {
      state: { points: action.payload.coursePoints },
    });
  else
    action.payload.navigate("/user/quiz/results", {
      state: {
        passed: data.passed,
        id: action.payload.courseId,
        minimumCorrectAnswers: action.payload.minimumCorrectAnswers,
      },
    });
  yield put(getLearnerStatistics());
}
