import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  getCompanyById,
  editCompany,
  deleteCompany,
  deleteAdmin,
} from "../../../../redux/owner/actions";
import {
  selectCompany,
  selectCompaniesLoading,
} from "../../../../redux/owner/selector";
import { signUp } from "../../../../redux/educator/actions";

const mapStateToProps = (state: StateType) => ({
  company: selectCompany(state),
  loading: selectCompaniesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCompanyById,
      editCompany,
      deleteCompany,
      signUp,
      deleteAdmin,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
