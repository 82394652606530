import { produce } from "immer";

import { companyTypes as types } from "../actions";
import { OwnerCompanyStateType } from "../../../utils";

const defaultState: OwnerCompanyStateType = {
  companies: [],
  company: null,
  loading: false,
  loaded: false,
};

const handleSetCompany = (state: any, payload: any) => {
  state.company = payload.company;
  state.loading = false;
  return state;
};

const handleSetCompanies = (state: any, payload: any) => {
  state.companies = payload.companies;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetDeleteAdmin = (state: any, payload: any) => {
  state.company = {
    ...state.company,
    users: state.company.users.filter(
      (user: any) => user.id !== payload.deletedUserId
    ),
  };
  state.loading = false;
  return state;
};

export default produce(
  (state: OwnerCompanyStateType = defaultState, action) => {
    switch (action.type) {
      case types.OWNER_SET_COMPANY:
        return handleSetCompanies(state, action.payload);
      case types.OWNER_SET_COMPANY_BY_ID:
        return handleSetCompany(state, action.payload);
      case types.OWNER_SET_DELETE_ADMIN:
        return handleSetDeleteAdmin(state, action.payload);
      case types.OWNER_GET_COMPANY_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case types.OWNER_GET_COMPANY:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
