import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addAlertService = async (alertData: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.alert, alertData));

export const getAlertService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.alert));

export const deleteAlertByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.alertById.replace(":id", id))
  );

export const getAlertByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.alertById.replace(":id", id)));
