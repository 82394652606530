import { produce } from "immer";

import { alertTypes as types } from "../actions";
import { EducatorAlertStateType } from "../../../utils";

const defaultState: EducatorAlertStateType = {
  alerts: [],
  alert: null,
  loading: false,
  loaded: false,
};

const handleSetAlerts = (state: any, payload: any) => {
  state.alerts = payload.alerts;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetAlertById = (state: any, payload: any) => {
  state.alert = payload.alert;
  state.loading = false;
  return state;
};

export default produce(
  (state: EducatorAlertStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_ALERT:
        return handleSetAlerts(state, action.payload);
      case types.SET_ALERT_BY_ID:
        return handleSetAlertById(state, action.payload);
      case types.GET_ALERT:
        return {
          ...state,
          loading: true,
        };
      case types.GET_ALERT_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
