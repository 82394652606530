export const departmentTypes = {
  GET_DEPARTMENT: "GET_DEPARTMENT",
  GET_DEPARTMENT_BY_ID: "GET_DEPARTMENT_BY_ID",
  SET_DEPARTMENT: "SET_DEPARTMENT",
  SET_DEPARTMENT_BY_ID: "SET_DEPARTMENT_BY_ID",
  GET_DEPARTMENT_USER: "GET_DEPARTMENT_USER",
  SET_DEPARTMENT_USER: "SET_DEPARTMENT_USER",
};

export const getDepartment = () => ({
  type: departmentTypes.GET_DEPARTMENT,
});

export const setDepartment = (payload: any) => ({
  type: departmentTypes.SET_DEPARTMENT,
  payload,
});

export const getDepartmentById = (id: any) => ({
  type: departmentTypes.GET_DEPARTMENT_BY_ID,
  payload: id,
});

export const setDepartmentById = (payload: any) => ({
  type: departmentTypes.SET_DEPARTMENT_BY_ID,
  payload,
});

export const getDepartmentUser = () => ({
  type: departmentTypes.GET_DEPARTMENT_USER,
});

export const setDepartmentUser = (payload: any) => ({
  type: departmentTypes.SET_DEPARTMENT_USER,
  payload,
});
