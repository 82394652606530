import { call, all, put, takeLatest, take } from "redux-saga/effects";

import {
  setModule,
  setModuleById,
  moduleTypes as types,
  getModule,
} from "../actions";
import axios, { AxiosResponse } from "axios";
import { resetStepper } from "../../shared/actions";
import {
  addModuleService,
  getModuleService,
  getModuleByIdService,
  assignCoursesService,
  deleteModuleByIdService,
  editModuleService,
} from "../../../services/educator/module";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorModuleSagas() {
  yield all([
    takeLatest(types.ADD_MODULE, handleAddModule),
    takeLatest(types.GET_MODULE, handleGetModule),
    takeLatest(types.GET_MODULE_BY_ID, handleGetModuleById),
    takeLatest(types.DELETE_MODULE, handleDeleteModule),
    takeLatest(types.EDIT_MODULE, handleEditModule),
    takeLatest(types.ASSIGN_COURSE, handleAssignCourses),
  ]);
}

function* handleAddModule(action: any) {
  const { data }: AxiosResponse = yield call(
    addModuleService,
    action.payload.module
  );
  if (data) {
    yield put(getModule());
    action.payload.navigate("/educator/module/" + data.id + "/assign-courses");
  }
}

function* handleGetModule(action: any) {
  const { data }: AxiosResponse = yield call(
    getModuleService,
    action?.payload?.query
  );
  if (data) {
    yield put(setModule({ modules: data }));
  }
}

function* handleGetModuleById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getModuleByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setModuleById({ module: data }));
  }
}

function* handleDeleteModule(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteModuleByIdService,
    action.payload.id
  );
  if (data) {
    yield put(getModule());
    showNotification("success", "Module deleted successfully!");
    action.payload.navigate("/educator/module");
  }
}

function* handleAssignCourses(action: any) {
  const { data }: AxiosResponse = yield call(
    assignCoursesService,
    action.payload.moduleId,
    action.payload.courseIds
  );
  if (data) {
    yield put(getModule());
    action.payload.navigate("/success/module", {
      state: { isEdit: action.payload.isEdit },
    });
  }
}

function* handleEditModule(action: any) {
  const { data }: AxiosResponse = yield call(
    editModuleService,
    action.payload.id,
    action.payload.module
  );
  if (data) {
    showNotification("success", "Module edited successfully!");
    yield put(getModule());
    if (action.payload.navigateRoute === "assign-courses") {
      const { data: moduleData }: AxiosResponse = yield call(
        getModuleByIdService,
        action.payload.id
      );
      action.payload.navigate(
        `/educator/module/${action.payload.id}/assign-courses`,
        {
          state: { isEdit: true, module: moduleData },
        }
      );
    } else {
      yield put(resetStepper());
      action.payload.navigate("/educator/module");
    }
  }
}
