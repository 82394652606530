import { useState, useEffect } from "react";
import { Row, Col, Typography, Image, Skeleton, Spin, Modal } from "antd";
import VideoPlayer from "./VideoPlayer";
import DocumentCard from "../../../../shared/DocumentCard";
import ActionButtons from "./ActionButtons";
import { ReactComponent as PlayIcon } from "../../../../../assets/images/play-media.svg";
import AiButton from "../../../../shared/Ai/AiButton";
import AiTextTyping from "../../../../shared/Ai/AiTextTyping";
import testImage from "../../../../../assets/images/default-cover-image.jpg";
import AIChat from "../../../../shared/AIChat";

const { Text } = Typography;

const Lesson = ({
  lesson,
  handleVideoEnd,
  handleDocumentOpen,
  handleQuizToggle,
  handleNextLesson,
  handleGetSummary,
  getPresignedUrl,
  removePresignedUrl,
  showLessonSummary,
  lessonSummary,
  loading,
  videoWatched,
  documentOpened,
  isLastLesson,
  presignedUrl,  
}: {
  lesson: any;
  videoWatched: boolean;
  documentOpened: boolean;
  isLastLesson: boolean;
  showLessonSummary: boolean;
  lessonSummary: string | null;
  loading: boolean;
  presignedUrl: string | null;
  handleVideoEnd: () => void;
  handleDocumentOpen: () => void;
  handleQuizToggle: () => void;
  handleNextLesson: () => void;
  handleGetSummary: () => void;
  getPresignedUrl: ({ fileKey }: { fileKey: string }) => void;
  removePresignedUrl: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AIChatOpen, setAIChatOpen] = useState(false);

  const handleDocumentOpenInternal = () => {
    setIsModalVisible(true);
    handleDocumentOpen();
  };

  const handleDocumentDownload = () => {
    getPresignedUrl({ fileKey: lesson.fileKey });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (presignedUrl) {
      fetch(presignedUrl).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const fileName =
            lesson.fileKey?.split("-")[lesson.fileKey?.split("-").length - 1];
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
        });
      });
      removePresignedUrl();
      handleDocumentOpen();
    }
  }, [presignedUrl]);

  if (!lesson) {
    return <Skeleton active />;
  }

  return (
    <>
    <Row className="pb-[60px]">
      <Col lg={18} sm={24} className="lg:pr-4">
        <div className="p-6 shadow-md rounded-lg bg-white">
          <div className="flex gap-3 mb-6">
            <PlayIcon className="min-w-5" />
            <Text className="text-neutral-600 font-semibold text-base min-w-fit">
              Lesson {String(lesson.orderIndex).padStart(2, "0")}
            </Text>
            <Text className="text-neutral-600 text-base">{lesson.title}</Text>
          </div>
          {lesson.videoUri ? (
            lesson.videoStatus !== "AVAILABLE" &&
            lesson.videoStatus !== "AVAILABLE_WITHOUT_TRANSCRIPTION" ? (
              <div className="flex items-center justify-center h-36">
                <div className="flex flex-col gap-6">
                  <Spin size="large" />
                  <Text className="text-neutral-600 font-semibold text-sm mb-4 block">
                    Video is processing
                  </Text>
                </div>
              </div>
            ) : (
              <VideoPlayer videoUri={lesson.videoUri} onEnd={handleVideoEnd} />
            )
          ) : (
            <Image
              src={
                lesson.coverImageKey
                  ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${lesson.coverImageKey}`
                  : testImage
              }
              alt="lesson cover"
              className="w-full h-96 object-cover rounded-lg"
            />
          )}
        </div>
        {lessonSummary && videoWatched && !loading && (
          <>
            {!showLessonSummary && (
              <div className=" w-full flex justify-center my-5">
                <AiButton
                  disabled={loading || showLessonSummary}
                  handleOnClick={handleGetSummary}
                  content={"Summarize with AI"}
                />
              </div>
            )}
            {showLessonSummary && (
              <>
              <div className=" w-full flex justify-center my-5">
                <AiButton
                disabled={false}
                  handleOnClick={() => setAIChatOpen(true)}
                  content={"AI Chat"}
                />
              </div>
              <div className="mt-5">
                <Text className="text-primary-600 font-semibold text-base">
                  AI Summary
                </Text>
                <div className=" overflow-y-auto p-4 bg-white rounded-lg mt-2 shadow-md">
                  <Text className="text-neutral-600 text-base">
                    <AiTextTyping text={lessonSummary} delay={30} />
                  </Text>
                </div>
              </div>
              </>
            )}
          </>
        )}

        {!lessonSummary && (
          <>
            {videoWatched && lesson.videoStatus === "AVAILABLE" && (
              <div className="w-full flex justify-center mt-5 text-teal-300">
                <AiButton
                  disabled={loading || showLessonSummary}
                  handleOnClick={handleGetSummary}
                  content={
                    <>
                      {loading ? (
                        <div className="ai-dots-loader"></div>
                      ) : (
                        "Summarize with AI"
                      )}
                    </>
                  }
                />
              </div>
            )}
          </>
        )}

        {lesson.description && (
          <div className="mt-5">
            <Text className="text-primary-600 font-semibold text-base">
              Description
            </Text>
            <div className=" overflow-y-auto p-4 bg-white rounded-lg mt-2 shadow-md">
              <Text className="text-neutral-600 text-base">
                {lesson.description}
              </Text>
            </div>
          </div>
        )}
      </Col>
      <Col lg={6} sm={24} className="lg:pl-4 flex flex-col justify-between">
        <div className="flex flex-col gap-4 max-h-[400px]">
          {lesson.fileKey && (
            <>
              <Text className="text-primary-600 font-semibold text-base">
                Additional documents
              </Text>
              <DocumentCard
                fileKey={lesson.fileKey}
                onOpen={handleDocumentOpenInternal}
                onDownload={handleDocumentDownload}
              />
            </>
          )}
        </div>

        <ActionButtons
          onQuizClick={handleQuizToggle}
          onNextLessonClick={handleNextLesson}
          showQuizButton={!!lesson.quiz}
          isQuizDisabled={
            (!videoWatched && !!lesson.videoUri) ||
            (!documentOpened && !!lesson.fileKey)
          }
          isNextLessonDisabled={
            (lesson.videoUri && !videoWatched) ||
            (lesson.fileKey && !documentOpened) ||
            (lesson.quiz && lesson.quiz.isMandatory)
          }
          isLastLesson={isLastLesson}
        />
      </Col>

      <Modal
        title="Document Viewer"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="80%"
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <iframe
          src={`${process.env.REACT_APP_CLOUDFRONT_KEY}/${lesson.fileKey}`}
          title="Document Viewer"
          style={{ height: "70vh", width: "100%" }}
        />
      </Modal>
      </Row>
      <Modal
        open={AIChatOpen}
        onCancel={() => setAIChatOpen(false)}
        footer={null}
        width="80%"
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
      <AIChat summary={lessonSummary} setAIChatOpen={setAIChatOpen}/>
    </Modal>
    </>
  );
};

export default Lesson;
