import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDepartments,
  selectPositions,
} from "../../../../redux/educator/selector";
import {
  departmentTypes,
  positionTypes,
} from "../../../../redux/educator/actions";
import AssignmentUser from "./AssignmentUser";

const AssignedToList = ({
  departmentIds,
  positionIds,
  userIds,
  users,
}: {
  departmentIds: string[];
  positionIds: string[];
  userIds: string[];
  users: any;
}) => {
  const dispatch = useDispatch();
  const departments = useSelector(selectDepartments);
  const positions = useSelector(selectPositions);

  const [departmentUsers, setDepartmentUsers] = useState<any>(null);
  const [positionUsers, setPositionUsers] = useState<any>(null);
  const [userUsers, setUserUsers] = useState<any>(null);
  const [allUsers, setAllUsers] = useState<any>(null);

  useEffect(() => {
    dispatch({
      type: departmentTypes.GET_DEPARTMENT_USER,
    });
    dispatch({
      type: positionTypes.GET_POSITION_USER,
    });
    const filteredUsers = users?.filter((user: any) =>
      userIds.includes(user.id)
    );

    setUserUsers(filteredUsers);
  }, []);

  useEffect(() => {
    if (departments?.length === 0) {
      setDepartmentUsers([]);
      return;
    }
    let departmentUsers: any = [];

    const filteredDepartments = departments?.filter(
      (department: any) => departmentIds?.includes(department.id) && department
    );

    filteredDepartments?.forEach((dep) => {
      dep?.users?.forEach((user: any) => departmentUsers.push(user));
    });
    setDepartmentUsers(departmentUsers);
  }, [departments]);

  useEffect(() => {
    if (positions?.length === 0) {
      setPositionUsers([]);
      return;
    }
    let positionsUsers: any = [];

    const filteredPositions = positions?.filter(
      (position: any) => positionIds?.includes(position.id) && position
    );

    filteredPositions?.forEach((pos) => {
      pos?.users?.forEach((user: any) => positionsUsers.push(user));
    });
    setPositionUsers(positionsUsers);
  }, [positions]);

  useEffect(() => {
    if (!departmentUsers || !positionUsers || !userUsers) return;

    setAllUsers(
      [...departmentUsers, ...positionUsers, ...userUsers].filter(
        (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
      )
    );
  }, [departmentUsers, positionUsers, userUsers]);

  return (
    allUsers && (
      <>
        <div className="text-neutral-600 text-sm">
          {allUsers.length > 0 ? "Assigned to" : "No Users"}
        </div>
        {allUsers.length > 0 && (
          <div className="mt-3">
            {allUsers.map((user: any, index: number) => (
              <AssignmentUser key={index} user={user} />
            ))}
          </div>
        )}
      </>
    )
  );
};

export default AssignedToList;
