import { useState, ChangeEvent, KeyboardEvent,useEffect,useRef } from "react";
import jsPDF from "jspdf";
import { DownloadOutlined } from "@ant-design/icons";
import "../../assets/fonts/calibri-normal"
import "../../assets/fonts/calibri-bold"
import StorageService from "../../services/storageService";
import { Tooltip } from "antd";

interface ChatMessage {
  sender: "user" | "bot";
  text: string;
}

const AIChat = ({summary}:any) => {
  const [message, setMessage] = useState<string>("");
  const [chat, setChat] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    const container = bottomRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [chat,message]);

  const sendMessage = async () => {
    if (!message.trim()) return;

    setChat((prevChat) => [
      ...prevChat,
      { sender: "user", text: message}
    ]);
    setMessage("");
    setLoading(true); 

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${StorageService.getStringItem("accessToken") as string
    }`
      },
      body: JSON.stringify({ message: message, summary: summary }) 
    });

    if (!response.body) {
      throw new Error("Response body is null");
    }
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    while (true) {
      const { value, done } = await reader.read();
      setLoading(false);
      if (done) {
        break;
      }

    setChat((prevChat) => {
      if (prevChat.length > 0 && prevChat[prevChat.length - 1].sender === "bot") {
       const updatedChat = [...prevChat];
        updatedChat[updatedChat.length - 1] = {
          ...updatedChat[updatedChat.length - 1],
          text: updatedChat[updatedChat.length - 1].text + value,
        };
        return updatedChat;
      } else {
        return [
          ...prevChat,
          { sender: "bot", text: value },
        ];
      }
    });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.addFont('calibri-normal.ttf', 'calibri', 'normal');
    doc.addFont('calibri-bold.ttf', 'calibri', 'bold');

    doc.setFontSize(12);

    const pageHeight = doc.internal.pageSize.height;
    const marginTop = 10;
    const marginBottom = 10;
    const lineHeight = 7;
    const spacingBetweenMessages = 14;

    let y = marginTop;

    chat.forEach((msg) => {
        const sender = msg.sender === "user" ? "You:" : "Bot:";

        if (y + lineHeight > pageHeight - marginBottom) {
            doc.addPage();
            y = marginTop;
        }

        doc.setFont('calibri', 'bold');
        doc.text(sender, 10, y);

        doc.setFont('calibri', 'normal');
        const splitText = doc.splitTextToSize(msg.text, 170); 

        splitText.forEach((line:any) => {
            if (y + lineHeight > pageHeight - marginBottom) {
                doc.addPage();
                y = marginTop;
                doc.setFont('calibri', 'normal');
            }
            doc.text(line, 30, y);
            y += lineHeight;
        });

        y += spacingBetweenMessages;
    });

    doc.save("chat_history.pdf");
  };
 
  return (
    <div className="max-w-3xl w-full mx-auto  font-sans relative">
     
      <button
  onClick={downloadPDF}
  disabled={chat.length === 0}
  className={`absolute right-0 top-0 p-2 rounded-xl ${
    chat.length === 0
      ? "bg-gray-300 cursor-not-allowed"
      : "bg-gradient-to-r from-primary-400 to-primary-600 hover:from-primary-500 hover:to-primary-700"
  } text-white shadow-lg transition`}
>
  <Tooltip title="Download PDF" placement="bottom">
    <span>
      <DownloadOutlined style={{ fontSize: '18px' }} />
    </span>
  </Tooltip>
</button>

  
      {/* Title */}
      <h2 className="text-center text-3xl font-extrabold text-gray-800 mb-6 tracking-tight">
       AI Chatbot
      </h2>
  
      {/* Chat Box */}
      <div
      ref={bottomRef}
       className="min-h-[450px] max-h-[450px] border rounded-3xl p-5 overflow-y-auto bg-white/60 backdrop-blur-md shadow-xl">
        {chat.map((c, i) => (
          <div
            key={i}
            className={`my-3 flex ${
              c.sender === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`inline-block px-4 py-3 rounded-2xl text-sm max-w-[70%] break-words shadow ${
                c.sender === "user"
                  ? "bg-gradient-to-br from-primary-400 to-primary-600 text-white"
                  : "bg-gray-100 text-gray-800"
              }`}
            >
              {c.text}
            </div>
          </div>
        ))}
        {loading && (
          <div className="text-left text-sm italic text-gray-500 mt-2">
            Bot is typing...
          </div>
        )}
      </div>
  
      {/* Input & send */}
      <div className="flex items-center gap-3 mt-6">
        <input
          type="text"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="flex-1 px-4 py-3 rounded-xl border border-gray-300 bg-white/80 backdrop-blur-md text-sm focus:ring-2 focus:ring-purple-500 focus:outline-none shadow"
          placeholder="Type your message..."
        />
        <button
          onClick={sendMessage}
          className="px-10 py-3 rounded-xl bg-gradient-to-r from-primary-400 to-primary-600 hover:from-primary-500 hover:to-primary-700 text-white font-semibold text-sm shadow-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
  
}

export default AIChat;