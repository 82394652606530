import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Row,
  Col,
  Form,
  Checkbox,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const EditUserScreen = (props: any) => {
  const {
    user,
    departments,
    positions,
    actions: { getDepartment, getPosition, getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const { id } = useParams();
  const [hasAccessToAllDepartments, setHasAccessToAllDepartments] =
    useState(false);
  const [hasAccessToAllPositions, setHasAccessToAllPositions] = useState(false);
  const [hasAccessToAllUsers, setHasAccessToAllUsers] = useState(false);

  useEffect(() => {
    getDepartment();
    getPosition();
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    setHasAccessToAllDepartments(user.hasAccessToAllDepartments);
    setHasAccessToAllPositions(user.hasAccessToAllPositions);
    setHasAccessToAllUsers(user.hasAccessToAllUsers);

    form.setFieldsValue({
      name: user.name || null,
      email: user.email,
      dateOfBirth: user.dateOfBirth ? dayjs(user.dateOfBirth) : null,
      department: user.hasAccessToAllDepartments ? [] : user.departmentIds,
      position: user.hasAccessToAllPositions ? [] : user.positionIds,
      hasAccessToAllDepartments: user.hasAccessToAllDepartments,
      hasAccessToAllPositions: user.hasAccessToAllPositions,
      hasAccessToAllUsers: user.hasAccessToAllUsers,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        if (!!values.name && !!values.name.trim())
          formData.append("name", values.name.trim());
        formData.append("email", values.email);
        if (values.dateOfBirth)
          formData.append(
            "dateOfBirth",
            values.dateOfBirth.format("YYYY-MM-DD")
          );

        if (values.department !== undefined && !hasAccessToAllDepartments) {
          values.department.length > 0
            ? values.department.forEach((departmentId: string) => {
                formData.append("departmentIds", departmentId);
              })
            : formData.append("departmentIds", null as any);
        }

        if (values.position !== undefined && !hasAccessToAllPositions) {
          values.position.length > 0
            ? values.position.forEach((positionId: string) => {
                formData.append("positionIds", positionId);
              })
            : formData.append("positionIds", null as any);
        }

        if (profilePicture) {
          formData.append("profilePicture", profilePicture);
        }

        if (user.role === "EDUCATOR") {
          formData.append(
            "hasAccessToAllDepartments",
            hasAccessToAllDepartments as any
          );
          formData.append(
            "hasAccessToAllPositions",
            hasAccessToAllPositions as any
          );
          formData.append("hasAccessToAllUsers", hasAccessToAllUsers as any);
        }

        editUser({ id, user: formData });
        setIsChanged(false);
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const validateDepartmentAndPosition = (_: any, value: any) => {
    const departmentIds = form.getFieldValue("department");
    const positionIds = form.getFieldValue("position");

    if (
      (!departmentIds || departmentIds.length === 0) &&
      (!positionIds || positionIds.length === 0) &&
      !hasAccessToAllDepartments &&
      !hasAccessToAllPositions
    ) {
      return Promise.reject(
        new Error("At least one department or one position must be selected")
      );
    }

    Object.keys(form.getFieldsValue()).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }

      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });

    return Promise.resolve();
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={
            <Link
              to="/admin/user"
              className="text-neutral-600 font-semibold text-sm"
            >
              <ArrowLeftOutlined className="text-neutral-300 mr-2" /> Back to
              users
            </Link>
          }
        />
      </Col>
      <Col lg={16} className="h-full flex flex-col max-w-[600px]">
        {user && (
          <>
            <div className="flex justify-center mb-4">
              <div className="relative">
                <Avatar size={150} icon={<UserOutlined />} src={avatarUrl} />
                <Upload
                  className="absolute bottom-0 right-0"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadChange}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  <Button
                    shape="circle"
                    icon={<CameraOutlined />}
                    className="bg-blue-500 text-white"
                  />
                </Upload>
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
                department: user.departmentId,
                position: user.positionId,
              }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: !!user.name, message: "Please input user name!" },
                  {
                    validator: (_, value) => {
                      if (!!value && value.trim() === "") {
                        return Promise.reject(
                          new Error("Name cannot be whitespace!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[
                  {
                    required: !!user.dateOfBirth,
                    message: "Please select your date of birth!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                  disabledDate={(current) =>
                    !current || current.isAfter(dayjs())
                  }
                  allowClear={false}
                />
              </Form.Item>
              {/*
              <Form.Item name="language" label="Language">
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="English">English</Option>
                  <Option value="Bosnian">Bosnian</Option>
                </Select>
              </Form.Item>
*/}

              {user.role === "EDUCATOR" && (
                <>
                  <Form.Item
                    name="department"
                    label="Department"
                    rules={[
                      { required: false },
                      { validator: validateDepartmentAndPosition },
                    ]}
                  >
                    <Select
                      disabled={hasAccessToAllDepartments}
                      mode="multiple"
                      placeholder={
                        !hasAccessToAllDepartments
                          ? "Select Department"
                          : "All Departments Seleceted"
                      }
                      size="large"
                      className="w-full min-h-[50px] flex items-center -mt-1"
                      allowClear
                    >
                      {departments.map((dept: any) => (
                        <Option key={dept.id} value={dept.id}>
                          {dept.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="position"
                    label="Position"
                    rules={[
                      { required: false },
                      { validator: validateDepartmentAndPosition },
                    ]}
                  >
                    <Select
                      disabled={hasAccessToAllPositions}
                      mode="multiple"
                      placeholder={
                        !hasAccessToAllPositions
                          ? "Select Position"
                          : "All Positions Seleceted"
                      }
                      size="large"
                      className="w-full min-h-[50px] flex items-center -mt-1"
                      allowClear
                    >
                      {positions.map((pos: any) => (
                        <Option key={pos.id} value={pos.id}>
                          {pos.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="mt-2"
                    name="hasAccessToAllDepartments"
                    rules={[
                      { required: false },
                      { validator: validateDepartmentAndPosition },
                    ]}
                  >
                    <Checkbox
                      checked={hasAccessToAllDepartments}
                      onChange={(e) => {
                        setHasAccessToAllDepartments(e.target.checked);
                        setIsChanged(true);
                        form.setFieldsValue({
                          department: !e.target.checked
                            ? user.departmentIds
                            : [],
                        });
                      }}
                    >
                      Has Access to All Departments
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    className="mt-2"
                    name="hasAccessToAllPositions"
                    rules={[
                      { required: false },
                      { validator: validateDepartmentAndPosition },
                    ]}
                  >
                    <Checkbox
                      checked={hasAccessToAllPositions}
                      onChange={(e) => {
                        setHasAccessToAllPositions(e.target.checked);
                        setIsChanged(true);
                        form.setFieldsValue({
                          position: !e.target.checked ? user.positionIds : [],
                        });
                      }}
                    >
                      Has Access to All Positions
                    </Checkbox>
                  </Form.Item>

                  <Form.Item className="mt-2" name="hasAccessToAllUsers">
                    <Checkbox
                      checked={hasAccessToAllUsers}
                      onChange={(e) => {
                        setHasAccessToAllUsers(e.target.checked);
                        setIsChanged(true);
                      }}
                    >
                      Has Access to All Users
                    </Checkbox>
                  </Form.Item>
                </>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditUserScreen;
