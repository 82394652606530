import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setPosition,
  setPositionById,
  setPositionUser,
  adminPositionTypes as types,
  getPositionUser,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  addPositionService,
  getPositionService,
  getPositionByIdService,
  getPositionUserService,
  deletePositionByIdService,
  editPositionService,
} from "../../../services/educator/position";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* adminPositionSagas() {
  yield all([
    takeLatest(types.ADMIN_ADD_POSITION, handleAddPosition),
    takeLatest(types.ADMIN_GET_POSITION, handleGetPosition),
    takeLatest(types.ADMIN_GET_POSITION_BY_ID, handleGetPositionById),
    takeLatest(types.ADMIN_DELETE_POSITION, handleDeletePosition),
    takeLatest(types.ADMIN_EDIT_POSITION, handleEditPosition),
    takeLatest(types.ADMIN_GET_POSITION_USER, handleGetPositionWithUsers),
  ]);
}

function* handleAddPosition(action: any) {
  const { data }: AxiosResponse = yield call(
    addPositionService,
    action.payload.position
  );
  if (data) {
    yield put(getPositionUser());
    showNotification("success", "Position added successfully");
  }
}

function* handleGetPosition() {
  const { data }: AxiosResponse = yield call(getPositionService);
  if (data) {
    yield put(setPosition({ positions: data }));
  }
}

function* handleGetPositionById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getPositionByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setPositionById({ position: data }));
  }
}

function* handleDeletePosition(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deletePositionByIdService,
    action.payload.id
  );
  if (data) {
    yield put(getPositionUser());
    showNotification("success", "Position deleted successfully");
  }
}

function* handleEditPosition(action: any) {
  const { data }: AxiosResponse = yield call(
    editPositionService,
    action.payload.id,
    action.payload.position
  );
  if (data) {
    yield put(getPositionUser());
    showNotification("success", "Position updated successfully");
  }
}

function* handleGetPositionWithUsers() {
  const { data }: AxiosResponse = yield call(getPositionUserService);
  if (data) {
    yield put(setPositionUser({ positions: data }));
  }
}
