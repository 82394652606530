import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, Avatar, Tooltip, Typography } from "antd";
import MenuItems from "../../../assets/images/menu-items";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../redux/auth/selector";
import { userTypes } from "../../../redux/learner/actions";
import { getLoggedUser } from "../../../redux/auth/actions";
import "../../../assets/styles/sidebar.scss";

const { Sider } = Layout;
const { Text } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const pathToKeyMap: Record<string, string> = {
  "/owner/company": "company",
};

const OwnerSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [siderWidth, setSiderWidth] = useState(360);
  const items = [
    getItem("Company", "company", <MenuItems.CompanyInfo />, undefined, () =>
      navigate("/owner/company")
    ),
    getItem(
      "Logout",
      "logout",
      <LogoutOutlined style={{ fontSize: 18 }} />,
      undefined,
      () => dispatch({ type: userTypes.LOGOUT, payload: { navigate } })
    ),
  ];

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1024) {
        setSiderWidth(200);
        return;
      }
      if (screenWidth < 1800) {
        setSiderWidth(250);
        return;
      }
    };

    window.addEventListener("resize", handleResize);

    // Set the initial width
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
      theme="light"
      width={siderWidth}
    >
      <div className="flex flex-col gap-7">
        <div className="h-20 bg-primary-850"></div>

        <div className="px-5 flex justify-items-center items-center h-full  gap-3">
          <Tooltip title="Profile settings">
            <Avatar
              size={48}
              src={
                user?.profilePictureKey &&
                process.env.REACT_APP_CLOUDFRONT_KEY +
                  "/" +
                  user?.profilePictureKey
              }
              icon={<UserOutlined />}
              className="cursor-pointer min-w-12"
              onClick={() => navigate("/settings/" + user?.id)}
            />
          </Tooltip>
          <div>
            <Text>{user?.name ?? user?.email}</Text>
            <div className="text-primary-600">Owner</div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          items={items}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[pathToKeyMap[location.pathname]]}
        />
      </div>
    </Sider>
  );
};

export default OwnerSidebar;
