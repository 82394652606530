import { produce } from "immer";

import { adminUserTypes as types } from "../actions";
import { EducatorUserStateType } from "../../../utils";

const defaultState: EducatorUserStateType = {
  users: [],
  usersByRole: [],
  user: null,
  loading: false,
  loaded: false,
};

const handleSetUser = (state: any, payload: any) => {
  state.user = payload.user;
  return state;
};

const handleSetUsers = (state: any, payload: any) => {
  state.users = payload.users;
  state.loaded = true;
  state.loading = false;
  return state;
};

const handleSetUsersByRole = (state: any, payload: any) => {
  state.usersByRole = payload.users;
  state.loaded = true;
  state.loading = false;
  return state;
};

const handleDeleteUser = (state: any, id: any) => {
  state.users = state.users.filter((user: any) => user.id !== id);
  return state;
};

export default produce(
  (state: EducatorUserStateType = defaultState, action) => {
    switch (action.type) {
      case types.ADMIN_SET_USER:
        return handleSetUsers(state, action.payload);
      case types.ADMIN_SET_USER_BY_ID:
        return handleSetUser(state, action.payload);
      case types.ADMIN_SET_DELETE_USER:
        return handleDeleteUser(state, action.id);
      case types.ADMIN_SET_USER_BY_ROLE:
        return handleSetUsersByRole(state, action.payload);
      case types.ADMIN_GET_USER:
        return {
          ...state,
          loading: true,
        };
      case types.ADMIN_GET_USER_BY_ROLE:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
