export const moduleTypes = {
  ADD_MODULE: "ADD_MODULE",
  GET_MODULE: "GET_MODULE",
  GET_MODULE_BY_ID: "GET_MODULE_BY_ID",
  DELETE_MODULE: "DELETE_MODULE",
  SET_MODULE: "SET_MODULE",
  SET_MODULE_BY_ID: "SET_MODULE_BY_ID",
  EDIT_MODULE: "EDIT_MODULE",
  ASSIGN_COURSE: "ASSIGN_COURSE",
};

export const addModule = (payload: any) => ({
  type: moduleTypes.ADD_MODULE,
  payload,
});

export const getModule = (payload?: any) => ({
  type: moduleTypes.GET_MODULE,
  payload,
});

export const setModule = (payload: any) => ({
  type: moduleTypes.SET_MODULE,
  payload,
});

export const getModuleById = (id: any) => ({
  type: moduleTypes.GET_MODULE_BY_ID,
  payload: id,
});

export const setModuleById = (payload: any) => ({
  type: moduleTypes.SET_MODULE_BY_ID,
  payload,
});

export const deleteModule = (payload: any) => ({
  type: moduleTypes.DELETE_MODULE,
  payload,
});

export const editModule = (payload: any) => ({
  type: moduleTypes.EDIT_MODULE,
  payload,
});

export const assignCourse = (payload: any) => ({
  type: moduleTypes.ASSIGN_COURSE,
  payload,
});
