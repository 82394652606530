import HeaderItems from "../../assets/images/header-items";

export default [
  {
    path: "/user/dashboard",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/module",
    icon: HeaderItems.Module,
    heading: "There are currently no available modules.",
    buttonText: "Create New Module",
    buttonLink: "/educator/module/create",
  },
  {
    path: "/user/module",
    icon: HeaderItems.Module,
    heading: "There are currently no available modules.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/course",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "Create New Course",
    buttonLink: "/educator/course/create",
  },
  {
    path: "/user/course",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/lesson",
    icon: HeaderItems.Lesson,
    heading: "There are currently no available lessons.",
    buttonText: "Create New Lesson",
    buttonLink: "/educator/lesson/create",
  },
  {
    path: "/educator/alert",
    icon: HeaderItems.Alert,
    heading: "There are currently no Alerts.",
    buttonText: "Create New Alert",
    buttonLink: "/educator/alert/create",
  },
  {
    path: "/user/alert",
    icon: HeaderItems.Alert,
    heading: "There are currently no Alerts.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/quiz",
    icon: HeaderItems.Quiz,
    heading: "There are currently no Quiz.",
    buttonText: "Create New Quiz",
    buttonLink: "/educator/quiz/create",
  },
  {
    path: "/educator/quizzie",
    icon: HeaderItems.Quizzie,
    heading: "There are currently no Quizzie.",
    buttonText: "Create New Quizzie",
    buttonLink: "/educator/quizzie/create",
  },
  {
    path: "/user/quizzie",
    icon: HeaderItems.Quizzie,
    heading: "There are currently no Quizzie available",
  },
  {
    path: "/educator/discussion",
    icon: HeaderItems.Discussion,
    heading: "There are currently no Discussions",
    buttonText: "Start New Discussion",
    buttonLink: "/educator/discussion/create",
  },
  {
    path: "/user/discussion",
    icon: HeaderItems.Discussion,
    heading: "There are currently no Discussions",
    buttonText: "Start New Discussion",
    buttonLink: "/user/discussion/create",
  },
  {
    path: "/educator/ai-content",
    icon: HeaderItems.AI,
    heading: "There is currently no AI Content.",
    buttonText: "Create New AI Content",
    buttonLink: "/educator/ai-content/create",
  },
  {
    path: "/educator/lesson/create",
    icon: HeaderItems.AI,
    heading: "There is currently no AI Content.",
    buttonText: "Create New AI Content",
    buttonLink: "/educator/ai-content/create",
  },
  {
    path: "/educator/discussion",
    icon: HeaderItems.Discussion,
    heading: "There are currently no Discussions.",
  },
  {
    path: "/user/discussion",
    icon: HeaderItems.Discussion,
    heading: "There are currently no Discussions",
    buttonText: "Start New Discussion",
    buttonLink: "/user/discussion/create",
  },
  {
    path: "/educator/assignment",
    icon: HeaderItems.Assignment,
    heading: "There are currently no available Assignments.",
    buttonText: "Create New Assignment",
    buttonLink: "/educator/assignment/create",
  },
  {
    path: "/user/assignment",
    icon: HeaderItems.Assignment,
    heading: "There are currently no Assignments available",
  },
  {
    path: "/user/lesson-demand",
    icon: HeaderItems.LessonDemand,
    heading: "There are currently no Lesson Demands",
    buttonText: "Create New Lesson Demand",
    buttonLink: "/user/lesson-demand/create",
  },
  {
    path: "/educator/lesson-demand",
    icon: HeaderItems.LessonDemand,
    heading: "There are currently no available Lesson Demands.",
  },
  {
    path: '/owner/company',
    icon: HeaderItems.CompanyStructure,
    heading: 'There are currently no Companies.',
  },
];
