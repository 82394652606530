export const adminUserTypes = {
  ADMIN_ADD_USER: "ADMIN_ADD_USER",
  ADMIN_GET_USER: "ADMIN_GET_USER",
  ADMIN_GET_USER_BY_ID: "ADMIN_GET_USER_BY_ID",
  ADMIN_DELETE_USER: "ADMIN_DELETE_USER",
  ADMIN_SET_USER: "ADMIN_SET_USER",
  ADMIN_SET_USER_BY_ID: "ADMIN_SET_USER_BY_ID",
  ADMIN_SET_DELETE_USER: "ADMIN_SET_DELETE_USER",
  ADMIN_GET_USER_BY_ROLE: "ADMIN_GET_USER_BY_ROLE",
  ADMIN_SET_USER_BY_ROLE: "ADMIN_SET_USER_BY_ROLE",
  ADMIN_EDIT_USER: "ADMIN_EDIT_USER",
  ADMIN_SIGN_UP: "ADMIN_SIGN_UP",
  LOGOUT_ADMIN: "LOGOUT_ADMIN",
};

export const addUser = (payload: any) => ({
  type: adminUserTypes.ADMIN_ADD_USER,
  payload,
});

export const getUser = () => ({
  type: adminUserTypes.ADMIN_GET_USER,
});

export const setUser = (payload: any) => ({
  type: adminUserTypes.ADMIN_SET_USER,
  payload,
});

export const getUserById = (id: any) => ({
  type: adminUserTypes.ADMIN_GET_USER_BY_ID,
  payload: id,
});

export const setUserById = (payload: any) => ({
  type: adminUserTypes.ADMIN_SET_USER_BY_ID,
  payload,
});

export const deleteUser = (payload: any) => ({
  type: adminUserTypes.ADMIN_DELETE_USER,
  payload,
});

export const setDeleteUser = (id: any) => ({
  type: adminUserTypes.ADMIN_SET_DELETE_USER,
  id,
});

export const editUser = (payload: any) => ({
  type: adminUserTypes.ADMIN_EDIT_USER,
  payload,
});

export const getUserByRole = (payload: any) => ({
  type: adminUserTypes.ADMIN_GET_USER_BY_ROLE,
  payload,
});

export const signUp = (payload: any) => ({
  type: adminUserTypes.ADMIN_SIGN_UP,
  payload,
});

export const logout = () => ({
  type: adminUserTypes.LOGOUT_ADMIN,
});
