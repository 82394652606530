export const lessonDemandTypes = {
  CREATE_LESSON_DEMAND: "CREATE_LESSON_DEMAND",
  GET_LESSON_DEMANDS: "GET_LESSON_DEMANDS",
  SET_LESSON_DEMANDS: "SET_LESSON_DEMANDS",
  GET_LESSON_DEMAND_BY_ID: "GET_LESSON_DEMAND_BY_ID",
  SET_LESSON_DEMAND_BY_ID: "SET_LESSON_DEMAND_BY_ID",
  CHANGE_RESOLVED_STATUS_LESSON_DEMAND: "CHANGE_RESOLVED_STATUS_LESSON_DEMAND",
  SET_CHANGE_RESOLVED_STATUS_LESSON_DEMAND:
    "SET_CHANGE_RESOLVED_STATUS_LESSON_DEMAND",
  DELETE_LESSON_DEMAND_BY_ID: "DELETE_LESSON_DEMAND_BY_ID",
};

export const createLessonDemand = (payload: any) => ({
  type: lessonDemandTypes.CREATE_LESSON_DEMAND,
  payload,
});

export const getLessonDemand = () => ({
  type: lessonDemandTypes.GET_LESSON_DEMANDS,
});

export const setLessonDemand = (payload: any) => ({
  type: lessonDemandTypes.SET_LESSON_DEMANDS,
  payload,
});

export const getLessonDemandById = (id: any) => ({
  type: lessonDemandTypes.GET_LESSON_DEMAND_BY_ID,
  payload: id,
});

export const setLessonDemandById = (payload: any) => ({
  type: lessonDemandTypes.SET_LESSON_DEMAND_BY_ID,
  payload,
});

export const changeResolvedStatusLessonDemand = (payload: any) => ({
  type: lessonDemandTypes.CHANGE_RESOLVED_STATUS_LESSON_DEMAND,
  payload,
});

export const setChangeResolvedStatusLessonDemand = (payload: any) => ({
  type: lessonDemandTypes.SET_CHANGE_RESOLVED_STATUS_LESSON_DEMAND,
  payload,
});

export const deleteLessonDemandById = (id: any) => ({
  type: lessonDemandTypes.DELETE_LESSON_DEMAND_BY_ID,
  payload: id,
});
