import { createSelector } from '@reduxjs/toolkit';

import { OwnerCompanyStateType } from '../../../utils';
import { StateType } from '../../../utils';

const selectCompanyState = (state: StateType): OwnerCompanyStateType =>
  state.ownerCompanyReducer;

export const selectCompanies = createSelector(
  selectCompanyState,
  (state: OwnerCompanyStateType) => state.companies,
);

export const selectCompany = createSelector(
  selectCompanyState,
  (state: OwnerCompanyStateType) => state.company,
);

export const selectCompaniesLoading = createSelector(
  selectCompanyState,
  (state: OwnerCompanyStateType) => state.loading,
);

export const selectCompanyLoaded = createSelector(
  selectCompanyState,
  (state: OwnerCompanyStateType) => state.loaded,
);
