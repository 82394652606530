import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils/types";
import { selectUsersByRole } from "../../../../redux/educator/selector";
import { getUser, deleteUser } from "../../../../redux/educator/actions";
import { setCurrentStep } from "../../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  users: selectUsersByRole(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getUser,
      deleteUser,
      setCurrentStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
