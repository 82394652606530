import { produce } from "immer";

import { newsFeedTypes as types } from "../actions";
import { LearnerNewsFeedStateType } from "../../../utils/types/Learner";
import { newsFeedType } from "../../../utils/constants/newsFeedTypes";

const defaultState: {
  newsFeed: LearnerNewsFeedStateType;
  loading: boolean;
} = {
  newsFeed: null,
  loading: false,
};

const handleSetLearnerNewsFeed = (
  state: { newsFeed: LearnerNewsFeedStateType; loading: boolean },
  payload: LearnerNewsFeedStateType
) => {
  state.newsFeed = {
    modules: payload?.modules ?? null,
    courses: payload?.courses ?? null,
    alerts: payload?.alerts ?? null,
    quizzies: payload?.quizzies ?? null,
    assignments: payload?.assignments ?? null,
    discussions: payload?.discussions ?? null,
    lessonDemands: payload?.lessonDemands ?? null,
  };
  state.loading = false;
  return state;
};

const handleUpdateLearnerNewsFeed = (
  state: { newsFeed: any; loading: boolean },
  payload: string
) => {
  switch (true) {
    case payload === newsFeedType.module:
      state.newsFeed = { ...state.newsFeed, modules: 0 };
      break;
    case payload === newsFeedType.course:
      state.newsFeed = { ...state.newsFeed, courses: 0 };
      break;
    case payload === newsFeedType.alert:
      state.newsFeed = { ...state.newsFeed, alerts: 0 };
      break;
    case payload === newsFeedType.quizzie:
      state.newsFeed = { ...state.newsFeed, quizzies: 0 };
      break;
    case payload === newsFeedType.assignment:
      state.newsFeed = { ...state.newsFeed, assignments: 0 };
      break;
    case payload === newsFeedType.discussion:
      state.newsFeed = { ...state.newsFeed, discussions: 0 };
      break;
    case payload === newsFeedType.lessonDemand:
      state.newsFeed = { ...state.newsFeed, lessonDemands: 0 };
      break;
  }
  state.loading = false;
  return state;
};

export default produce((state = defaultState, action) => {
  switch (action.type) {
    case types.SET_LEARNER_NEWS_FEED_ACTION:
      return handleSetLearnerNewsFeed(state, action.payload);
    case types.UPDATE_LEARNER_NEWS_FEED_STATE_ACTION:
      return handleUpdateLearnerNewsFeed(state, action.payload);
    case types.GET_LEARNER_NEWS_FEED_ACTION:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
});
