export const adminRatingTypes = {
  ADMIN_ADD_RATING_QUESTION: "ADMIN_ADD_RATING_QUESTION",
  ADMIN_GET_RATING: "ADMIN_GET_RATING",
  ADMIN_SET_RATING: "ADMIN_SET_RATING",
  ADMIN_DELETE_RATING: "ADMIN_DELETE_RATING",
  ADMIN_EDIT_RATING: "ADMIN_EDIT_RATING",
};

export const adminAddRatingQuestion = (payload: any) => ({
  type: adminRatingTypes.ADMIN_ADD_RATING_QUESTION,
  payload,
});

export const adminGetRating = () => ({
  type: adminRatingTypes.ADMIN_GET_RATING,
});

export const adminSetRating = (payload: any) => ({
  type: adminRatingTypes.ADMIN_SET_RATING,
  payload,
});

export const adminDeleteRating = (payload: any) => ({
  type: adminRatingTypes.ADMIN_DELETE_RATING,
  payload,
});

export const adminEditRating = (payload: any) => ({
  type: adminRatingTypes.ADMIN_EDIT_RATING,
  payload,
});
