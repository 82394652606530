import { createSelector } from "@reduxjs/toolkit";
import { StateType } from "../../../utils";
import { RatingStateType } from "../../../utils/types/Learner/rating/RatingState";

const selectRatingsState = (state: StateType): RatingStateType =>
  state.adminRatingsReducer;

export const selectRatings = createSelector(
  selectRatingsState,
  (state: RatingStateType) => state.ratings
);

export const selectRatingsLoaded = createSelector(
  selectRatingsState,
  (state: RatingStateType) => state.loaded
);

export const selectRatingsLoading = createSelector(
  selectRatingsState,
  (state: RatingStateType) => state.loading
);
