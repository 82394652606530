import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getCompany, addCompany } from "../../../../redux/owner/actions";
import {
  selectCompanies,
  selectCompaniesLoading,
  selectCompanyLoaded,
} from "../../../../redux/owner/selector";

const mapStateToProps = (state: StateType) => ({
  companies: selectCompanies(state),
  loading: selectCompaniesLoading(state),
  loaded: selectCompanyLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCompany,
      addCompany,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
