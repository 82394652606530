import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  UploadFile,
  Row,
  Col,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";
import showNotification from "../../../../services/notificationService";
import {
  departmentTypes,
  positionTypes,
} from "../../../../redux/educator/actions";
import {
  selectDepartments,
  selectPositions,
} from "../../../../redux/educator/selector";

const { Text } = Typography;
const { TextArea } = Input;

const CreateModuleScreen = (props: any) => {
  const {
    actions: { addModule, setInitialSteps },
  } = props;
  const departments = useSelector(selectDepartments);
  const positions = useSelector(selectPositions);

  const [fileList, setFileList] = useState<any>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setInitialSteps(2);
    dispatch({ type: departmentTypes.GET_DEPARTMENT });
    dispatch({ type: positionTypes.GET_POSITION });
  }, []);

  const handleSubmit = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    if (selectedDepartments.length === 0 && selectedPositions.length === 0) {
      showNotification(
        "error",
        "Please select at least one department or one position"
      );
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (fileList.length > 0) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }
    selectedDepartments.forEach((departmentId: string) => {
      formData.append("departmentIds", departmentId);
    });
    selectedPositions.forEach((positionId: string) => {
      formData.append("positionIds", positionId);
    });
    addModule({ module: formData, navigate });
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  return (
    <Row className="h-full">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={`Create a new module which will contain all created courses and lessons.
       
            You can skip this step and create a new Module at a later time.`}
        />
      </Col>
      <Col lg={16} className=" max-w-[600px]">
        <Form layout="vertical" onFinish={handleSubmit}>
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Form.Item
            name="coverImage"
            className="pb-6"
            rules={[
              { required: true, message: "Please upload a cover image!" },
            ]}
          >
            <Upload
              name="coverImage"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="image/png, image/jpeg, image/gif"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={fileList}
              onChange={handleChange}
            >
              {fileList.length === 0 && (
                <div className="flex flex-col items-center justify-center">
                  <UploadIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a cover image
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    PNG, JPG, GIF up to 5MB
                  </p>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Assign Module
          </Text>

          <div className="mt-2 flex flex-col gap-2">
            <DepartmentSelector
              departments={departments}
              onSelectionChange={handleDepartmentChange}
              setSelectedDepartments={setSelectedDepartments}
              selectedDepartments={selectedDepartments}
            />
            <PositionSelector
              positions={positions}
              onSelectionChange={handlePositionChange}
              selectedPositions={selectedPositions}
              setSelectedPositions={setSelectedPositions}
            />
          </div>
          <div className="w-full flex justify-end">
            <Form.Item className="w-2/4 my-5">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-[50px]"
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateModuleScreen;
