import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import WildCardNavigation from "../../modules/shared/WildCardNavigation";
import CompanyInfoScreen from "../../modules/admin/Company";
import AdminHeader from "../../modules/admin/Header/Header";
import AdminSidebar from "../../modules/admin/Sidebar/Sidebar";
import DepartmentsScreen from "../../modules/admin/Department";
import PositionsScreen from "../../modules/admin/Position";
import UsersScreen from "../../modules/admin/User/View";
import EditUserScreen from "../../modules/admin/User/Edit";
import SignUp from "../../modules/admin/User/SignUp";
import RatingsScreen from "../../modules/admin/Ratings/View";

const { Content } = Layout;

const AdminLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh", padding: "0" }}>
      <AdminSidebar></AdminSidebar>
      <Layout>
        <AdminHeader></AdminHeader>

        <Content className="educator-container">
          <Routes>
            <Route path="company" element={<CompanyInfoScreen />} />
            <Route path="department" element={<DepartmentsScreen />} />
            <Route path="position" element={<PositionsScreen />} />
            <Route path="user" element={<UsersScreen />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="user/:id" element={<EditUserScreen />} />
            <Route path="rating" element={<RatingsScreen />} />
            <Route path="*" element={<WildCardNavigation />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
