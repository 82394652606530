export const alertTypes = {
  ADD_ALERT: "ADD_ALERT",
  GET_ALERT: "GET_ALERT",
  DELETE_ALERT: "DELETE_ALERT",
  SET_ALERT: "SET_ALERT",
  GET_ALERT_BY_ID: "GET_ALERT_BY_ID",
  SET_ALERT_BY_ID: "SET_ALERT_BY_ID",
};

export const addAlert = (payload: any) => ({
  type: alertTypes.ADD_ALERT,
  payload,
});

export const getAlert = () => ({
  type: alertTypes.GET_ALERT,
});

export const setAlert = (payload: any) => ({
  type: alertTypes.SET_ALERT,
  payload,
});

export const deleteAlert = (payload: any) => ({
  type: alertTypes.DELETE_ALERT,
  payload,
});

export const getAlertById = (payload: any) => ({
  type: alertTypes.GET_ALERT_BY_ID,
  payload,
});

export const setAlertById = (payload: any) => ({
  type: alertTypes.SET_ALERT_BY_ID,
  payload,
});
