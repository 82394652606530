import { Typography } from "antd";
import ItemCard from "./ItemCard";

const { Text } = Typography;

const ItemList = (props: any) => {
  const {
    loggedUserRole,
    itemType,
    items,
    editingItem,
    setEditingItem,
    editingName,
    onEditItem,
    onSaveItem,
    onDeleteItem,
    onViewUsers,
    onChangeName,
  } = props;
  return (
    <div className="flex flex-col pb-4 border-b border-b-primary-200 max-h-[calc(100vh-550px)] overflow-y-auto">
      <div>
        {items &&
          items.map((item: any) => (
            <ItemCard
              loggedUserRole={loggedUserRole}
              key={item.id}
              item={item}
              isEditing={editingItem && editingItem.id === item.id}
              setEditingItem={setEditingItem}
              editingName={editingName}
              onEdit={() => onEditItem(item)}
              onSave={onSaveItem}
              onDelete={() => onDeleteItem(item.id)}
              onViewUsers={() => onViewUsers(item)}
              onChangeName={onChangeName}
            />
          ))}
      </div>
    </div>
  );
};

export default ItemList;
