import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";

const { Text } = Typography;

const SignUp = (props: any) => {
  const {
    departments,
    positions,
    actions: { signUp, getDepartment, getPosition },
  } = props;

  const [form] = Form.useForm();
  const [role, setRole] = useState("EDUCATOR");
  const [hasAccessToAllDepartments, setHasAccessToAllDepartments] =
    useState(false);
  const [hasAccessToAllPositions, setHasAccessToAllPositions] = useState(false);
  const [hasAccessToAllUsers, setHasAccessToAllUsers] = useState(false);

  useEffect(() => {
    getDepartment();
    getPosition();
  }, []);

  const onFinishSignUp = ({
    email,
    departmentIds,
    positionIds,
  }: {
    email: string;
    departmentIds: string[] | null;
    positionIds: string[] | null;
  }) => {
    signUp({
      email,
      departmentIds: role === "EDUCATOR" ? departmentIds || [] : [],
      positionIds: role === "EDUCATOR" ? positionIds || [] : [],
      role,
      hasAccessToAllDepartments,
      hasAccessToAllPositions,
      hasAccessToAllUsers,
    });
    form.resetFields();
  };

  const validateDepartmentAndPosition = (_: any, value: any) => {
    const departmentIds = form.getFieldValue("departmentIds");
    const positionIds = form.getFieldValue("positionIds");

    if (
      (!departmentIds || departmentIds.length === 0) &&
      (!positionIds || positionIds.length === 0) &&
      !hasAccessToAllDepartments &&
      !hasAccessToAllPositions
    ) {
      return Promise.reject(
        new Error("At least one department or one position must be selected")
      );
    }

    Object.keys(form.getFieldsValue()).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }

      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
    return Promise.resolve();
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title="Invite a User"
          text="Enter the information of the user you want to invite to the application"
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinishSignUp}
          autoComplete="off"
          size="large"
        >
          <div className="w-full mb-7">
            <Text className="text-neutral-700 text-lg font-semibold">
              User Information
            </Text>
          </div>

          <Text className="text-neutral-600 text-sm font-semibold">
            Email Address
          </Text>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="mt-2"
          >
            <Input
              type="email"
              autoComplete="off"
              name="email"
              placeholder="Input Email Address"
              className="h-[50px]"
            />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            User role
          </Text>

          <Form.Item
            name="role"
            rules={[{ required: true, message: "Please select role!" }]}
            className="mt-2"
          >
            <Select
              placeholder="Select Role"
              className="min-h-[50px]"
              onChange={(value) => setRole(value)}
            >
              {["ADMIN", "EDUCATOR"].map((role) => (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {role === "EDUCATOR" && (
            <>
              <Text className="text-neutral-600 text-sm font-semibold">
                Department (Optional)
              </Text>

              <Form.Item
                name="departmentIds"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
                className="mt-2"
              >
                <Select
                  mode="multiple"
                  placeholder={
                    !hasAccessToAllDepartments
                      ? "Select Department"
                      : "All Departments Seleceted"
                  }
                  allowClear
                  className="min-h-[50px] flex items-center -mt-1"
                  disabled={hasAccessToAllDepartments}
                >
                  {departments.map((dept: any) => (
                    <Select.Option key={dept.id} value={dept.id}>
                      {dept.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Text className="text-neutral-600 text-sm font-semibold">
                Position (Optional)
              </Text>

              <Form.Item
                name="positionIds"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
                className="mt-2"
              >
                <Select
                  mode="multiple"
                  placeholder={
                    !hasAccessToAllPositions
                      ? "Select Position"
                      : "All Positions Seleceted"
                  }
                  allowClear
                  className="min-h-[50px] flex items-center -mt-1"
                  disabled={hasAccessToAllPositions}
                >
                  {positions.map((pos: any) => (
                    <Select.Option key={pos.id} value={pos.id}>
                      {pos.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="hasAccessToAllDepartments"
                className="mt-2"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
              >
                <Checkbox
                  checked={hasAccessToAllDepartments}
                  onChange={(e) => {
                    setHasAccessToAllDepartments(e.target.checked);
                    form.setFieldsValue({
                      departmentIds: [],
                    });
                  }}
                >
                  Has Access to All Departments
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="hasAccessToAllPositions"
                className="mt-2"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
              >
                <Checkbox
                  checked={hasAccessToAllPositions}
                  onChange={(e) => {
                    setHasAccessToAllPositions(e.target.checked);
                    form.setFieldsValue({
                      positionIds: [],
                    });
                  }}
                >
                  Has Access to All Positions
                </Checkbox>
              </Form.Item>

              <Form.Item className="mt-2" name="hasAccessToAllUsers">
                <Checkbox
                  checked={hasAccessToAllUsers}
                  onChange={(e) => setHasAccessToAllUsers(e.target.checked)}
                >
                  Has Access to All Users
                </Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <div className="w-full flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                className="w-1/2 bg-primary-600 h-[50px]"
              >
                Send Invitation
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SignUp;
