import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  getAlert,
  getLearnerNewsFeed,
  deleteNewsFeed,
} from "../../../../redux/learner/actions";
import {
  selectAlerts,
  selectAlertsLoaded,
  selectAlertsLoading,
  selectLearnerAlertsNewsFeed,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  alerts: selectAlerts(state),
  alertsNewsFeed: selectLearnerAlertsNewsFeed(state),
  loading: selectAlertsLoading(state),
  loaded: selectAlertsLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAlert,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
