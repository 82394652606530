import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import HeaderItems from "../../../assets/images/header-items";

const { Header } = Layout;
const AdminHeader = () => {
  const { pathname } = useLocation();
  const [headerValues, setHeaderValues] = useState<any>(
    new Map([
      [
        "/admin/company",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          subtitle: "View, create and manage your company structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/company",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          subtitle: "View and manage your company structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/department",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/position",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/user",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/user/:id",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],

      [
        "/admin/sign-up",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],
      [
        "/admin/rating",
        {
          Icon: HeaderItems.Rating,
          title: "Ratings",
          hasStepper: false,
        },
      ],
    ])
  );
  const [CurrentHeaderValue, setCurrentHeaderValue] = useState<any>(null);

  useEffect(() => {
    getHeaderPropsForCurrentPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getHeaderPropsForCurrentPath = () => {
    const mapKeysArr = Array.from(headerValues.keys());
    const currentValueByPath = mapKeysArr.find((key: any) =>
      matchPath(key, pathname)
    );

    const headerValueToSet = headerValues.get(currentValueByPath);
    if (headerValueToSet) {
      setCurrentHeaderValue(headerValueToSet);
      return;
    }

    setCurrentHeaderValue(null);
  };

  return (
    <>
      {CurrentHeaderValue && (
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="educator-header-container h-full flex items-center">
            <Row className="w-full">
              <Col
                lg={CurrentHeaderValue.subtitle ? "auto" : 8}
                className="flex items-center gap-5"
              >
                <CurrentHeaderValue.Icon className="w-[30px] h-[30px] fill-primary-600 text-3xl text-primary-600" />
                <div>
                  <div className="text-sm font-semibold">
                    {CurrentHeaderValue.title}
                  </div>
                  {CurrentHeaderValue.subtitle && (
                    <div className="text-xs">{CurrentHeaderValue.subtitle}</div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Header>
      )}
    </>
  );
};

export default AdminHeader;
