import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const createLessonDemandService = async (data: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.lessonDemand, data));

export const getLessonDemandService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.lessonDemand));

export const getLessonDemandByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.lessonDemandById.replace(":id", id))
  );

export const changeResolvedStatusService = async (
  id: string,
  isResolved: boolean
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.lessonDemandById.replace(":id", id), {
      isResolved,
    })
  );

export const deleteLessonDemandByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.lessonDemandById.replace(":id", id))
  );
