import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setPosition,
  setPositionById,
  setPositionUser,
  positionTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  getPositionService,
  getPositionByIdService,
  getPositionUserService,
} from "../../../services/educator/position";
import { EntityIdPayload } from "../../../utils";

export default function* educatorPositionSagas() {
  yield all([
    takeLatest(types.GET_POSITION, handleGetPosition),
    takeLatest(types.GET_POSITION_BY_ID, handleGetPositionById),

    takeLatest(types.GET_POSITION_USER, handleGetPositionWithUsers),
  ]);
}

function* handleGetPosition() {
  const { data }: AxiosResponse = yield call(getPositionService);
  if (data) {
    yield put(setPosition({ positions: data }));
  }
}

function* handleGetPositionById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getPositionByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setPositionById({ position: data }));
  }
}

function* handleGetPositionWithUsers() {
  const { data }: AxiosResponse = yield call(getPositionUserService);
  if (data) {
    yield put(setPositionUser({ positions: data }));
  }
}
