import { useEffect, useState } from "react";
import { Modal, Button, Typography, Checkbox } from "antd";
import { ReactComponent as UserIcon } from "../../assets/images/reusable-items/users.svg";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsers,
  selectUsersByRole,
  selectUsersLoaded,
} from "../../redux/educator/selector";
import { userTypes as types } from "../../redux/educator/actions";
import showNotification from "../../services/notificationService";
import { selectUser } from "../../redux/auth/selector";

const { Text } = Typography;

const UserSelector = ({
  users,
  onSelectionChange,
  selectedUsers,
  setSelectedUsers,
  isDetailsState,
  isAssignmentDetailsPage,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedUsers, setTempSelectedUsers] = useState<string[]>([]);
  const [concatedUsers, setConcatedUsers] = useState<any>([]);
  const educatorUsers = useSelector(selectUsersByRole);
  const usersLoaded = useSelector(selectUsersLoaded);
  const user = useSelector(selectUser);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    users &&
      educatorUsers &&
      setConcatedUsers(
        users.concat(
          educatorUsers.filter(
            (item2) => !users.some((item1: any) => item1.id === item2.id)
          )
        )
      );
  }, [users, educatorUsers]);

  useEffect(() => {
    if (!usersLoaded) {
      dispatch({ type: types.GET_USER_BY_ROLE, payload: "user" });
    }
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedUsers(tempSelectedUsers);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedUsers);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedUsers(selectedUsers);
  };

  const handleSelect = (user: any) => {
    const educatorUserIds = educatorUsers.map((u) => u.id);

    const hasAccess = educatorUserIds.some(
      (educatorUserId) => educatorUserId === user
    );
    if (!hasAccess) {
      showNotification("error", "Sorry, you do not have access to this user.");
      return;
    }

    setTempSelectedUsers((prevSelected: string[]) =>
      prevSelected.includes(user)
        ? prevSelected.filter((item: string) => item !== user)
        : [...prevSelected, user]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    const allUserIds = concatedUsers.map((u: any) => u.id);

    if (checked) {
      setTempSelectedUsers(allUserIds);
    } else {
      if (!user.hasAccessToAllUsers) {
        const educatorUserIds = educatorUsers.map((u) => u.id);
        const selected = allUserIds.filter(
          (userIds: any) => !educatorUserIds.includes(userIds)
        );
        setTempSelectedUsers(selected);
      } else {
        setTempSelectedUsers([]);
      }
    }
  };

  const openModal = () => {
    setTempSelectedUsers(selectedUsers);
    setIsModalVisible(true);
  };

  const handleExpand = () => {
    if (!isDetailsState) return;
    setExpanded(!expanded);
  };

  return (
    <>
      <div
        onClick={handleExpand}
        className={`w-full px-6 py-4 rounded-lg shadow-md  ${
          !isDetailsState ? " bg-white" : "bg-neutral-100"
        } 
        ${isDetailsState && selectedUsers?.length > 0 && "cursor-pointer"}
        `}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <UserIcon className="fill-primary-400 w-[20px] h-[20px]" />
            <Text
              className={`text-neutral-600 text-sm ${
                selectedUsers?.length > 0 ? "" : "font-semibold"
              }
              ${isDetailsState && "font-semibold"}
              `}
            >
              {isDetailsState
                ? `Users ${
                    selectedUsers?.length > 0
                      ? `(${selectedUsers?.length})`
                      : ""
                  }`
                : selectedUsers?.length > 0
                ? `Selected Users (${selectedUsers?.length})`
                : "User(s)"}
            </Text>
          </div>
          {!isDetailsState && (
            <Button
              onClick={openModal}
              className="border-none text-primary-600 text-base font-semibold shadow-none"
            >
              {selectedUsers.length === 0 ? "Select" : "Add More"}
            </Button>
          )}
        </div>
        {selectedUsers.length > 0 && (
          <div
            style={{
              height: isDetailsState
                ? !expanded
                  ? "0px"
                  : `calc(${selectedUsers.length * 49 + 12}px)`
                : "auto",
              maxHeight: !isDetailsState ? "184px" : "auto",
            }}
            className={`flex flex-col gap-1 transition-all duration-500 ${
              isDetailsState && !expanded
                ? "overflow-hidden opacity-0"
                : `overflow-auto opacity-100 border-t border-t-primary-200 pt-4 mt-2`
            }`}
          >
            {selectedUsers.map((userId: string) => {
              const user: any = concatedUsers.find((u: any) => u.id === userId);
              return (
                <div
                  key={userId}
                  className="flex items-center px-4 py-3 rounded-lg bg-neutral-100"
                >
                  <Text className="text-neutral-600 text-sm">
                    {user?.email}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
          <div className="flex gap-3 items-center">
            <UserIcon className="fill-primary-400 w-[20px] h-[20px]" />
            <Text className="text-neutral-400 font-normal text-sm">
              User(s)
            </Text>
          </div>
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={tempSelectedUsers?.length === concatedUsers?.length}
          >
            <Text className="text-neutral-400 font-normal text-sm">
              Select All
            </Text>
          </Checkbox>
        </div>
        <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
          {users &&
            educatorUsers &&
            concatedUsers?.map((user: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSelect(user.id)}
                className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                  tempSelectedUsers?.includes(user.id)
                    ? "bg-primary-100"
                    : "bg-neutral-100"
                } hover:bg-primary-200 transition`}
              >
                <Text
                  className={`flex-1 text-neutral-600 text-sm ${
                    tempSelectedUsers?.includes(user.id) ? "font-semibold" : ""
                  }`}
                >
                  {user.email}
                </Text>
                {tempSelectedUsers?.includes(user.id) && (
                  <CheckOutlined className="text-blue-500" />
                )}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default UserSelector;
