import { createSelector } from "@reduxjs/toolkit";

import { LearnerLessonDemandStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectDiscussionState = (
  state: StateType
): LearnerLessonDemandStateType => state.learnerLessonDemandReducer;

export const selectLessonDemands = createSelector(
  selectDiscussionState,
  (state: LearnerLessonDemandStateType) => state.lessonDemands
);

export const selectLessonDemand = createSelector(
  selectDiscussionState,
  (state: LearnerLessonDemandStateType) => state.lessonDemand
);

export const selectLessonDemandLoading = createSelector(
  selectDiscussionState,
  (state: LearnerLessonDemandStateType) => state.loading
);

export const selectLessonDemandsLoaded = createSelector(
  selectDiscussionState,
  (state: LearnerLessonDemandStateType) => state.lessonDemandsLoaded
);

export const selectCurrentLessonDemandLoaded = createSelector(
  selectDiscussionState,
  (state: LearnerLessonDemandStateType) => state.currentlessonDemandLoaded
);
