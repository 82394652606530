import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Input, Skeleton, Typography } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import EmptyState from "../../../shared/EmptyState";
import testLogo from "../../../../assets/images/company-structure-items/companyInfo.svg";
import { useNavigate } from "react-router-dom";
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;
const { Text } = Typography;

interface Company {
  id: number;
  name: string;
  imageKey: string;
  users: string[];
}

const OwnerCompanyScreen = (props: {
  companies: Company[];
  loading: boolean;
  loaded: boolean;
  actions: { getCompany: () => void };
}) => {
  const {
    companies,
    loading,
    loaded,
    actions: { getCompany },
  } = props;

  const navigate = useNavigate();

  const [filteredCompanies, setFilteredCompanies] =
    useState<Company[]>(companies);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (loaded) return;
    getCompany();
  }, []);

  useEffect(() => {
    let filtered = companies;

    if (searchTerm) {
      filtered = filtered.filter((company: Company) =>
        company.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredCompanies(filtered);
  }, [searchTerm, companies]);

  if (loading) return <Skeleton active />;

  return (
    <>
      <div className="text-primary-600 text-xl font-semibold mb-2">
        Companies
      </div>

      <div className="flex flex-row gap-8 mb-6">
        <Input
          placeholder="Search Companies"
          prefix={<SearchIcon className="mx-3" />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          type="primary"
          className="w-64 h-[50px] text-base leading-[18px] font-semibold"
          onClick={() => navigate("/owner/company/create")}
        >
          Create New Company
        </Button>
      </div>

      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100vh - 280px)",
          paddingBottom: "10px",
        }}
      >
        {filteredCompanies && filteredCompanies.length > 0 ? (
          <Row gutter={[16, 16]}>
            {filteredCompanies.map((company) => (
              <Col key={company.id} span={8}>
                <Card
                  style={{
                    cursor: "pointer",
                    border: "1px solid #d9d9d9",
                    backgroundColor: "white",
                  }}
                  hoverable
                  onClick={() => navigate(`/owner/company/${company.id}`)}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={
                        company?.imageKey
                          ? `${cloudfrontKey}/${company?.imageKey}`
                          : testLogo
                      }
                      alt={company.name}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                      }}
                    />
                    <div
                      className="h-6 overflow-auto break-words"
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#333",
                        margin: "10px 10px",
                      }}
                    >
                      {company.name}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="flex items-center justify-center h-full py-[60px]">
            <EmptyState />
          </div>
        )}
      </div>
    </>
  );
};

export default OwnerCompanyScreen;
