import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setUser,
  setUserById,
  setDeleteUser,
  setUserByRole,
  userTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  getUserService,
  getUserByIdService,
  deleteUserByIdService,
  editUserService,
  getUserByRoleService,
  signUpService,
  logoutUserService,
} from "../../../services/educator/user";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorUserSagas() {
  yield all([
    takeLatest(types.GET_USER, handleGetUser),
    takeLatest(types.GET_USER_BY_ID, handleGetUserById),
    takeLatest(types.DELETE_USER, handleDeleteUser),
    takeLatest(types.EDIT_USER, handleEditUser),
    takeLatest(types.GET_USER_BY_ROLE, handleGetUserByRole),
    takeLatest(types.SIGN_UP, handleSignUp),
    takeLatest(types.LOGOUT_EDUCATOR, handleLogout),
  ]);
}

function* handleGetUser() {
  const { data }: AxiosResponse = yield call(getUserService);
  if (data) {
    yield put(setUser({ users: data }));
  }
}

function* handleGetUserById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getUserByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setUserById({ user: data }));
  }
}

function* handleDeleteUser(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteUserByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setDeleteUser(action.payload.id));
    showNotification("success", "User deleted successfully");
  }
}

function* handleEditUser(action: any) {
  const { data }: AxiosResponse = yield call(
    editUserService,
    action.payload.id,
    action.payload.user
  );
  if (data) {
    showNotification("success", "User updated successfully");
  }
}

function* handleGetUserByRole(action: any) {
  const { data }: AxiosResponse = yield call(
    getUserByRoleService,
    action.payload
  );
  if (data) {
    yield put(setUserByRole({ users: data }));
  }
}

function* handleSignUp(action: any) {
  const { data }: AxiosResponse = yield call(signUpService, action.payload);
  if (data) {
    showNotification("success", "Invitation sent successfully");
  }
}

function* handleLogout(action: any) {
  const { data }: AxiosResponse = yield call(logoutUserService);
  action.payload.navigate("/login");
}
