import { Button, Card,Typography, Row, Col, Input, Skeleton,Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import MenuItems from "../../../../assets/images/menu-items";
import ListHeader from "../components/ListHeader";
import PerformanceListItem from "../components/PerformanceRatingListItem";
import PerformanceUserListItem from "../components/PerformanceUserListItem";

const EducatorDashboardRatingsScreen = (props: any) => {
  const {
    lessons,
    actions: {
      getAnalytics,
    },
  } = props;

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLessons, setFilteredLessons] = useState(lessons?.data);
  const [singleLesson, setSingleLesson] = useState<any>(null);
  const [singleLessonActive, setSingleLessonActive] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState<any>(null);
  const [question,setQuestion] = useState<any>(null);
  const [questionActive, setQuestionActive] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<any>(null);

  useEffect(() => {
    document.body.classList.add("educator-dashboard-layout");

    return () => {
      document.body.classList.remove("educator-dashboard-layout");
    };
  }, []);

  useEffect(() => {
    getAnalytics({
      type: "rating",
    });
  }, []);

  useEffect(() => {
    if(questionActive){
      let filtered = question?.userRatings;
      if (searchTerm) {
        filtered = filtered.filter((user: any) =>
          user.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredUsers(filtered);
    }else if(singleLessonActive){
      let filtered = singleLesson?.questions;
      if (searchTerm) {
        filtered = filtered.filter((question: any) =>
          question.questionText.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredQuestions(filtered);
    }else{
      let filtered = lessons?.data;
      if (searchTerm) {
        filtered = filtered.filter((lesson: any) =>
          lesson.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredLessons(filtered);
    }
  }, [searchTerm, lessons, singleLesson, question]);


  const handleLessonSelection = (lesson: any) => {
    setSingleLesson(lesson);
    setFilteredQuestions(lesson.questions);
    setSingleLessonActive(true);
    setSearchTerm("");
  };

  const LessonsList = () => {
    if (!lessons || lessons?.loading) {
      return <Skeleton active />;
    }

    if (lessons?.loaded && filteredLessons?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Lesson className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available lessons.
          </div>

          <Button
            onClick={() => navigate("/educator/lesson/create")}
            className="w-full bg-primary-600 h-10 text-base font-semibold text-white"
          >
            Create New Lesson
          </Button>
        </div>
      );
    }

    if (lessons?.loaded) {
      return filteredLessons?.map((lesson: any, index: number) => (

        <Card key={index} className="shadow-md p-5">
        <div className="flex w-full h-full gap-5">
          <div className="flex-shrink-0">
            <img src={ process.env.REACT_APP_CLOUDFRONT_KEY + "/" + lesson.coverImageKey} alt={lesson.lessonTitle} className="w-32 h-20 object-cover rounded-lg" />
          </div>
          <div className="flex flex-col justify-between gap-3 w-full">
            <Typography.Text className="text-base text-neutral-600 font-semibold line-clamp-2">
              {lesson.lessonTitle}
            </Typography.Text>
    
            <Button
              className="w-full bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
              onClick={() => handleLessonSelection(lesson)}
            >
              View Lesson Ratings
            </Button>
     
          </div>
        </div>
      </Card>
      ));
    }
  };

  const SingleLessonList = () => {
    if (!singleLesson) {
      return <Skeleton active />;
    }

    if (filteredQuestions?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Lesson className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available questions.
          </div>
        </div>
      );
    }

    if (filteredQuestions.length > 0) {
      return filteredQuestions?.map((question: any) => (
        <PerformanceListItem
          key={question.questionId}
         question={question}
         handleSingleLessonOnClick={handleSingleLessonOnClick}
        ></PerformanceListItem>
      ));
    }
  };

  const handleSingleLessonOnClick = (question: any) => {
    setQuestion(question);
    setFilteredUsers(question.userRatings);
    setQuestionActive(true);
    setSearchTerm("");
  }

  const UserRatingsList = () => {
    if (!question) {
      return <Skeleton active />;
    }
  
    return filteredUsers.map((user: any, index: number) => (
      <PerformanceUserListItem
        key={index}
        user={user}
        rating={user.rating}
      ></PerformanceUserListItem>
    ));
  };

  const backToAllLessons = () => {
    setSingleLessonActive(false);
    setSearchTerm("");
  };

  const backToQuestions = () => {
    setQuestionActive(false);
    setSearchTerm("");
  };  

  return (
    <Row>
      <Col lg={12} className="pr-[80px]">
      <div className="text-primary-600 text-xl font-semibold mb-2">
            Performance
          </div>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{width:'20rem', height:'20rem'}} className="flex flex-col items-center text-3xl"/>
      </Col>

      <Col lg={12} className="pl-[80px]">
      {questionActive ? (
          <div
            onClick={backToQuestions}
            className="full-overview-selector text-primary-500 font-medium cursor-pointer"
          >
            &lt; Back to questions
          </div>
        ):
        singleLessonActive && (
          <div
            onClick={() => backToAllLessons()}
            className="full-overview-selector text-primary-500 font-medium cursor-pointer"
          >
            &lt; Back to full overview
          </div>
        )}
        <div className="bg-primary-100 w-full">
          <div className="bg-white p-5 w-full analytics-selector flex flex-col gap-4 rounded-lg">
            <div>
              <ListHeader
                title={questionActive ? singleLesson.lessonTitle + ' - ' + question.questionText : singleLessonActive ?  singleLesson.lessonTitle : "Ratings"}
                Icon={MenuItems.Lesson}
              />
            </div>
            <div className="analyitcs-searchbox sticky">
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder="Search"
                prefix={<SearchIcon className="" />}
                size="small"
              />
            </div>
          </div>
          <div className="p-4 bg-white rounded-lg mt-4">
            <div className="flex flex-col gap-3 max-h-[calc(100vh-450px)] overflow-y-auto">
              <>
              {questionActive ? (
                  <UserRatingsList />
                ) : singleLessonActive ? (
                  <SingleLessonList />
                ) : (
                  <LessonsList />
                )}

              </>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EducatorDashboardRatingsScreen;
