import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  getAlert,
  setAlert,
  setAlertById,
  alertTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  addAlertService,
  getAlertService,
  deleteAlertByIdService,
  getAlertByIdService,
} from "../../../services/educator/alert";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorAlertSagas() {
  yield all([
    takeLatest(types.ADD_ALERT, handleAddAlert),
    takeLatest(types.GET_ALERT, handleGetAlert),
    takeLatest(types.GET_ALERT_BY_ID, handleGetAlertById),
    takeLatest(types.DELETE_ALERT, handleDeleteAlert),
  ]);
}

function* handleAddAlert(action: any) {
  const { data }: AxiosResponse = yield call(
    addAlertService,
    action.payload.alert
  );
  if (data) {
    yield put(getAlert());
    action.payload.navigate("/success/alert");
  }
}

function* handleGetAlert() {
  const { data }: AxiosResponse = yield call(getAlertService);
  if (data) {
    yield put(setAlert({ alerts: data }));
  }
}

function* handleDeleteAlert(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteAlertByIdService,
    action.payload.id
  );

  if (data) {
    showNotification("success", "Alert deleted successfully");
    yield put({ type: types.GET_ALERT });
  }
}

function* handleGetAlertById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getAlertByIdService,
    action.payload.id
  );

  if (data) {
    yield put(setAlertById({ alert: data }));
  }
}
