import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  lessonTypes as types,
  setPresignedUrl,
  getCourse,
  getCourseStatistics,
  getLatestCourse,
  getLearnerAnalytics,
  getLearnerStatistics,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  markLessonAsCompletedService,
  getPresignedUrlService,
} from "../../../services/learner/lesson";

export default function* learnerLessonSagas() {
  yield all([
    takeLatest(types.MARK_AS_COMPLETE, handleMarkAsComplete),
    takeLatest(types.GET_PRESIGNED_URL, handleGetPresignedUrl),
  ]);
}

function* handleMarkAsComplete(action: any) {
  const response: AxiosResponse = yield call(
    markLessonAsCompletedService,
    action.payload.id
  );
  if (response.status === 200) {
    yield put(getCourse());
    yield put(getCourseStatistics());
    yield put(getLearnerStatistics());
    yield put(getLearnerAnalytics());
    yield put(getLatestCourse());
  }
}

function* handleGetPresignedUrl(action: any) {
  const response: AxiosResponse = yield call(
    getPresignedUrlService,
    action.payload.fileKey
  );
  if (response.status === 200) {
    yield put(setPresignedUrl(response.data));
  }
}
