import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setDepartment,
  setDepartmentById,
  setDepartmentUser,
  departmentTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  getDepartmentService,
  getDepartmentUserService,
  getDepartmentByIdService,
} from "../../../services/educator/department";
import { EntityIdPayload } from "../../../utils";

export default function* educatorDepartmentSagas() {
  yield all([
    takeLatest(types.GET_DEPARTMENT, handleGetDepartment),
    takeLatest(types.GET_DEPARTMENT_BY_ID, handleGetDepartmentById),
    takeLatest(types.GET_DEPARTMENT_USER, handleGetDepartmentWithUsers),
  ]);
}

function* handleGetDepartment() {
  const { data }: AxiosResponse = yield call(getDepartmentService);
  if (data) {
    yield put(setDepartment({ departments: data }));
  }
}

function* handleGetDepartmentById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getDepartmentByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setDepartmentById({ department: data }));
  }
}

function* handleGetDepartmentWithUsers() {
  const { data }: AxiosResponse = yield call(getDepartmentUserService);
  if (data) {
    yield put(setDepartmentUser({ departments: data }));
  }
}
