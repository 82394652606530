import React, { useState,useEffect } from "react";
import { useDispatch, } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Rate, Typography } from "antd";

import showNotification from "../../../services/notificationService";
import { learnerRatingTypes } from "../../../redux/learner/actions";

const { Text } = Typography;

const UserRatingScreen = ({
  ratingQuestions,
  setIsRatingState,
  lessonFinished,
  coursePoints,
  lessonId,
  showQuiz,
}: {
  ratingQuestions: any;
  setIsRatingState: React.Dispatch<React.SetStateAction<boolean>>;
  lessonFinished: boolean;
  coursePoints: number;
  lessonId: string;
  showQuiz: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const description = [
    "Very bad, not useful",
    "Bad",
    "Useful",
    "Good",
    "Excellent",
  ];

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [ratingValue, setRatingValue] = useState(0);
  const [ratedArray, setRatedArray] = useState<any>([]);

  useEffect(() => {
    if (ratingQuestions.length === 0 && !lessonFinished) 
      setIsRatingState(false);
    else if (ratingQuestions.length === 0 && lessonFinished) 
      showQuiz
    ? setIsRatingState(false)
    : navigate(`/success/user/course`, {
        state: { points: coursePoints },
      });
  }, [ratingQuestions, setIsRatingState]);

  const skip = () => {
    setCurrentQuestion(currentQuestion + 1);
    setRatingValue(0);

    if (currentQuestion !== ratingQuestions.length) return;

    if (ratedArray.length > 0) {
      dispatch({
        type: learnerRatingTypes.LEARNER_RATE_LESSON,
        payload: {
          id: lessonId,
          rating:
            ratingValue === 0
              ? [...ratedArray]
              : [
                  ...ratedArray,
                  {
                    rating: ratingValue,
                    questionId: ratingQuestions[currentQuestion - 1].id,
                  },
                ],
        },
      });
    }

    if (!lessonFinished) {
      setIsRatingState(false);
    } else {
      showQuiz
        ? setIsRatingState(false)
        : navigate(`/success/user/course`, {
            state: { points: coursePoints },
          });
    }
  };

  const next = () => {
    if (ratingValue === 0) {
      showNotification("error", "Please rate or skip question!");
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
    setRatedArray([
      ...ratedArray,
      {
        rating: ratingValue,
        questionId: ratingQuestions[currentQuestion - 1].id,
      },
    ]);
    setRatingValue(0);
  };

  const finish = () => {
    if (ratingValue === 0) {
      showNotification("error", "Please rate or skip question!");
      return;
    }
    setCurrentQuestion(currentQuestion + 1);

    setRatedArray([
      ...ratedArray,
      {
        rating: ratingValue,
        questionId: ratingQuestions[currentQuestion - 1].id,
      },
    ]);

    dispatch({
      type: learnerRatingTypes.LEARNER_RATE_LESSON,
      payload: {
        id: lessonId,
        rating: [
          ...ratedArray,
          {
            rating: ratingValue,
            questionId: ratingQuestions[currentQuestion - 1].id,
          },
        ],
      },
    });

    if (!lessonFinished) {
      setIsRatingState(false);
    } else {
      showQuiz
        ? setIsRatingState(false)
        : navigate(`/success/user/course`, {
            state: { points: coursePoints },
          });
    }
  };

  return (
    <div className="flex justify-center mt-[40px]">
      <div className="w-full xl:w-1/2 flex flex-col gap-6">
        <div className="bg-white p-10 flex flex-col gap-6 shadow-sm">
          <div className="flex items-center gap-4">
            <div
              className="h-[40px] w-[40px] flex justify-center items-center bg-primary-600 text-white font-semibold text-[16px] leading-[18px] rounded-full"
              style={{
                boxShadow: "inset 0 2px 3px 0 rgba(0, 0, 0, 0.25)",
              }}
            >
              {currentQuestion}
            </div>
            <Text className="text-neutral-600 text-sm">
              Rating Questions {currentQuestion}/{ratingQuestions.length}
            </Text>
          </div>
          <div className="text-neutral-600 font-semibold text-lg">
            {ratingQuestions[currentQuestion - 1]?.question}
          </div>
        </div>
        <div className="bg-white p-10 flex flex-col gap-8 shadow-sm rounded-lg">
          <div className="flex justify-center">
            <div className="flex flex-col items-center gap-4">
              <Rate
                rootClassName="text-rose-400 flex gap-3 text-[30px]"
                tooltips={description}
                onChange={setRatingValue}
                value={ratingValue}
              />
              {ratingValue ? (
                <span className="text-neutral-600 font-semibold text-base">
                  {description[ratingValue - 1]}
                </span>
              ) : null}
            </div>
          </div>
          <div>
            <div className="flex w-full gap-3">
              <Button
                className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[32px]"
                onClick={skip}
                disabled={currentQuestion === ratingQuestions.length + 1}
              >
                Skip
              </Button>
              <Button
                className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[32px]"
                disabled={currentQuestion === ratingQuestions.length + 1}
                onClick={
                  currentQuestion < ratingQuestions.length ? next : finish
                }
              >
                {currentQuestion < ratingQuestions.length
                  ? "Next"
                  : "Finish Rating"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRatingScreen;
