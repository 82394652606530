const course = "COURSE";
const module = "MODULE";
const alert = "ALERT";
const quizzie = "QUIZZIE";
const assignment = "ASSIGNMENT";
const discussion = "DISCUSSION";
const lessonDemand = "LESSON_DEMAND";

const newsFeedType = {
  course,
  module,
  alert,
  quizzie,
  assignment,
  discussion,
  lessonDemand,
};

export {
  newsFeedType,
  course,
  module,
  alert,
  quizzie,
  assignment,
  discussion,
  lessonDemand,
};
