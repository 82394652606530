import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  deleteLessonDemandById,
  getLessonDemand,
} from "../../../../redux/learner/actions";
import {
  selectLessonDemandLoading,
  selectLessonDemands,
  selectLessonDemandsLoaded,
} from "../../../../redux/learner/selector/lessonDemand";

const mapStateToProps = (state: StateType) => ({
  lessonDemands: selectLessonDemands(state),
  loaded: selectLessonDemandsLoaded(state),
  loading: selectLessonDemandLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonDemand,
      deleteLessonDemandById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
