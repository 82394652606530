import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getAssignments } from "../../../../redux/educator/actions";
import {
  selectAssignmentLoaded,
  selectAssignmentLoading,
  selectAssignments,
} from "../../../../redux/educator/selector";
import { selectLearnerAssignmentsNewsFeed } from "../../../../redux/learner/selector";
import {
  deleteNewsFeed,
  getLearnerNewsFeed,
} from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  assignments: selectAssignments(state),
  assignmentsNewsFeed: selectLearnerAssignmentsNewsFeed(state),
  loading: selectAssignmentLoading(state),
  loaded: selectAssignmentLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAssignments,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
