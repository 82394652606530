import { useEffect } from "react";
import { ConnectedProps, useSelector } from "react-redux";
import { Typography, Row, Col, Avatar } from "antd";

import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import testLogo from "../../../assets/images/company-structure-items/companyInfo.svg";
import CreateEntityInfo from "../../shared/CreateEntityInfo";
import { selectUser } from "../../../redux/auth/selector";
import connector from "./Container";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const CompanyInfoScreen = (props: ConnectedProps<typeof connector>) => {
  const {
    companyInfo,
    actions: { getCompanyInfoById, setCurrentStep },
  } = props;

  const user = useSelector(selectUser);

  useEffect(() => {
    setCurrentStep({ steps: 4, currentStep: 1 });
    const id = user?.companyId;
    id && !companyInfo && getCompanyInfoById({ id });
  }, [user]);

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={
            <>
              <div className="mb-6">Here is the Company’s information.</div>
              <div>
                Please note that only Admin can edit the info, so if it needs to
                be edited please contact your Admin.
              </div>
            </>
          }
        />
      </Col>
      <Col lg={16} className="gap-4 h-full flex flex-col max-w-[600px]">
        <div>
          <div className="bg-white shadow-md rounded-lg p-5 flex flex-col">
            <div className="flex justify-between items-center mb-6">
              <div className="flex gap-4">
                <CompanyStructureItems.Step1 />
                <Text className="text-neutral-600 font-normal text-lg">
                  Company Info
                </Text>
              </div>
            </div>
            <Text className="text-neutral-600 font-semibold text-sm">
              Company Name
            </Text>
            <div className="h-[50px] bg-neutral-100 flex items-center px-4 text-neutral-600 font-semibold text-base rounded-lg mt-4 mb-6 overflow-auto">
              {companyInfo?.name}
            </div>
            <div className="h-[100px] flex justify-between">
              <Text className="text-neutral-600 font-semibold text-sm">
                Company Logotype
              </Text>
              <Avatar
                size={100}
                className="shadow-md border border-neutral-200"
                src={
                  companyInfo?.imageKey
                    ? `${cloudfrontKey}/${companyInfo?.imageKey}`
                    : testLogo
                }
              ></Avatar>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CompanyInfoScreen;
