export const learnerRatingTypes = {
  LEARNER_RATE_LESSON: "LEARNER_RATE_LESSON",
  LEARNER_GET_RATING: "LEARNER_GET_RATING",
  LEARNER_SET_RATING: "LEARNER_SET_RATING",
};

export const learnerRateLesson = (payload: any) => ({
  type: learnerRatingTypes.LEARNER_RATE_LESSON,
  payload,
});

export const learnerGetRating = () => ({
  type: learnerRatingTypes.LEARNER_GET_RATING,
});

export const learnerSetRating = (payload: any) => ({
  type: learnerRatingTypes.LEARNER_SET_RATING,
  payload,
});
