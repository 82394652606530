import { useEffect, useState } from "react";
import { Button, Modal, Skeleton, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AddEntity from "../../../shared/AddEntity";
import { ReactComponent as lessonIcon } from "../../../../assets/images/reusable-items/lesson.svg";
import testImage from "../../../../assets/images/default-cover-image.jpg";
import SortableItem from "../../../shared/SortableItem";
import EntityCard from "../../../shared/EntityCard";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";

const { Text } = Typography;

const CourseDetailsScreen = (props: any) => {
  const {
    course,
    loading,
    actions: {
      getCourseById,
      deleteCourse,
      changePublishStatus,
      updateLessonOrder,
    },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [lessons, setLessons] = useState<
    { id: string; title: string; duration?: string; coverImageKey?: string }[]
  >([]);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [isEditingOrder, setIsEditingOrder] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

  useEffect(() => {
    getCourseById({ id });
  }, []);

  useEffect(() => {
    if (course?.lessons) {
      setLessons(course.lessons);
    }

    setSelectedDepartments(course?.departmentIds.map((id: any) => id));
    setSelectedPositions(course?.positionIds.map((id: any) => id));
  }, [course]);

  const onDeleteCourse = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this course?",
      onOk: async () => {
        await deleteCourse({ id, navigate });
      },
    });
  };

  const onChangePublishStatus = async (publish: boolean) => {
    Modal.confirm({
      title: `Are you sure you want to ${
        publish ? "publish" : "unpublish"
      } this course?`,
      onOk: async () => {
        await changePublishStatus({ id, publish, navigate });
      },
    });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = lessons.findIndex(
        (lesson: any) => lesson.id === active.id
      );
      const newIndex = lessons.findIndex(
        (lesson: any) => lesson.id === over.id
      );
      const reorderedLessons = arrayMove(lessons, oldIndex, newIndex);
      setLessons(reorderedLessons);
      setIsOrderChanged(true);
    }
  };

  const saveOrder = () => {
    const lessonIds = lessons.map((lesson) => lesson.id);
    updateLessonOrder({ id, lessonIds });
    setIsOrderChanged(false);
    setIsEditingOrder(false);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {course && (
        <div className="grid grid-cols-2 w-full gap-8 pb-4">
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Course Overview
            </Text>
            <div className="mt-3">
              <div className="bg-white rounded-lg p-6">
                <Text className="text-neutral-600 font-semibold text-sm">
                  Title
                </Text>
                <div className="w-100 mt-2 mb-6 min-h-[50px] bg-neutral-100 p-4 text-neutral-600 text-base font-semibold rounded-lg">
                  <Text>{course.title}</Text>
                </div>
                <Text className="text-neutral-600 font-semibold text-sm">
                  Description
                </Text>
                <div className="w-100 mt-2 mb-6 h-[156px] bg-neutral-100 p-4 text-neutral-600 text-base font-normal rounded-lg overflow-auto">
                  <Text>{course.description}</Text>
                </div>
                <Text className="text-neutral-600 font-semibold text-sm">
                  Cover Image
                </Text>
                <div>
                  <img
                    src={
                      course?.coverImageKey
                        ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${course?.coverImageKey}`
                        : testImage
                    }
                    alt="cover"
                    className="rounded-lg w-full  mt-2 mb-6"
                  />
                </div>
                <div className=" flex flex-col gap-3">
                  <DepartmentSelector
                    departments={course?.assignments?.departments}
                    onSelectionChange={handleDepartmentChange}
                    selectedDepartments={selectedDepartments}
                    setSelectedDepartments={setSelectedDepartments}
                    isDetailsState={true}
                    isAssignmentDetailsPage={true}
                  />
                  <PositionSelector
                    positions={course?.assignments?.positions}
                    onSelectionChange={handlePositionChange}
                    selectedPositions={selectedPositions}
                    setSelectedPositions={setSelectedPositions}
                    isDetailsState={true}
                    isAssignmentDetailsPage={false}
                  />
                </div>
              </div>
              <div className="w-100 flex flex-row justify-between mt-4 text-base font-semibold">
                <Button
                  type="default"
                  className={
                    "w-1/4 h-[46px] " +
                    (course.isPublished ? "bg-neutral-300" : "")
                  }
                  onClick={() => onChangePublishStatus(!course.isPublished)}
                >
                  {course.isPublished ? "Published" : "Publish"}
                </Button>
                <div className="w-1/2 flex gap-4">
                  <Button
                    type="primary"
                    className="w-2/4 h-[46px]"
                    onClick={() => navigate(`/educator/course/${id}/edit`)}
                  >
                    Edit
                  </Button>
                  <Button
                    danger
                    type="primary"
                    className="w-2/4 h-[46px]"
                    onClick={onDeleteCourse}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Selected Lessons
            </Text>
            <div className="h-0 min-h-[calc(100%-118px)] overflow-auto flex flex-col gap-4 mt-3">
              {lessons?.length > 0 ? (
                isEditingOrder ? (
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={lessons.map((lesson) => lesson.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {lessons.map((lesson) => (
                        <SortableItem
                          key={lesson.id}
                          id={lesson.id}
                          lesson={lesson}
                          navigate={navigate}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                ) : (
                  lessons.map((lesson) => (
                    <EntityCard
                      key={lesson.id}
                      title={lesson.title}
                      duration={lesson.duration || "0 hr 0 min"}
                      coverImageKey={lesson?.coverImageKey || ""}
                      footer={
                        <Button
                          type="primary"
                          className="w-full h-6 bg-primary-600"
                          onClick={() =>
                            navigate(`/educator/lesson/${lesson.id}`)
                          }
                        >
                          View
                        </Button>
                      }
                      classes="w-full max-w-[507px] h-[200px]"
                    />
                  ))
                )
              ) : (
                <div>
                  <div className="h-full w-full flex items-center justify-center mt-2">
                    <AddEntity
                      firstButtonText="Select Lessons"
                      firstButtonOnClick={() =>
                        navigate(
                          `/educator/course/${course.id}/assign-lessons`,
                          {
                            state: { isEdit: true, course },
                          }
                        )
                      }
                      Icon={lessonIcon}
                      cardClasses="shadow-md max-w-[583px] w-full max-h-[400px] p-[50px]"
                      imageClasses="w-[150px] text-center mb-10"
                    />
                  </div>
                </div>
              )}
            </div>
            {lessons?.length > 0 && (
              <div className="w-100 flex mt-4 text-base font-semibold flex-row-reverse">
                {isEditingOrder ? (
                  <Button
                    type="primary"
                    className="mt-4 w-1/4 h-[46px]"
                    disabled={!isOrderChanged}
                    onClick={saveOrder}
                  >
                    Save Order
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="mt-4 w-1/4 h-[46px]"
                    onClick={() => setIsEditingOrder(true)}
                  >
                    Change Order
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetailsScreen;
