import { Button, Typography, Modal, Row, Col, Empty } from "antd";
import { useEffect, useState } from "react";
import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import { useNavigate } from "react-router-dom";
import CreateEntityInfo from "../../shared/CreateEntityInfo";
import ItemList from "../../shared/CompanyStructure/ItemList";
import { getLoggedUser } from "../../../redux/auth/actions";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selector";

const { Text } = Typography;

const PositionsScreen = (props: any) => {
  const {
    positions,
    actions: { getPositionUser, setCurrentStep },
  } = props;
  const loggedUser = useSelector(selectUser);
  const [editingPosition, setEditingPosition] = useState<any>(null);
  const [editingName, setEditingName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep({ steps: 4, currentStep: 3 });
    getPositionUser();
    getLoggedUser();
  }, []);

  const handleViewUsers = (position: any) => {
    setSelectedPosition(position);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedPosition(null);
  };

  return (
    <Row className="h-full  pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text="Step 3 is to define the Company’s Structure by adding Position(s)."
        />
      </Col>
      <Col lg={16} className="gap-4 h-full flex flex-col max-w-[600px]">
        <div>
          <div className="flex gap-4 mb-6">
            <CompanyStructureItems.Step3 />
            <Text className="text-neutral-600 font-normal text-lg">
              Position(s)
            </Text>
          </div>
          <div className="w-full mb-2">
            <Text className="text-neutral-600 font-semibold text-sm">
              Position(s)
            </Text>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col gap-4">
            {positions && positions.length > 0 ? (
              <>
                <Text className="text-neutral-600 font-normal text-sm">
                  Created Position(s)
                </Text>
                <ItemList
                  loggedUserRole={loggedUser?.role}
                  itemType="Position"
                  items={positions}
                  editingItem={editingPosition}
                  setEditingItem={setEditingPosition}
                  editingName={editingName}
                  onViewUsers={handleViewUsers}
                  onChangeName={(e: any) => setEditingName(e.target.value)}
                />
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </div>
        <div className="flex w-full gap-10">
          <Button
            className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
            onClick={() => navigate("/educator/home")}
          >
            Save & Exit
          </Button>
          <Button
            className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[50px]"
            onClick={() => navigate("/educator/user")}
          >
            Continue
          </Button>
        </div>
      </Col>
      <Modal
        title={`Users in ${selectedPosition?.name}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mt-3">
          {selectedPosition?.users.map((user: any) => (
            <div
              key={user.id}
              className="w-full h-[50px] bg-neutral-200 rounded-lg flex items-center px-4 mb-1 justify-between"
            >
              <Text className="text-neutral-700 font-semibold text-sm leading-4">
                {user.name || user.email}
              </Text>
            </div>
          ))}
        </div>
      </Modal>
    </Row>
  );
};

export default PositionsScreen;
