import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Skeleton, Card, Tooltip, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import EmptyState from "../../../shared/EmptyState";
import { type LessonDemand } from "../../../../utils";

const { Text } = Typography;

const LearnerLessonDemandScreen = (props: {
  lessonDemands: LessonDemand[];
  loading: boolean;
  loaded: boolean;
  actions: {
    getLessonDemand: () => void;
    deleteLessonDemandById: (params: { id: string }) => void;
  };
}) => {
  const {
    lessonDemands,
    loading,
    loaded,
    actions: { getLessonDemand, deleteLessonDemandById },
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getLessonDemand();
  }, []);

  if (loading) return <Skeleton active />;

  return (
    <>
      {lessonDemands && lessonDemands.length > 0 ? (
        <div className="flex flex-col gap-7">
          <Card className="p-4 border-none shadow-sm rounded-lg">
            <div className="flex justify-between items-center">
              <Text className="text-neutral-500 font-semibold text-lg">
                Create a new Lesson Demand.
              </Text>
              <Button
                onClick={() => navigate("/user/lesson-demand/create")}
                type="primary"
                className="h-12 w-[250px] text-sm font-semibold bg-primary-600"
              >
                Start
              </Button>
            </div>
          </Card>
          <div className="border-b border-primary-300"></div>
          <Text className="text-primary-700 font-semibold text-xl">
            Your Lesson Demands
          </Text>

          <div>
            <div className="flex  flex-wrap gap-x-10 gap-y-4 overflow-y-scroll max-h-[calc(100vh-410px)]">
              {lessonDemands.map((lessonDemand: LessonDemand) => (
                <Card
                  key={lessonDemand.id}
                  className="w-[calc(50%-20px)] p-4 border-none shadow-sm rounded-lg"
                >
                  <div className="flex justify-between items-center gap-4">
                    <Text className="text-neutral-500 font-semibold text-base line-clamp-2 break-words">
                      {lessonDemand?.title}
                    </Text>
                    <div className="flex items-center gap-2">
                      <Button
                        onClick={() =>
                          navigate(`/user/lesson-demand/${lessonDemand.id}`)
                        }
                        type="primary"
                        className="h-10 min-w-[110px] xl:min-w-[135px] text-sm font-semibold bg-primary-600"
                      >
                        View
                      </Button>
                      <Tooltip title="Delete lesson demand">
                        <Popconfirm
                          title="Delete lesson demand"
                          description="Are you sure to delete this lesson demand?"
                          onConfirm={() =>
                            deleteLessonDemandById({ id: lessonDemand?.id })
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="link"
                            className="border-none shadow-none bg-transparent h-10  w-10 flex items-start"
                          >
                            <DeleteOutlined className="text-3xl text-rose-400" />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default LearnerLessonDemandScreen;
