import { Avatar, Typography } from "antd";
import {
  UserOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const PerformanceListItem = ({
  user,
  completedEntities,
  totalEntities,
  rating
}: any) => {
  return (
    <div className="flex gap-3 pr-2">
      <div className="w-full h-[36px] bg-neutral-100 rounded-lg flex items-center px-4 gap-2">
        <div className="flex items-center gap-2">
          <Avatar
            className="text-sm"
            style={{
              fontSize: "0.85rem",
              height: 26,
              width: 26,
            }}
            src={
              user?.profilePictureKey &&
              process.env.REACT_APP_CLOUDFRONT_KEY +
                "/" +
                user?.profilePictureKey
            }
            icon={<UserOutlined />}
          />
          <Text className="text-neutral-600 font-semibold text-sm leading-4 ml-3">
            {user.userName}
          </Text>
        </div>
      </div>
      {rating ? (
        <div
          className={`flex ${
            rating === "5" ? "bg-primary-200" : "bg-neutral-100"
          } rounded-lg px-4 items-center ${
            rating === "5" ? "text-primary-700" : "text-primary-600"
          }`}
        >
          {rating}
        </div>
      ) :
      completedEntities !== undefined && totalEntities !== undefined ? (
        <div
          className={`flex ${
            user?.progress === "100" ? "bg-primary-200" : "bg-neutral-100"
          } rounded-lg px-4 items-center ${
            user?.progress >= "100" ? "text-primary-700" : "text-primary-600"
          }`}
        >
          {completedEntities}/{totalEntities}
        </div>
      ) : user?.completed ? (
        <CheckCircleOutlined className="text-success-550" />
      ) : (
        <CloseCircleOutlined className="text-rose-400" />
      )}
    </div>
  );
};

export default PerformanceListItem;
