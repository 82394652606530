import { Typography, Button, Card, Image, Skeleton, Input } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CourseIcon } from "../../../../assets/images/reusable-items/course.svg";
import EmptyState from "../../../shared/EmptyState";
import testImage from "../../../../assets/images/default-cover-image.jpg";
import FilterByDepartments from "../../../shared/FilterByDepartments";
import FilterByPositions from "../../../shared/FilterByPositions";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";

const { Text } = Typography;

const EducatorModulesScreen = (props: any) => {
  const {
    modules,
    loading,
    loaded,
    actions: { getModule },
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredModules, setFilteredModules] = useState(modules);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getModule();
  }, []);

  useEffect(() => {
    setFilteredModules(
      modules.filter((module: any) =>
        module.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, modules]);

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);

    let query = "";
    for (let i = 0; i < departments.length; i++) {
      query += "departmentIds=" + departments[i] + "&";
    }
    for (let i = 0; i < selectedPositions.length; i++) {
      query += "positionIds=" + selectedPositions[i] + "&";
    }

    getModule({
      query,
    });
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);

    let query = "";
    for (let i = 0; i < positions.length; i++) {
      query += "positionIds=" + positions[i] + "&";
    }
    for (let i = 0; i < selectedDepartments.length; i++) {
      query += "departmentIds=" + selectedDepartments[i] + "&";
    }

    getModule({
      query,
    });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {modules ? (
        <>
          <div className="flex flex-row gap-9">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              type="primary"
              className="w-64 h-[50px] text-base leading-[18px] font-semibold"
              onClick={() => navigate("/educator/module/create")}
            >
              Create New Module
            </Button>
          </div>
          <div className="flex justify-end my-[18px]">
            <div className="flex justify-center w-[200px] gap-4">
              <FilterByDepartments
                onSelectionChange={handleDepartmentChange}
                selectedDepartments={selectedDepartments}
                setSelectedDepartments={setSelectedDepartments}
              />
              <FilterByPositions
                onSelectionChange={handlePositionChange}
                selectedPositions={selectedPositions}
                setSelectedPositions={setSelectedPositions}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 overflow-y-scroll max-h-[calc(100vh-290px)]">
            {filteredModules.length > 0 ? (
              filteredModules.map((content: any, index: any) => (
                <Card
                  key={content.id}
                  className="w-[calc(50%-0.5rem)] p-3 shadow-md"
                >
                  <div className="flex gap-4 h-full">
                    <div className="w-5/12 h-[130px]">
                      <Image
                        src={
                          content.coverImageKey
                            ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${content.coverImageKey}`
                            : testImage
                        }
                        preview={false}
                        className="rounded-lg object-cover"
                      />
                    </div>
                    <div className="flex flex-col justify-between w-7/12 gap-4">
                      <Text className="text-base text-neutral-600 font-semibold leading-[18px] line-clamp-3">
                        {content.title}
                      </Text>
                      <div>
                        <div className=" flex gap-2 mb-2 ml-1">
                          <CourseIcon
                            height={16}
                            className="fill-primary-400"
                          />

                          <Text className="text-sm text-neutral-500">
                            {content.courseCount} courses
                          </Text>
                        </div>
                        <div className="flex w-full gap-4">
                          <div className="w-full bg-rose-400 border-none text-white rounded-lg h-6 flex flex-row items-baseline gap-1 justify-center">
                            <Text className="text-white text-sm leading-6 font-semibold">
                              {content.points}
                            </Text>
                            <Text className="text-white  text-[10px] leading-3">
                              PTS
                            </Text>
                          </div>
                          <Button
                            onClick={() =>
                              navigate(`/educator/module/${content.id}`)
                            }
                            type="primary"
                            className="h-[24px] w-full bg-primary-600 font-semibold text-sm rounded-lg leading-[16px]"
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorModulesScreen;
