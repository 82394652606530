import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const createAssignmentService = async (assignmentData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.assignment, assignmentData));

export const getAssignmentsService = async (query: any) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.assignment.replace(":query", query))
  );

export const getAssignmentByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.assignmentById.replace(":id", id))
  );

export const getSubmissionsService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.submissionsById.replace(":id", id))
  );

export const getUserSubmissionService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.gradeSubmission.replace(":submissionId", id))
  );

export const deleteAssignmentByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.assignmentById.replace(":id", id))
  );

export const editAssignmentService = async (
  id: string,
  assignmentData: FormData
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.assignmentById.replace(":id", id), assignmentData)
  );

export const userSubmitFiles = async (id: string, files: FormData[]) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.userSubmitFiles.replace(":id", id), files)
  );

export const gradeSubmissionService = async (id: string, grade: FormData) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.gradeSubmission.replace(":submissionId", id), {
      grade,
    })
  );

export const createAssignmentCommentService = async (
  id: string,
  comment: any
) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.assignmentComment.replace(":id", id), { comment })
  );

export const editAssignmentCommentService = async (
  id: string,
  comment: boolean
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.assignmentCommentById.replace(":commentId", id), {
      comment,
    })
  );

export const deleteAssignmentCommentService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.assignmentCommentById.replace(":commentId", id))
  );
