import { useState, useEffect } from "react";
import {
  Button,
  Avatar,
  List,
  Typography,
  Skeleton,
  Modal,
  Form,
  Input,
  Select,
  Col,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import testLogo from "../../../../assets/images/company-structure-items/companyInfo.svg";
import { useParams, useNavigate } from "react-router-dom";
import CompanyEdit from "../../../educator/CompanyInfo/EditCompanyInfo";

const { Title, Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const CompanyDetails = (props: any) => {
  const {
    company,
    loading,
    actions: {
      getCompanyById,
      editCompany,
      deleteCompany,
      signUp,
      deleteAdmin,
    },
  } = props;

  const navigate = useNavigate();

  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getCompanyById({ id });
  }, []);

  const handleInviteNewUserClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinishSignUp = ({ email }: { email: string }) => {
    signUp({
      email,
      role: "ADMIN",
      companyId: id,
      departmentIds: [],
      positionIds: [],
    });
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleDeleteClick = () => {
    deleteCompany({ id, navigate });
  };

  const handleDeleteAdmin = (id: string) => {
    deleteAdmin({ id });
  };

  if (loading || !company) return <Skeleton active />;

  return (
    <div className="bg-white shadow-md rounded-lg p-5 flex flex-col mb-4">
      <div className="flex justify-between items-center mb-6">
        <Text className="text-neutral-600 font-normal text-lg">
          Company Info
        </Text>
        <div>
          <Button
            icon={<EditOutlined />}
            onClick={handleEditClick}
            className="mr-3"
          />

          <Popconfirm
            title="Are you sure to delete this company?"
            onConfirm={handleDeleteClick}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              type="text"
              className="border border-rose-500"
            />
          </Popconfirm>
        </div>
      </div>
      {isEditMode ? (
        <CompanyEdit
          companyInfo={company}
          setIsEditing={setIsEditMode}
          editCompanyInfo={editCompany}
        />
      ) : (
        <>
          <Text className="text-neutral-600 font-semibold text-sm">
            Company Name
          </Text>
          <div className="min-h-[50px] bg-neutral-100 flex items-center px-4 text-neutral-600 font-semibold text-base rounded-lg mt-4 mb-6">
            {company?.name}
          </div>
          <div className="h-[100px] flex justify-between">
            <Text className="text-neutral-600 font-semibold text-sm">
              Company Logotype
            </Text>
            <Avatar
              size={100}
              className="shadow-md border border-neutral-200"
              src={
                company?.imageKey
                  ? `${cloudfrontKey}/${company?.imageKey}`
                  : testLogo
              }
            ></Avatar>
          </div>
          <div className="mb-6">
            <Title level={4}>Admins</Title>

            <List
              className={`${
                !company?.users.length
                  ? "h-24 flex items-center justify-center"
                  : "max-h-24 overflow-auto"
              }`}
              itemLayout="horizontal"
              dataSource={company?.users}
              renderItem={(user: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          user?.profilePictureKey
                            ? `${cloudfrontKey}/${user?.profilePictureKey}`
                            : null
                        }
                        icon={<UserOutlined />}
                      ></Avatar>
                    }
                    title={
                      <div className="flex justify-between gap-5">
                        <Text style={{ lineHeight: "32px" }}>{user.email}</Text>
                        <Popconfirm
                          title="Are you sure to delete this admin?"
                          onConfirm={() => handleDeleteAdmin(user?.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="text"
                            className="border border-rose-500"
                            icon={<DeleteOutlined />}
                            danger
                          />
                        </Popconfirm>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <Button
            type="primary"
            onClick={handleInviteNewUserClick}
            className="h-[50px]"
          >
            Invite New User
          </Button>
        </>
      )}

      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <Col
          lg={16}
          className="h-full flex flex-col justify-between max-w-[600px]"
        >
          <Form
            form={form}
            name="basic"
            onFinish={onFinishSignUp}
            autoComplete="off"
            size="large"
          >
            <div className="w-full mb-7">
              <Text className="text-neutral-700 text-lg font-semibold">
                Admin Information
              </Text>
            </div>

            <Text className="text-neutral-600 text-sm font-semibold">
              Email Address
            </Text>

            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              className="mt-2"
            >
              <Input
                type="email"
                autoComplete="off"
                name="email"
                placeholder="Input Email Address"
              />
            </Form.Item>

            <Form.Item>
              <div className="w-full flex justify-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-1/2 bg-primary-600 h-[50px]"
                >
                  Send Invitation
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Modal>
    </div>
  );
};

export default CompanyDetails;
