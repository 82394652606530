import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Layout, Row, Col } from "antd";

import HeaderItems from "../../../assets/images/header-items";
import EducatorHeaderProgressItem from "./HeaderProgressItem";
import { useSelector } from "react-redux";
import { selectStepper } from "../../../redux/shared/selectors";

const { Header } = Layout;
const EducatorHeader = () => {
  const { pathname } = useLocation();
  const stepper = useSelector(selectStepper);

  const [headerValues, setHeaderValues] = useState<any>(
    new Map([
      [
        "/educator/home",
        {
          Icon: HeaderItems.Home,
          title: "Welcome to your Home.",
          subtitle:
            "Create various options, check Analytics or see Lesson Demands",
          hasStepper: false,
        },
      ],
      [
        "/educator/dashboard/course",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your Course Dashboard.",
          subtitle: "Here you can monitor Students, Courses and Lessons.",
          hasStepper: false,
        },
      ],
      [
        "/educator/dashboard/module",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your Module Dashboard.",
          subtitle: "Here you can monitor Students, Courses and Lessons.",
          hasStepper: false,
        },
      ],
      [
        "/educator/dashboard/lesson",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your Lesson Dashboard.",
          subtitle: "Here you can monitor Students, Courses and Lessons.",
          hasStepper: false,
        },
      ],
      [
        "/educator/dashboard/user",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your User Dashboard.",
          subtitle: "Here you can monitor Students, Courses and Lessons.",
          hasStepper: false,
        },
      ],
      [
        "/educator/dashboard/rating",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your Rating Dashboard.",
          subtitle: "Here you can monitor Lessons ratings.",
          hasStepper: false,
        },
      ],
      [
        "/educator/module",
        {
          Icon: HeaderItems.Module,
          title: "Modules",
          hasStepper: false,
        },
      ],
      [
        "/educator/module/create",
        {
          Icon: HeaderItems.Module,
          title: "Create New Module",
          hasStepper: true,
        },
      ],
      [
        "/educator/module/:id/assign-courses",
        {
          Icon: HeaderItems.Module,
          title: "Select courses for module",
          hasStepper: true,
        },
      ],
      [
        "/educator/module/:id",
        {
          Icon: HeaderItems.Module,
          title: "Module details",
          hasStepper: false,
        },
      ],
      [
        "/educator/module/:id/edit",
        {
          Icon: HeaderItems.Module,
          title: "Module Edit",
          hasStepper: true,
        },
      ],
      [
        "/educator/course/create",
        {
          Icon: HeaderItems.Course,
          title: "Create New Course",
          hasStepper: true,
        },
      ],
      [
        "/educator/course",
        {
          Icon: HeaderItems.Course,
          title: "Courses",
          hasStepper: false,
        },
      ],
      [
        "/educator/course/:id",
        {
          Icon: HeaderItems.Course,
          title: "Course details",
          hasStepper: false,
        },
      ],
      [
        "/educator/course/:id/edit",
        {
          Icon: HeaderItems.Course,
          title: "Course edit",
          hasStepper: false,
        },
      ],
      [
        "/educator/course/:id/assign-lessons",
        {
          Icon: HeaderItems.Course,
          title: "Select lessons for course",
          hasStepper: true,
        },
      ],
      [
        "/educator/lesson/create",
        {
          Icon: HeaderItems.Lesson,
          title: "Create New Lesson",
          hasStepper: true,
        },
      ],
      [
        "/educator/lesson/:id/edit",
        {
          Icon: HeaderItems.Lesson,
          title: "Lesson edit",
          hasStepper: true,
        },
      ],
      [
        "/educator/lesson",
        {
          Icon: HeaderItems.Lesson,
          title: "Lessons",
          hasStepper: false,
        },
      ],
      [
        `/educator/lesson/:id`,
        {
          Icon: HeaderItems.Lesson,
          title: "Lesson details",
          hasStepper: false,
        },
      ],
      [
        `/educator/lesson/:id/assign-quiz`,
        {
          Icon: HeaderItems.Lesson,
          title: "Create Lesson",
          hasStepper: true,
        },
      ],
      [
        "/educator/quiz/create",
        {
          Icon: HeaderItems.Quiz,
          title: "Create Quiz",
          hasStepper: true,
        },
      ],
      [
        "/educator/quiz",
        {
          Icon: HeaderItems.Quiz,
          title: "Quiz",
          hasStepper: false,
        },
      ],
      [
        `/educator/quiz/:id`,
        {
          Icon: HeaderItems.Quiz,
          title: "Edit Quiz",
          hasStepper: true,
        },
      ],
      [
        "/educator/company-info",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Info",
          hasStepper: true,
        },
      ],
      [
        "/educator/department",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: true,
        },
      ],
      [
        "/educator/position",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: true,
        },
      ],
      [
        "/educator/user",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: true,
        },
      ],
      [
        "/educator/sign-up",
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Company Structure",
          hasStepper: false,
        },
      ],
      [
        `/educator/user/:id`,
        {
          Icon: HeaderItems.CompanyStructure,
          title: "Edit User",
          hasStepper: false,
        },
      ],
      [
        "/educator/quizzie/create",
        {
          Icon: HeaderItems.Quizzie,
          title: "Create Quizzie",
          hasStepper: true,
        },
      ],
      [
        "/educator/quizzie",
        {
          Icon: HeaderItems.Quizzie,
          title: "Quizzie",
          hasStepper: false,
        },
      ],
      [
        `/educator/quizzie/:id`,
        {
          Icon: HeaderItems.Quizzie,
          title: "New Quizzie from existing",
          hasStepper: true,
        },
      ],
      [
        `/educator/alert`,
        {
          Icon: HeaderItems.Alert,
          title: "Alerts",
          hasStepper: false,
        },
      ],
      [
        `/educator/alert/create`,
        {
          Icon: HeaderItems.Alert,
          title: "New Alert",
          hasStepper: true,
        },
      ],
      [
        "/educator/discussion",
        {
          Icon: HeaderItems.Discussion,
          title: "My Discussion Overview",
          hasStepper: false,
        },
      ],
      [
        `/educator/benchmark`,
        {
          Icon: HeaderItems.Benchmark,
          title: "Welcome to your Benchmark",
          hasStepper: false,
        },
      ],
      [
        `/educator/ai-content`,
        {
          Icon: HeaderItems.AI,
          title: "AI Content",
          hasStepper: false,
        },
      ],
      [
        `/educator/ai-content/create`,
        {
          Icon: HeaderItems.AI,
          title: "Create AI Content",
          hasStepper: false,
        },
      ],
      [
        "/educator/assignment",
        {
          Icon: HeaderItems.Assignment,
          title: "Assignments",
          hasStepper: false,
        },
      ],
      [
        "/educator/assignment/create",
        {
          Icon: HeaderItems.Assignment,
          title: "Create Assignment",
          hasStepper: false,
        },
      ],
      [
        "/educator/assignment/:id/grade",
        {
          Icon: HeaderItems.Assignment,
          title: "View & Grade Assignment",
          hasStepper: false,
        },
      ],
      [
        "/educator/assignment/:id",
        {
          Icon: HeaderItems.Assignment,
          title: "Assignment Overview",
          hasStepper: false,
        },
      ],
      [
        "/educator/discussion/create",
        {
          Icon: HeaderItems.Discussion,
          title: "Start Discussion",
          hasStepper: false,
         },
      ],
      [
        "/educator/lesson-demand",
        {
          Icon: HeaderItems.LessonDemand,
          title: "Demand Lesson",
          hasStepper: false,
        },
      ],
    ])
  );
  const [CurrentHeaderValue, setCurrentHeaderValue] = useState<any>(null);

  useEffect(() => {
    getHeaderPropsForCurrentPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getHeaderPropsForCurrentPath = () => {
    const mapKeysArr = Array.from(headerValues.keys());
    const currentValueByPath = mapKeysArr.find((key: any) =>
      matchPath(key, pathname)
    );

    const headerValueToSet = headerValues.get(currentValueByPath);
    if (headerValueToSet) {
      setCurrentHeaderValue(headerValueToSet);
      return;
    }

    setCurrentHeaderValue(null);
  };

  return (
    <>
      {CurrentHeaderValue && (
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="educator-header-container h-full flex items-center">
            <Row className="w-full">
              <Col
                lg={CurrentHeaderValue.subtitle ? "auto" : 8}
                className="flex items-center gap-5"
              >
                <CurrentHeaderValue.Icon className="w-[30px] h-[30px] fill-primary-600 text-3xl text-primary-600" />
                <div>
                  <div className="text-sm font-semibold">
                    {CurrentHeaderValue.title}
                  </div>
                  {CurrentHeaderValue.subtitle && (
                    <div className="text-xs">{CurrentHeaderValue.subtitle}</div>
                  )}
                </div>
              </Col>
              <Col lg={16} className="flex gap-2 max-w-[600px] items-center">
                {CurrentHeaderValue?.hasStepper &&
                  stepper.map((item, index) => (
                    <EducatorHeaderProgressItem key={index} isFilled={item} />
                  ))}
              </Col>
            </Row>
          </div>
        </Header>
      )}
    </>
  );
};

export default EducatorHeader;
