import { produce } from "immer";

import { adminRatingTypes as types } from "../actions";
import { RatingStateType } from "../../../utils/types/Learner/rating/RatingState";

const defaultState: RatingStateType = {
  ratings: [],
  loading: false,
  loaded: false,
};

const handleSetRatings = (state: RatingStateType, payload: any) => {
  state.ratings = payload.ratings;
  state.loaded = true;
  state.loading = false;

  return state;
};

export default produce((state: RatingStateType = defaultState, action) => {
  switch (action.type) {
    case types.ADMIN_SET_RATING:
      return handleSetRatings(state, action.payload);
    case types.ADMIN_GET_RATING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
});
