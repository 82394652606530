import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Card, Form, Input, Select } from "antd";
import EducatorSelector from "../../../shared/EducatorSelector";

const { Text } = Typography;
const { TextArea } = Input;

const CreateDiscussionScreen = (props: any) => {
  const {
    actions: { createDiscussion },
  } = props;

  const [form] = Form.useForm();
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [selectedEducators, setSelectedEducators] = useState<string[]>([]);
  const discussionCategories = [
    {
      label: "General",
      value: "GENERAL",
    },
    {
      label: "Module",
      value: "MODULE",
    },
    {
      label: "Lesson",
      value: "LESSON",
    },
    {
      label: "Course",
      value: "COURSE",
    },
  ];

  const navigate = useNavigate();

  const handleEducatorChange = (educators: string[]) => {
    setSelectedEducators(educators);
  };

  const onFinish = () => {
    createDiscussion({
      discussions: { category, title, assignedTo: selectedEducators, question },
      navigate,
      createdByUser: true,
    });
  };

  return (
    <>
      <div className="xl:flex">
        <div className="xl:w-1/3 md:pb-4 xl:p-0">
          <div className="flex flex-col flex-wrap gap-4 xl:max-w-[230px]">
            <Text className="text-neutral-500 font-normal text-base">
              Start a new Discussion by filling the form on the right.
            </Text>
          </div>
        </div>
        <div className="xl:w-2/3 pb-[60px]">
          <Card className="p-8 shadow-md xl:max-w-[600px] border-none">
            <div className="h-full flex flex-col justify-between">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                size="large"
              >
                <Text className="text-neutral-600 text-sm font-semibold">
                  Select Category
                </Text>
                <Form.Item
                  name="category"
                  rules={[
                    { required: true, message: "Please select category!" },
                  ]}
                  className="mt-2"
                >
                  <Select
                    value={category}
                    onSelect={(e) => setCategory(e)}
                    placeholder="Select from the List"
                    allowClear
                    className="min-h-[50px] shadow-md rounded-lg discussion-select"
                  >
                    {discussionCategories.map((category) => (
                      <Select.Option
                        key={category.value}
                        value={category.value}
                      >
                        {category.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Text className="text-neutral-600 text-sm font-semibold">
                  Title
                </Text>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: "Please type title!" }]}
                  className="mt-2 discussion-input"
                >
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                    name="title"
                    placeholder="Type title here"
                    className="shadow-md rounded-lg"
                  />
                </Form.Item>

                <Text className="text-neutral-600 text-sm font-semibold">
                  Select User / Educator
                </Text>
                <EducatorSelector
                  onSelectionChange={handleEducatorChange}
                  selectedOptions={selectedEducators}
                  setSelectedOptions={setSelectedEducators}
                />

                <Text className="text-neutral-600 text-sm font-semibold">
                  Add Question / Description
                </Text>
                <Form.Item
                  name="question"
                  rules={[
                    {
                      required: true,
                      message: "Please add question / description!",
                    },
                  ]}
                  className="mt-2 discussion-input"
                >
                  <TextArea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Type here"
                    autoSize={{ minRows: 6, maxRows: 10 }}
                    className="shadow-md font-light"
                  />
                </Form.Item>

                <Form.Item>
                  <div className="w-full flex justify-between gap-4">
                    <Button
                      onClick={() => navigate("/user/discussion")}
                      className="w-full bg-white h-[50px] border border-primary-200 text-primary-600"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full bg-primary-600 h-[50px]"
                      disabled={
                        !category ||
                        !title ||
                        !selectedEducators.length ||
                        !question
                      }
                    >
                      Add New Discussion
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CreateDiscussionScreen;
