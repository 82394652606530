import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  getCourse,
  getCourseStatistics,
  getLatestCourse,
  getLearnerAnalytics,
  getLearnerStatistics,
  setCourse,
  setCourseById,
  setLatestCourse,
  courseTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  getCourseService,
  getCourseByIdService,
  getLatestCourseService,
  restartCourseByIdService,
} from "../../../services/learner/course";
import { EntityIdPayload } from "../../../utils";

import showNotification from "../../../services/notificationService";

export default function* learnerCourseSagas() {
  yield all([
    takeLatest(types.GET_COURSE, handleGetCourse),
    takeLatest(types.GET_COURSE_BY_ID, handleGetCourseById),
    takeLatest(types.GET_LATEST_COURSE, handleGetLatestCourse),
    takeLatest(types.RESTART_COURSE_BY_ID, handleRestartCourseById),
  ]);
}

function* handleGetCourse() {
  const { data }: AxiosResponse = yield call(getCourseService);
  if (data) {
    yield put(setCourse({ courses: data }));
  }
}

function* handleGetCourseById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getCourseByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setCourseById({ course: data }));
  }
}

function* handleGetLatestCourse() {
  const { data }: AxiosResponse = yield call(getLatestCourseService);
  if (data) {
    yield put(setLatestCourse({ latestCourse: data }));
    return;
  }
  yield put(setLatestCourse({ latestCourse: null }));
}

function* handleRestartCourseById(action: EntityIdPayload) {
  yield call(restartCourseByIdService, action.payload.id);
  const { data }: AxiosResponse = yield call(
    getCourseByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setCourseById({ course: data }));
    showNotification("success", "Course restarted successfully!");
    yield put(getCourse());
    yield put(getCourseStatistics());
    yield put(getLearnerStatistics());
    yield put(getLearnerAnalytics());
    yield put(getLatestCourse());
  }
}
