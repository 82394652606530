import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../utils/types";
import { selectPositions } from "../../../redux/admin/selector";
import {
  getPositionUser,
  addPosition,
  editPosition,
  deletePosition,
} from "../../../redux/admin/actions";
import { setCurrentStep } from "../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  positions: selectPositions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getPositionUser,
      addPosition,
      editPosition,
      deletePosition,
      setCurrentStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
