export const adminCompanyInfoTypes = {
  ADMIN_GET_COMPANY_INFO_BY_ID: "ADMIN_GET_COMPANY_INFO_BY_ID",
  ADMIN_SET_COMPANY_INFO_BY_ID: "ADMIN_SET_COMPANY_INFO_BY_ID",
  ADMIN_EDIT_COMPANY_INFO: "ADMIN_EDIT_COMPANY_INFO",
};

export const adminGetCompanyInfoById = (id: any) => ({
  type: adminCompanyInfoTypes.ADMIN_GET_COMPANY_INFO_BY_ID,
  payload: id,
});

export const adminSetCompanyInfoById = (payload: any) => ({
  type: adminCompanyInfoTypes.ADMIN_SET_COMPANY_INFO_BY_ID,
  payload,
});

export const adminEditCompanyInfo = (payload: any) => ({
  type: adminCompanyInfoTypes.ADMIN_EDIT_COMPANY_INFO,
  payload,
});
