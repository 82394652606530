import { createSelector } from "reselect";
import { LearnerNewsFeedStateType } from "../../../utils/types";

const selectLearnerNewsFeedState = (
  state: any
): { newsFeed: LearnerNewsFeedStateType } => state.learnerNewsFeedReducer;

export const selectLearnerNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed
);

export const selectLearnerAlertsNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.alerts
);

export const selectLearnerAssignmentsNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.assignments
);

export const selectLearnerCoursesNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.courses
);

export const selectLearnerDiscussionsNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.discussions
);

export const selectLearnerLessonDemandsNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) =>
    state.newsFeed?.lessonDemands
);

export const selectLearnerModulesNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.modules
);

export const selectLearnerQuizziesNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed?.quizzies
);

export const selectLearnerNewsFeedLoading = createSelector(
  selectLearnerNewsFeedState,
  (state: any) => state.loading
);
