import { Typography, Button, Input, Skeleton, Tooltip } from "antd";
import { useState, useEffect } from "react";
import EntityCard from "../../../shared/EntityCard";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import EmptyState from "../../../shared/EmptyState";
import { useNavigate } from "react-router-dom";
import FilterByDepartments from "../../../shared/FilterByDepartments";
import FilterByPositions from "../../../shared/FilterByPositions";

const { Text } = Typography;

const EducatorCoursesScreen = (props: any) => {
  const {
    courses,
    loading,
    loaded,
    actions: { getCourse },
  } = props;

  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getCourse();
  }, []);

  useEffect(() => {
    let filtered = courses;

    if (filter === "PUBLISHED") {
      filtered = filtered.filter((course: any) => course.isPublished);
    } else if (filter === "UNPUBLISHED") {
      filtered = filtered.filter((course: any) => !course.isPublished);
    }

    if (searchTerm) {
      filtered = filtered.filter((course: any) =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredCourses(filtered);
  }, [searchTerm, filter, courses]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);

    let query = "";
    for (let i = 0; i < departments.length; i++) {
      query += "departmentIds=" + departments[i] + "&";
    }
    for (let i = 0; i < selectedPositions.length; i++) {
      query += "positionIds=" + selectedPositions[i] + "&";
    }

    getCourse({
      query,
    });
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);

    let query = "";
    for (let i = 0; i < positions.length; i++) {
      query += "positionIds=" + positions[i] + "&";
    }
    for (let i = 0; i < selectedDepartments.length; i++) {
      query += "departmentIds=" + selectedDepartments[i] + "&";
    }

    getCourse({
      query,
    });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {courses ? (
        <div>
          <div className="flex flex-row gap-9">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              type="primary"
              className="w-64 h-[50px] text-base leading-[18px] font-semibold"
              onClick={() => navigate("/educator/course/create")}
            >
              Create New Course
            </Button>
          </div>
          <div className="flex items-center justify-between mt-4">
            <div className="flex gap-4">
              <Button
                type="link"
                onClick={() => handleFilterChange("ALL")}
                className={
                  filter === "ALL"
                    ? "text-primary-600 font-semibold"
                    : "text-neutral-400 font-normal"
                }
              >
                ALL
              </Button>
              <Button
                type="link"
                onClick={() => handleFilterChange("PUBLISHED")}
                className={
                  filter === "PUBLISHED"
                    ? "text-primary-600 font-semibold"
                    : "text-neutral-400 font-normal"
                }
              >
                PUBLISHED
              </Button>
              <Button
                type="link"
                onClick={() => handleFilterChange("UNPUBLISHED")}
                className={
                  filter === "UNPUBLISHED"
                    ? "text-primary-600 font-semibold"
                    : "text-neutral-400 font-normal"
                }
              >
                UNPUBLISHED
              </Button>
            </div>
            <div className="flex justify-center gap-4 w-[200px]">
              <FilterByDepartments
                onSelectionChange={handleDepartmentChange}
                selectedDepartments={selectedDepartments}
                setSelectedDepartments={setSelectedDepartments}
              />
              <FilterByPositions
                onSelectionChange={handlePositionChange}
                selectedPositions={selectedPositions}
                setSelectedPositions={setSelectedPositions}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 mt-4  overflow-y-scroll max-h-[calc(100vh-290px)]">
            {filteredCourses?.length > 0 ? (
              filteredCourses.map((content: any, index: any) => (
                <EntityCard
                  key={content.id}
                  title={content.title}
                  coverImageKey={content.coverImageKey || ""}
                  numberOfEntities={content.lessonCount}
                  duration={content.duration || "0 hr 0 min"}
                  footer={
                    <div className="flex flex-row items-center gap-4">
                      <div className="w-full bg-rose-400 border-none text-white py-[7px] rounded-lg h-8 flex flex-row items-baseline gap-1 justify-center">
                        <Text className="text-white text-base leading-[18px] font-semibold">
                          {content.points}
                        </Text>
                        <Text className="text-white  text-[10px] leading-3">
                          PTS
                        </Text>
                      </div>
                      <Button
                        className="w-full bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
                        onClick={() =>
                          navigate("/educator/course/" + content.id)
                        }
                      >
                        View Lessons
                      </Button>
                    </div>
                  }
                />
              ))
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>{" "}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorCoursesScreen;
