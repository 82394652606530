import { call, all, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  setDiscussions,
  setDiscussionById,
  getDiscussionById,
  discussionTypes as types,
  getDiscussions,
  setDeletedDiscussionById,
  setChangeResolvedStatus,
} from "../actions";

import { EntityIdPayload } from "../../../utils";
import {
  changeResolvedStatusDiscussionService,
  createDiscussionCommentService,
  createDiscussionService,
  deleteCommentDiscussionService,
  deleteDiscussionService,
  editCommentDiscussionService,
  getDiscussionByIdService,
  getDiscussionsService,
  likeCommentDiscussionService,
} from "../../../services/learner/discussion";
import showNotification from "../../../services/notificationService";

export default function* educatorCourseSagas() {
  yield all([
    takeLatest(types.CREATE_DISCUSSION, handleCreateDiscussion),
    takeLatest(types.GET_DISCUSSIONS, handleGetDiscussions),
    takeLatest(types.GET_DISCUSSION_BY_ID, handleGetDiscussionById),
    takeLatest(types.CHANGE_RESOLVED_STATUS, handleChangeResolvedStatus),
    takeLatest(types.CREATE_COMMENT, handleCreateComment),
    takeLatest(types.EDIT_COMMENT, handleEditComment),
    takeLatest(types.DELETE_COMMENT, handleDeleteComment),
    takeLatest(types.LIKE_COMMENT, handleLikeComment),
    takeLatest(types.DELETE_DISCUSSION, handleDeleteDiscussion),
  ]);
}

function* handleCreateDiscussion(action: any) {
  const { data }: AxiosResponse = yield call(
    createDiscussionService,
    action.payload.discussions
  );
  if (data) {
    yield put(getDiscussions());

    action.payload.createdByUser
      ? action.payload.navigate(`/success/user/discussion`)
      : action.payload.navigate(`/success/educator/discussion`);
  }
}

function* handleGetDiscussions() {
  const { data }: AxiosResponse = yield call(getDiscussionsService);
  if (data) {
    yield put(setDiscussions({ discussions: data }));
  }
}

function* handleGetDiscussionById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getDiscussionByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setDiscussionById({ discussion: data }));
  }
}

function* handleChangeResolvedStatus(action: any) {
  const { data }: AxiosResponse = yield call(
    changeResolvedStatusDiscussionService,
    action.payload.id,
    action.payload.isResolved
  );

  if (data) {
    yield put(setChangeResolvedStatus({ id: action.payload.id }));
  }
}

function* handleCreateComment(action: any) {
  const { data }: AxiosResponse = yield call(
    createDiscussionCommentService,
    action.payload.id,
    action.payload.comment
  );
}

function* handleEditComment(action: any) {
  const { data }: AxiosResponse = yield call(
    editCommentDiscussionService,
    action.payload.id,
    action.payload.comment
  );
}

function* handleDeleteComment(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteCommentDiscussionService,
    action.payload.id
  );
  if (data) {
    showNotification("success", data.message);
  }
}

function* handleLikeComment(action: any) {
  const { data }: AxiosResponse = yield call(
    likeCommentDiscussionService,
    action.payload.id,
    action.payload.isLiked
  );
}

function* handleDeleteDiscussion(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteDiscussionService,
    action.payload.id
  );
  if (data.message) {
    showNotification("success", data.message);
    yield put(setDeletedDiscussionById({ id: action.payload.id }));
  }
}
