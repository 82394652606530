import { useNavigate } from "react-router-dom";
import { Button, Card, Typography } from "antd";

import { assignmentProgress } from "../../../../utils/helpers/calculateAssignmentProgress";
import CircleProgress from "../../../shared/CircleProgress";
import AssignedToList from "./AssignedToList";

const { Text } = Typography;

const AssignmentActiveCard = ({
  assignment,
  percentage,
  users,
  onDelete,
}: any) => {
  const navigate = useNavigate();

  return (
    <Card className=" p-8 border-none shadow-md rounded-lg">
      <div className="flex flex-col gap-5">
        <div>
          <div className="flex justify-center">
            <div className="flex items-center gap-5">
              <CircleProgress
                size={100}
                percentage={assignmentProgress(percentage)}
              />
              <div className="text-neutral-600 text-base">Users Completed</div>
            </div>
          </div>
        </div>
        <div className="border-b border-neutral-200"></div>
        <Text className="text-neutral-600 font-semibold text-lg">
          {assignment?.title}
        </Text>
        <div className="border-b border-neutral-200"></div>
        <div>
          <div className="flex flex-col pb-4 border-b border-b-neutral-200 max-h-[calc(100vh-520px)] overflow-y-auto">
            <div>
              {assignment && (
                <AssignedToList
                  departmentIds={assignment?.departmentIds}
                  positionIds={assignment?.positionIds}
                  userIds={assignment?.userIds}
                  users={users}
                />
              )}
            </div>
          </div>
        </div>
        <Button
          onClick={() =>
            navigate(`/educator/assignment/${assignment?.id}/grade`)
          }
          type="primary"
          className="h-[50px] shadow-none rounded-lg"
        >
          View and Grade
        </Button>
        <div className="flex gap-4">
          <Button
            onClick={() => navigate(`/educator/assignment/${assignment?.id}`)}
            type="primary"
            className="w-full h-[50px] shadow-none rounded-lg bg-white text-primary-500 border-primary-200"
          >
            Re-Assign
          </Button>
          <Button
            onClick={onDelete}
            type="primary"
            className="w-full h-[50px] shadow-none rounded-lg bg-white text-primary-500 border-primary-200"
          >
            Delete
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default AssignmentActiveCard;
