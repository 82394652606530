import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addCourseService = async (courseData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.course, courseData));

export const getCourseService = async (query: any) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.course.replace(":query", query))
  );

export const getCourseByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.courseByIdIncludeStructure.replace(":id", id))
  );

export const deleteCourseByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.courseById.replace(":id", id))
  );

export const editCourseService = async (id: string, courseData: FormData) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.courseById.replace(":id", id), courseData)
  );

export const assignLessonsService = async (
  courseId: string,
  lessonIds: string[]
) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.courseAssignLessons.replace(":id", courseId), {
      lessonIds,
    })
  );

export const changePublishStatusCourseService = async (
  id: string,
  publish: boolean
) =>
  handleApiCall(() =>
    ApiService.patch(apiRoutes.courseById.replace(":id", id), {
      publish,
    })
  );

export const updateLessonOrderService = async (
  courseId: string,
  lessonIds: string[]
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.updateLessonOrder.replace(":id", courseId), {
      order: lessonIds,
    })
  );
