import React from "react";
import { Typography,
  Tooltip,Popconfirm,Button, } from "antd";
import {
  ArrowRightOutlined,
  MinusOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { DiscussionType } from "../../../utils";
import { discussionTypes } from "../../../redux/learner/actions";

const { Text } = Typography;

const DiscussionCard = ({
  content,
  isActive,
  isOpened,
  currentUser,
  isCreator,
  deleteDiscussion
}: {
  content: DiscussionType;
  isActive?: boolean;
  isOpened?: boolean;
  currentUser?: any;
  isCreator?: boolean;
  deleteDiscussion?: (id: string) => void;
}) => {
  const dispatch = useDispatch();

  const handleResolveDiscussion = () => {
    if (!isOpened || content?.createdBy !== currentUser?.id) return;
    dispatch({
      type: discussionTypes.CHANGE_RESOLVED_STATUS,
      payload: {
        id: content.id,
        isResolved: !content.isResolved,
      },
    });
  };

  return (
    <div className="flex justify-between items-center gap-4 h-full">
      <div
        className={`flex flex-col ${
          !isActive ? "max-w-[calc(100%-145px)]" : "max-w-[calc(100%-32px)]"
        }`}
      >
        <Text
          className={`${
            !isActive ? "text-neutral-500" : "text-white"
          } text-[10px] leading-[14px] font-normal truncate`}
        >
          {content.category.toUpperCase()}
        </Text>
        <Text
          className={`${
            !isActive ? "text-primary-600" : "text-white"
          }  text-base font-semibold truncate`}
        >
          {content.title}
        </Text>
      </div>
      <div className="flex gap-3">
      {isOpened && isCreator && (
           <Tooltip title="Delete discussion">
           <Popconfirm
             title="Delete discussion"
             description="Are you sure to delete this discussion?"
             onConfirm={() => deleteDiscussion && deleteDiscussion(content.id)}
             okText="Yes"
             cancelText="No"
           >
             <Button className="border-none shadow-none bg-transparent h-8">
               <DeleteOutlined className="text-2xl text-rose-400" />
             </Button>
           </Popconfirm>
         </Tooltip>
        )}

        {!isActive && (
          <Text
            onClick={handleResolveDiscussion}
            className={`text-white w-[100px] flex items-center justify-center py-[6px] font-semibold text-sm rounded-lg  ${
              content.isResolved ? "bg-success-550" : "bg-rose-400"
            } ${content?.createdBy === currentUser?.id && "cursor-pointer"}`}
          >
            {content.isResolved ? "Resolved" : "Unresolved"}
          </Text>
        )}
            
        {isActive ? (
          <ArrowRightOutlined className="text-white" />
        ) : isOpened ? (
          <MinusOutlined className="text-primary-400" />
        ) : (
          <PlusOutlined className="text-primary-400" />
        )}
      </div>
    </div>
  );
};

export default DiscussionCard;
