import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils/types";
import {
  adminAddRatingQuestion,
  adminDeleteRating,
  adminEditRating,
  adminGetRating,
} from "../../../../redux/admin/actions";
import {
  selectRatings,
  selectRatingsLoaded,
  selectRatingsLoading,
} from "../../../../redux/admin/selector";

const mapStateToProps = (state: StateType) => ({
  ratings: selectRatings(state),
  loading: selectRatingsLoading(state),
  loaded: selectRatingsLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      adminGetRating,
      adminAddRatingQuestion,
      adminDeleteRating,
      adminEditRating,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
