import { Form, Input, Button, Select, Row, Col, Typography } from "antd";
import { useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;

const SignUp = (props: any) => {
  const {
    departments,
    positions,
    actions: { signUp, getDepartment, getPosition },
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    getDepartment();
    getPosition();
  }, []);

  const onFinishSignUp = ({
    email,
    departmentId,
    positionId,
  }: {
    email: string;
    departmentId: string | null;
    positionId: string | null;
  }) => {
    signUp({
      email,
      departmentIds: departmentId ? [departmentId] : [],
      positionIds: positionId ? [positionId] : [],
      role: "USER",
    });
    form.resetFields();
  };

  const validateDepartmentAndPosition = (_: any, value: any) => {
    const departmentId = form.getFieldValue("departmentId");
    const positionId = form.getFieldValue("positionId");

    if (!departmentId && !positionId) {
      return Promise.reject(
        new Error("At least one department or one position must be selected")
      );
    }

    Object.keys(form.getFieldsValue()).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }

      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
    return Promise.resolve();
  };

  return (
    <Row className="h-full  pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title="Invite a User"
          text="Enter the information of the user you want to invite to the application"
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinishSignUp}
          autoComplete="off"
          size="large"
        >
          <div className="w-full mb-7">
            <Text className="text-neutral-700 text-lg font-semibold">
              User Information
            </Text>
          </div>

          <Text className="text-neutral-600 text-sm font-semibold">
            Email Adress
          </Text>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="mt-2"
          >
            <Input
              type="email"
              autoComplete="off"
              name="email"
              placeholder="Input Email Adress"
            />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Department (Optional)
          </Text>

          <Form.Item
            name="departmentId"
            rules={[
              { required: false },
              { validator: validateDepartmentAndPosition },
            ]}
            className="mt-2"
          >
            <Select
              placeholder="Select Department"
              allowClear
              className="min-h-[50px]"
            >
              {departments.map((dept: any) => (
                <Select.Option key={dept.id} value={dept.id}>
                  {dept.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Position (Optional)
          </Text>

          <Form.Item
            name="positionId"
            rules={[
              { required: false },
              { validator: validateDepartmentAndPosition },
            ]}
            className="mt-2"
          >
            <Select
              placeholder="Select Position"
              allowClear
              className="min-h-[50px]"
            >
              {positions.map((pos: any) => (
                <Select.Option key={pos.id} value={pos.id}>
                  {pos.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div className="w-full flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                className="w-1/2 bg-primary-600 h-[50px]"
              >
                Send Invitation
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SignUp;
