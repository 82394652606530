import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Typography, Checkbox, Empty, Tooltip } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import {
  selectPositions,
  selectPositionsLoaded,
} from "../../redux/educator/selector";
import { positionTypes as types } from "../../redux/educator/actions";
import { ReactComponent as PositionIcon } from "../../assets/images/reusable-items/position.svg";

const { Text } = Typography;

const FilterByPositions = ({
  onSelectionChange,
  selectedPositions,
  setSelectedPositions,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedPositions, setTempSelectedPositions] = useState<string[]>(
    []
  );
  const positions = useSelector(selectPositions);
  const positionsLoaded = useSelector(selectPositionsLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!positionsLoaded && isModalVisible) {
      dispatch({ type: types.GET_POSITION });
    }
  }, [isModalVisible]);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedPositions(tempSelectedPositions);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedPositions);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedPositions(selectedPositions);
  };

  const handleSelect = (pos: any) => {
    setTempSelectedPositions((prevSelected: string[]) =>
      prevSelected.includes(pos)
        ? prevSelected.filter((item) => item !== pos)
        : [...prevSelected, pos]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allDepartmentIds = positions.map((pos: any) => pos.id);
      setTempSelectedPositions(allDepartmentIds);
    } else {
      setTempSelectedPositions([]);
    }
  };

  const openModal = () => {
    setTempSelectedPositions(selectedPositions);
    setIsModalVisible(true);
  };

  return (
    <>
      <Tooltip title="Filter By Positions">
        <Button
          type="text"
          className={`h-fit ${
            selectedPositions.length > 0 && "bg-primary-200"
          }`}
          onClick={openModal}
        >
          <PositionIcon
            className={`w-5 h-5 ${
              selectedPositions.length > 0
                ? "fill-primary-600"
                : "fill-primary-400"
            }`}
          />
        </Button>
      </Tooltip>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        {positions.length > 0 ? (
          <>
            <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
              <div className="flex gap-3 items-center">
                <PositionIcon className="fill-primary-400 w-[20px] h-[20px]" />
                <Text className="text-neutral-400 font-normal text-sm">
                  Position(s)
                </Text>
              </div>
              <Checkbox
                onChange={(e) => handleSelectAll(e.target.checked)}
                checked={tempSelectedPositions?.length === positions?.length}
              >
                <Text className="text-neutral-400 font-normal text-sm">
                  Select All
                </Text>
              </Checkbox>
            </div>
            <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
              {positions &&
                positions?.map((pos: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => handleSelect(pos.id)}
                    className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                      tempSelectedPositions?.includes(pos.id)
                        ? "bg-primary-100"
                        : "bg-neutral-100"
                    } hover:bg-primary-200 transition`}
                  >
                    <Text
                      className={`flex-1 text-neutral-600 text-sm ${
                        tempSelectedPositions?.includes(pos.id)
                          ? "font-semibold"
                          : ""
                      }`}
                    >
                      {pos.name}
                    </Text>
                    {tempSelectedPositions?.includes(pos.id) && (
                      <CheckOutlined className="text-blue-500" />
                    )}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </>
  );
};

export default FilterByPositions;
