import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  getUserById,
  editUser,
  getDepartment,
  getPosition,
} from "../../../../redux/admin/actions";
import {
  selectUser,
  selectDepartments,
  selectPositions,
} from "../../../../redux/admin/selector";

const mapStateToProps = (state: StateType) => ({
  user: selectUser(state),
  departments: selectDepartments(state),
  positions: selectPositions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getUserById,
      getDepartment,
      getPosition,
      editUser,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
