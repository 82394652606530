import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Card, Avatar, Skeleton, Button } from "antd";
import { EyeFilled, UserOutlined } from "@ant-design/icons";

import FilterButtons from "../../shared/FilterButtons";
import { LessonDemand } from "../../../utils";
import EmptyState from "../../shared/EmptyState";
import { ReactComponent as LessonIcon } from "../../../assets/images/reusable-items/lesson.svg";
import { formatTimeAgo } from "../../../utils/helpers";
import { lessonDemandTypes } from "../../../redux/learner/actions";
import { newsFeedType } from "../../../utils/constants/newsFeedTypes";

const { Text } = Typography;

const EducatorLessonDemandScreen = (props: {
  lessonDemands: LessonDemand[];
  lessonDemandsNewsFeed: number | null | undefined;
  loading: boolean;
  loaded: boolean;
  actions: {
    getLessonDemand: () => void;
    getLearnerNewsFeed: () => void;
    deleteNewsFeed: any;
  };
}) => {
  const {
    lessonDemands,
    lessonDemandsNewsFeed,
    loading,
    loaded,
    actions: { getLessonDemand, getLearnerNewsFeed, deleteNewsFeed },
  } = props;

  const dispatch = useDispatch();
  const [filteredLessonDemands, setFilteredLessonDemands] =
    useState(lessonDemands);
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "ALL", value: "ALL" },
    { key: "RESOLVED", value: "RESOLVED" },
    { key: "UNRESOLVED", value: "UNRESOLVED" },
  ];
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);

  useEffect(() => {
    if (loaded) return;
    getLessonDemand();
  }, []);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (lessonDemandsNewsFeed && lessonDemandsNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.lessonDemand });
    }
  }, [lessonDemandsNewsFeed]);

  useEffect(() => {
    let filtered = lessonDemands;

    if (filter === "RESOLVED") {
      filtered = filtered.filter(
        (lessonDemand: LessonDemand) => lessonDemand.isResolved
      );
    } else if (filter === "UNRESOLVED") {
      filtered = filtered.filter(
        (lessonDemand: LessonDemand) => !lessonDemand.isResolved
      );
    }

    setFilteredLessonDemands(filtered);
  }, [filter, lessonDemands]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    setExpandedCardId(null);
  };

  const handleCardExpand = (id: string) => {
    setExpandedCardId(expandedCardId === id ? null : id);
  };

  const handleResolveLessonDemand = (id: string, isResolved: boolean) => {
    dispatch({
      type: lessonDemandTypes.CHANGE_RESOLVED_STATUS_LESSON_DEMAND,
      payload: {
        id,
        isResolved: !isResolved,
      },
    });
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {lessonDemands.length > 0 ? (
        <div className="xl:flex pb-[40px]">
          <div className="xl:w-1/3 md:pb-4 xl:p-0">
            <div className="flex flex-col flex-wrap gap-4 xl:max-w-[270px]">
              <Text className="text-neutral-500 font-semibold text-lg">
                Monitor your Lesson Demands
              </Text>
              <Text className="text-neutral-500 font-normal text-base">
                Here you can see all Lessons Demands and also resolve the
                unresolved.
              </Text>
            </div>
          </div>
          <div className="xl:w-2/3 xl:-mt-4">
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            />
            <div className="flex flex-wrap gap-x-4 gap-y-4 overflow-y-scroll max-h-[calc(100vh-220px)] mt-4">
              {filteredLessonDemands.length > 0 ? (
                filteredLessonDemands.map((lessonDemand) => (
                  <Card
                    key={lessonDemand.id}
                    className={`w-[calc(50%-8px)] p-5 border-none shadow-sm rounded-lg transition-all  ease-in-out ${
                      expandedCardId === lessonDemand.id
                        ? "max-h-[1000px] duration-300"
                        : "max-h-[142px] duration-100"
                    }`}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center gap-2">
                        <div className="flex items-center gap-1">
                          <LessonIcon className="min-w-6 h-6 fill-primary-400" />

                          <Text className="text-neutral-600 font-semibold text-sm leading-4 line-clamp-2 break-words">
                            {lessonDemand.title}
                          </Text>
                        </div>

                        <div className="flex items-center gap-1">
                          {expandedCardId !== lessonDemand.id && (
                            <EyeFilled
                              className="min-w-5 h-5 text-primary-400 cursor-pointer"
                              onClick={() => handleCardExpand(lessonDemand.id)}
                            />
                          )}

                          <Text
                            onClick={() =>
                              handleResolveLessonDemand(
                                lessonDemand?.id,
                                lessonDemand.isResolved
                              )
                            }
                            className={`text-sm font-semibold text-white h-[32px] min-w-[110px] flex items-center justify-center rounded-lg cursor-pointer ${
                              lessonDemand.isResolved
                                ? "bg-success-550"
                                : "bg-rose-400"
                            }`}
                          >
                            {lessonDemand.isResolved
                              ? "Resolved"
                              : "Unresolved"}
                          </Text>
                        </div>
                      </div>
                      {expandedCardId === lessonDemand.id && (
                        <div className="border-b border-primary-100 pt-3"></div>
                      )}

                      <div
                        className={`transition-all duration-300 ease-in-out overflow-hidden ${
                          expandedCardId === lessonDemand.id
                            ? "max-h-[1000px]"
                            : "max-h-0"
                        }`}
                      >
                        {expandedCardId === lessonDemand.id ? (
                          <div className="bg-neutral-100 p-4 mt-4 rounded-lg">
                            <Text className="text-sm text-neutral-600">
                              {lessonDemand.description}
                            </Text>
                          </div>
                        ) : (
                          <div className="max-h-0 transition-all duration-100"></div>
                        )}
                      </div>

                      <div className="bg-neutral-100 rounded-lg px-4 py-2 mt-4 flex justify-between items-center">
                        <div className="flex flex-col">
                          <div className="text-[10px] leading-[14px] text-neutral-600">
                            Created By
                          </div>
                          <div className="flex gap-1 items-center">
                            {lessonDemand.createdUser?.profilePictureKey ? (
                              <Avatar
                                size={20}
                                src={`${process.env.REACT_APP_CLOUDFRONT_KEY}/${lessonDemand.createdUser.profilePictureKey}`}
                                className="min-w-5"
                              />
                            ) : (
                              <Avatar
                                size={20}
                                icon={<UserOutlined />}
                                className="min-w-5"
                              />
                            )}
                            <Text className="text-neutral-600  h-6 overflow-auto">
                              {lessonDemand.createdUser?.name ??
                                lessonDemand.createdUser?.email}
                            </Text>
                          </div>
                        </div>
                        <Text className="text-xs font-medium text-neutral-500 min-w-[90px] text-end">
                          {formatTimeAgo(lessonDemand.createdAt)}
                        </Text>
                      </div>

                      <div
                        className={`transition-all  ease-in-out overflow-hidden ${
                          expandedCardId === lessonDemand.id
                            ? "max-h-[50px] duration-300"
                            : "max-h-0 duration-100"
                        }`}
                      >
                        {expandedCardId === lessonDemand.id && (
                          <Button
                            onClick={() => handleCardExpand(lessonDemand.id)}
                            className="mt-4 h-[32px] w-full bg-primary-200 text-primary-600 font-semibold"
                          >
                            See Less
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="flex items-center w-full justify-center h-full py-[60px]">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorLessonDemandScreen;
