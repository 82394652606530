import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addQuizzieService = async (quizData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.quizzie, quizData));

export const deleteQuizzieByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.quizzieById.replace(":id", id))
  );

export const getQuizzieService = async (query: any) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.quizzie.replace(":query", query))
  );

export const getQuizzieByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.quizzieById.replace(":id", id)));
