import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Card, Input, Avatar, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { LessonDemand } from "../../../../utils";
const { Text } = Typography;

const LearnerLessonDemandDetailsScreen = (props: {
  lessonDemand: LessonDemand;
  loading: boolean;
  loaded: boolean;
  actions: { getLessonDemandById: (params: { id: string }) => void };
}) => {
  const {
    lessonDemand,
    loading,
    loaded,
    actions: { getLessonDemandById },
  } = props;

  const { id } = useParams();

  useEffect(() => {
    id && getLessonDemandById({ id });
  }, [id]);

  if (loading) return <Skeleton active />;

  return (
    <>
      <div className="xl:flex  pb-[60px]">
        <div className="xl:w-1/3 md:pb-4 xl:p-0">
          <div className="flex flex-col flex-wrap gap-4 xl:max-w-[240px]">
            <Text className="text-neutral-500 font-normal text-base">
              Here is Lesson Demand information.
            </Text>
          </div>
        </div>
        <div className="xl:w-2/3">
          <Card className="p-8 shadow-md xl:max-w-[600px] border-none">
            <div className="h-full flex flex-col  gap-6">
              <div>
                <Text className="text-neutral-600 text-sm font-semibold">
                  Title
                </Text>
                <Text className="bg-neutral-100 rounded-lg mt-2 p-4 block text-neutral-600 text-sm font-semibold">
                  {lessonDemand?.title}
                </Text>
              </div>

              <div>
                <Text className="text-neutral-600 text-sm font-semibold">
                  Selected Educator
                </Text>
                <div className="bg-neutral-100 rounded-lg mt-2 px-4 py-3 flex items-center gap-2 text-neutral-600 text-base font-semibold">
                  {lessonDemand?.assignedUser?.profilePictureKey ? (
                    <Avatar
                      size={28}
                      src={
                        process.env.REACT_APP_CLOUDFRONT_KEY +
                        "/" +
                        lessonDemand?.assignedUser?.profilePictureKey
                      }
                    />
                  ) : (
                    <Avatar size={28} icon={<UserOutlined />} />
                  )}

                  <Text className="text-sm font-normal text-neutral-600">
                    {lessonDemand?.assignedUser?.name ??
                      lessonDemand?.assignedUser?.email}
                  </Text>
                </div>
              </div>
              <div>
                <Text className="text-neutral-600 text-sm font-semibold">
                  Description
                </Text>
                <Text className="bg-neutral-100 rounded-lg mt-2 p-4 block text-neutral-600 text-sm font-normal">
                  {lessonDemand?.description}
                </Text>
              </div>

              <div className="bg-neutral-100 rounded-lg px-4 py-3 flex justify-between items-center">
                <Text className="text-neutral-600 text-sm font-normal">
                  Status
                </Text>
                <Text
                  className={`text-sm font-semibold text-white h-[28px] w-[220px] flex items-center justify-center rounded-lg ${
                    lessonDemand?.isResolved ? "bg-success-550" : "bg-rose-400"
                  }`}
                >
                  {lessonDemand?.isResolved ? "Resolved" : "Unresolved"}
                </Text>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default LearnerLessonDemandDetailsScreen;
