import { useState } from "react";
import { Avatar, Button, Input, Typography, Upload, Form } from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import showNotification from "../../../../services/notificationService";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const CreateCompany = (props: any) => {
  const {
    actions: { addCompany },
  } = props;
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [fileList, setFileList] = useState<any>(null);
  const [form] = Form.useForm();

  const handleUploadChange = (info: any) => {
    const file = info.file;
    if (file) {
      setFileList(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const handleCreateCompany = (values: any) => {
    if (!fileList) {
      showNotification("error", "Upload company logo");
      return;
    }
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("logo", fileList);
    addCompany({ company: formData, navigate });
  };
  return (
    <div>
      <div className="flex gap-4 mb-6">
        <Text className="text-neutral-600 font-normal text-lg">
          Create New Company
        </Text>
      </div>
      <Form form={form} layout="vertical" onFinish={handleCreateCompany}>
        <Form.Item
          label="Company Name"
          name="name"
          rules={[
            { required: true, message: "Please input the company name!" },
          ]}
        >
          <Input placeholder="Type Company Name" />
        </Form.Item>
        <Form.Item
          label="Company Logotype"
          name="logo"
          rules={[{ required: true, message: "Please upload company logo!" }]}
        >
          <div className="bg-white rounded-md shadow-md pb-10 flex flex-col justify-center items-center">
            {avatarUrl ? (
              <Avatar size={130} src={avatarUrl} className="my-[16px]" />
            ) : (
              <div className="flex flex-col items-center justify-center pt-10">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium mt-6">
                  Upload a logotype
                </p>
                <p className="text-xs text-neutral-500 font-normal mb-5">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
            <div className="flex gap-4">
              <Upload
                name="logo"
                maxCount={1}
                beforeUpload={() => false}
                accept="image/png, image/jpeg, image/gif"
                onChange={handleUploadChange}
                showUploadList={false}
              >
                <Button type="primary" className="w-[142px]">
                  Browse Files
                </Button>
              </Upload>
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="w-full flex justify-between gap-10">
            <Button
              onClick={() => navigate("/owner/company")}
              className="w-1/2 h-[50px]"
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="w-1/2 h-[50px]">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCompany;
