import { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { selectUsers, selectUsersByRole } from "../../redux/educator/selector";
import { userTypes as types } from "../../redux/educator/actions";

const { Text } = Typography;

const EducatorSelector = ({
  onSelectionChange,
  selectedOptions,
  setSelectedOptions,
  isSingleSelect,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedOptions, setTempSelectedOptions] = useState<string[]>([]);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const options = useSelector(selectUsers);
  const optionsByRole = useSelector(selectUsersByRole);

  const dispatch = useDispatch();

  useEffect(() => {
    isSingleSelect
      ? dispatch({ type: types.GET_USER_BY_ROLE, payload: "educator" })
      : dispatch({ type: types.GET_USER });
  }, []);

  useEffect(() => {
    setSelectOptions(isSingleSelect ? optionsByRole : options);
  }, [options, optionsByRole]);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedOptions(tempSelectedOptions);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedOptions);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedOptions(selectedOptions);
  };

  const handleSelect = (selected: any) => {
    if (isSingleSelect) {
      setTempSelectedOptions([selected]);
    } else {
      setTempSelectedOptions((prevSelected: string[]) =>
        prevSelected.includes(selected)
          ? prevSelected.filter((item) => item !== selected)
          : [...prevSelected, selected]
      );
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allSelectedIds = selectOptions.map((option: any) => option.id);
      setTempSelectedOptions(allSelectedIds);
    } else {
      setTempSelectedOptions([]);
    }
  };

  const openModal = () => {
    setTempSelectedOptions(selectedOptions);
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="w-full bg-neutral-100 pl-4 rounded-lg shadow-md mt-2 mb-6">
        <div className="flex justify-between items-center h-[50px]">
          <div>
            <Text
              className={`text-neutral-400 text-sm font-light ${
                selectedOptions?.length > 0 ? "" : "font-semibold"
              }`}
            >
              {selectedOptions?.length > 0
                ? !isSingleSelect
                  ? `Selected (${selectedOptions?.length})`
                  : "Selected"
                : "Select from a list"}
            </Text>
          </div>
          <Button
            onClick={openModal}
            className="border border-neutral-200 text-neutral-600 text-base font-semibold shadow-none h-full"
          >
            {isSingleSelect
              ? "Select"
              : selectedOptions?.length === 0
              ? "Select"
              : "Add More"}
          </Button>
        </div>
        {selectedOptions?.length > 0 && (
          <div className="flex flex-col pt-4 mt-2 gap-1">
            {selectedOptions?.map((selectedId: string) => {
              const selected = selectOptions.find(
                (el: any) => el.id === selectedId
              );
              return (
                <div
                  key={selectedId}
                  className="flex items-center px-4 py-3 rounded-lg bg-neutral-100"
                >
                  <Text className="text-neutral-600 text-sm">
                    {selected?.name ?? selected?.email}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
          <div className="">
            <Text className="text-neutral-400 font-normal text-sm">
              {isSingleSelect ? "Educator" : "User(s) / Educator(s)"}
            </Text>
          </div>
          {!isSingleSelect && (
            <Checkbox
              onChange={(e) => handleSelectAll(e.target.checked)}
              checked={tempSelectedOptions.length === selectOptions.length}
            >
              <Text className="text-neutral-400 font-normal text-sm">
                Select All
              </Text>
            </Checkbox>
          )}
        </div>
        <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
          {selectOptions &&
            selectOptions.map((selected: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSelect(selected.id)}
                className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                  tempSelectedOptions?.includes(selected.id)
                    ? "bg-primary-100"
                    : "bg-neutral-100"
                } hover:bg-primary-200 transition`}
              >
                <Text
                  className={`flex-1 text-neutral-600 text-sm ${
                    tempSelectedOptions?.includes(selected.id)
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  {selected.name ?? selected.email}
                </Text>
                {tempSelectedOptions?.includes(selected.id) && (
                  <CheckOutlined className="text-blue-500" />
                )}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default EducatorSelector;
