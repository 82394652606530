import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../utils/types";
import {
  deleteNewsFeed,
  getLearnerNewsFeed,
  getLessonDemand,
} from "../../../redux/learner/actions";
import {
  selectLessonDemandLoading,
  selectLessonDemands,
  selectLessonDemandsLoaded,
} from "../../../redux/learner/selector/lessonDemand";
import { selectLearnerLessonDemandsNewsFeed } from "../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  lessonDemands: selectLessonDemands(state),
  lessonDemandsNewsFeed: selectLearnerLessonDemandsNewsFeed(state),
  loaded: selectLessonDemandsLoaded(state),
  loading: selectLessonDemandLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonDemand,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
