import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  adminGetRating,
  adminSetRating,
  adminRatingTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import showNotification from "../../../services/notificationService";
import {
  addRatingQuestionService,
  deleteRatingQuestionByIdService,
  editRatingQuestionService,
  getRatingService,
} from "../../../services/learner/rating";
import { EntityIdPayload } from "../../../utils";

export default function* adminRatingSagas() {
  yield all([
    takeLatest(types.ADMIN_ADD_RATING_QUESTION, handleAddRatingQuestion),
    takeLatest(types.ADMIN_GET_RATING, handleGetRatingQuestions),
    takeLatest(types.ADMIN_DELETE_RATING, handleDeleteRatingQuestion),
    takeLatest(types.ADMIN_EDIT_RATING, handleEditRatingQuestion),
  ]);
}

function* handleAddRatingQuestion(action: any) {
  const { data }: AxiosResponse = yield call(
    addRatingQuestionService,
    action.payload.ratingQuestion
  );
  if (data) {
    showNotification("success", "Rating Question added successfully");
    yield put(adminGetRating());
  }
}

function* handleGetRatingQuestions() {
  const { data }: AxiosResponse = yield call(getRatingService);
  if (data) {
    yield put(adminSetRating({ ratings: data }));
  }
}

function* handleDeleteRatingQuestion(action: EntityIdPayload) {
  const res: AxiosResponse = yield call(
    deleteRatingQuestionByIdService,
    action.payload.id
  );
  if (res.status === 204) {
    showNotification("success", "Rating Question deleted successfully");
    yield put(adminGetRating());
  }
}

function* handleEditRatingQuestion(action: any) {
  const { data }: AxiosResponse = yield call(
    editRatingQuestionService,
    action.payload.id,
    action.payload.question
  );
  if (data) {
    showNotification("success", "Rating Question updated successfully");
    yield put(adminGetRating());
  }
}
