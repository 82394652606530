import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../utils/types";
import { selectDepartments } from "../../../redux/admin/selector";
import {
  getDepartmentUser,
  addDepartment,
  editDepartment,
  deleteDepartment,
} from "../../../redux/admin/actions";
import { setCurrentStep } from "../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  departments: selectDepartments(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getDepartmentUser,
      addDepartment,
      editDepartment,
      deleteDepartment,
      setCurrentStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
