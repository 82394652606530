import { call, all, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  lessonDemandTypes as types,
  getLessonDemand,
  setLessonDemand,
  setLessonDemandById,
  setChangeResolvedStatusLessonDemand,
} from "../actions";
import { EntityIdPayload } from "../../../utils";
import {
  changeResolvedStatusService,
  createLessonDemandService,
  deleteLessonDemandByIdService,
  getLessonDemandByIdService,
  getLessonDemandService,
} from "../../../services/learner/lessonDemand";
import showNotification from "../../../services/notificationService";

export default function* educatorCourseSagas() {
  yield all([
    takeLatest(types.CREATE_LESSON_DEMAND, handleCreateLessonDemand),
    takeLatest(types.GET_LESSON_DEMANDS, handleGetLessonDemands),
    takeLatest(types.GET_LESSON_DEMAND_BY_ID, handleGetLessonDemandById),
    takeLatest(
      types.CHANGE_RESOLVED_STATUS_LESSON_DEMAND,
      handleChangeResolvedStatus
    ),
    takeLatest(types.DELETE_LESSON_DEMAND_BY_ID, handleDeleteLessonDemandById),
  ]);
}

function* handleCreateLessonDemand(action: any) {
  const { data }: AxiosResponse = yield call(
    createLessonDemandService,
    action.payload.lessonDemand
  );
  if (data) {
    action.payload.navigate(`/success/user/lesson-demand`);
    yield put(getLessonDemand());
  }
}

function* handleGetLessonDemands() {
  const { data }: AxiosResponse = yield call(getLessonDemandService);
  if (data) {
    yield put(setLessonDemand({ lessonDemands: data }));
  }
}

function* handleGetLessonDemandById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getLessonDemandByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setLessonDemandById({ lessonDemand: data }));
  }
}

function* handleChangeResolvedStatus(action: any) {
  const { data }: AxiosResponse = yield call(
    changeResolvedStatusService,
    action.payload.id,
    action.payload.isResolved
  );

  if (data) {
    yield put(setChangeResolvedStatusLessonDemand({ id: action.payload.id }));
  }
}

function* handleDeleteLessonDemandById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteLessonDemandByIdService,
    action.payload.id
  );

  if (data) {
    yield put(getLessonDemand());
    showNotification("success", data.message);
  }
}
