import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { addAlert, getAlertById } from "../../../../redux/educator/actions";
import { setInitialSteps } from "../../../../redux/shared/actions";
import { StateType } from "../../../../utils/types";
import { selectAlert } from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  currentAlert: selectAlert(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      addAlert,
      getAlertById,
      setInitialSteps,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
