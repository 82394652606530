/* eslint-disable require-yield */
import { call, all, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { types, loginSuccess, setLoggedUser } from "../actions";
import {
  LoginActionType,
  RegisterActionType,
  ResetPasswordActionType,
  SendResetPasswordActionType,
} from "../../../utils";
import {
  loginService,
  registerService,
  resetPasswordService,
  sendResetPasswordService,
} from "../../../services/auth";
import { getLoggedUserService } from "../../../services/shared";
import StorageService from "../../../services/storageService";
import {
  userRole,
  educatorRole,
  ownerRole,
  adminRole,
} from "../../../utils/constants/userRoles";
import routes from "../../../utils/constants/routes";
import showNotification from "../../../services/notificationService";

export default function* authSagas() {
  yield all([
    takeLatest(types.LOGIN_ACTION, handleLogin),
    takeLatest(types.REGISTER_ACTION, handleRegister),
    takeLatest(types.RESET_PASSWORD_ACTION, handleResetPassword),
    takeLatest(types.SEND_RESET_PASSWORD_EMAIL_ACTION, handleSendResetPassword),
    takeLatest(types.GET_LOGGED_USER, handleGetLoggedUser),
  ]);
}

function* handleLogin(action: LoginActionType) {
  const { data }: AxiosResponse = yield call(loginService, action.payload);
  if (!data?.user) {
    return;
  }

  const user = data.user;
  const accessToken = data.accessToken;
  const refreshToken = data.refreshToken;
  StorageService.setStringItem("accessToken", accessToken);
  StorageService.setStringItem("refreshToken", refreshToken);
  yield put(loginSuccess({ token: accessToken, user }));

  switch (user.role) {
    case userRole:
      action.payload.navigate(routes.userHome);
      break;
    case educatorRole:
      action.payload.navigate(routes.educatorHome);
      break;
    case ownerRole:
      action.payload.navigate(routes.ownerHome);
      break;
    case adminRole:
      action.payload.navigate(routes.adminHome);
      break;
  }
}

function* handleRegister(action: RegisterActionType) {
  const { data }: AxiosResponse = yield call(
    registerService,
    action.payload.user
  );

  if (data) {
    action.payload.navigate(routes.login);
  }
}

function* handleResetPassword(action: ResetPasswordActionType) {
  const { token, email, password, navigate } = action.payload;
  const { data }: AxiosResponse = yield call(resetPasswordService, {
    token,
    email,
    password,
  });
  if (data) {
    navigate(routes.login);
  }
}

function* handleSendResetPassword(action: SendResetPasswordActionType) {
  const { data }: AxiosResponse = yield call(
    sendResetPasswordService,
    action.payload
  );
  if (data) showNotification("success", "Email sent successfully");
}

function* handleGetLoggedUser() {
  const { data }: AxiosResponse = yield call(getLoggedUserService);

  if (data) {
    yield put(setLoggedUser({ user: data }));
  }
}
