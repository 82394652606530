import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getLessonDemandById } from "../../../../redux/learner/actions";
import {
  selectCurrentLessonDemandLoaded,
  selectLessonDemand,
  selectLessonDemandLoading,
} from "../../../../redux/learner/selector/lessonDemand";

const mapStateToProps = (state: StateType) => ({
  lessonDemand: selectLessonDemand(state),
  loaded: selectCurrentLessonDemandLoaded(state),
  loading: selectLessonDemandLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonDemandById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
