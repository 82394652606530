import { useEffect, useState } from "react";
import { Modal, Button, Typography, Checkbox, Empty } from "antd";
import { ReactComponent as PositionIcon } from "../../assets/images/reusable-items/position.svg";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectPositions, selectPositionsLoaded } from "../../redux/educator/selector";
import { positionTypes as types } from "../../redux/educator/actions";
import { selectUser } from "../../redux/auth/selector";
import showNotification from "../../services/notificationService";

const { Text } = Typography;

const PositionSelector = ({
  positions,
  onSelectionChange,
  selectedPositions,
  setSelectedPositions,
  isDetailsState,
  isAssignmentDetailsPage,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedPositions, setTempSelectedPositions] = useState<string[]>(
    []
  );
  const educatorPositions = useSelector(selectPositions);
  const positionsLoaded = useSelector(selectPositionsLoaded);
  const user = useSelector(selectUser);
  const [concatedPositions, setConcatedPositions] = useState<any>([]);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    positions &&
      educatorPositions &&
      setConcatedPositions(
        positions.concat(
          educatorPositions.filter(
            (item2) => !positions.some((item1: any) => item1.id === item2.id)
          )
        )
      );
  }, [positions, educatorPositions]);

  useEffect(() => {
    if (!positionsLoaded) {
      dispatch({ type: types.GET_POSITION });
    }
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedPositions(tempSelectedPositions);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedPositions);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedPositions(selectedPositions);
  };

  const handleSelect = (pos: string) => {
    const educatorPositionIds = educatorPositions.map((pos) => pos.id);

    const hasAccess = educatorPositionIds.some(
      (educatorPositionId) => educatorPositionId === pos
    );
    if (!hasAccess) {
      showNotification(
        "error",
        "Sorry, you do not have access to this position."
      );
      return;
    }

    setTempSelectedPositions((prevSelected: string[]) =>
      prevSelected.includes(pos)
        ? prevSelected.filter((item) => item !== pos)
        : [...prevSelected, pos]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    const allPositionIds = concatedPositions.map((pos: any) => pos.id);
    if (checked) {
      setTempSelectedPositions(allPositionIds);
    } else {
      if (!user.hasAccessToAllPositions) {
        const educatorPositionIds = educatorPositions.map((pos) => pos.id);
        const selected = allPositionIds.filter(
          (posIds: any) => !educatorPositionIds.includes(posIds)
        );
        setTempSelectedPositions(selected);
      } else {
        setTempSelectedPositions([]);
      }
    }
  };

  const openModal = () => {
    setTempSelectedPositions(selectedPositions);
    setIsModalVisible(true);
  };

  const handleExpand = () => {
    if (!isDetailsState) return;
    setExpanded(!expanded);
  };

  return (
    <>
      <div
        onClick={handleExpand}
        className={`w-full px-6 py-4 rounded-lg shadow-md  ${
          !isDetailsState ? " bg-white" : "bg-neutral-100"
        }
        ${isDetailsState && selectedPositions?.length > 0 && "cursor-pointer"}
        `}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <PositionIcon className="fill-primary-400 w-[20px] h-[20px]" />
            <Text
              className={`text-neutral-600 text-sm ${
                selectedPositions?.length > 0 ? "" : "font-semibold"
              }
              ${isDetailsState && "font-semibold"}
              `}
            >
              {isDetailsState
                ? `Positions ${
                    selectedPositions?.length > 0
                      ? `(${selectedPositions?.length})`
                      : ""
                  }`
                : selectedPositions?.length > 0
                ? `Selected Positions (${selectedPositions?.length})`
                : "Position(s)"}
            </Text>
          </div>
          {!isDetailsState && (
            <Button
              onClick={openModal}
              className="border-none text-primary-600 text-base font-semibold shadow-none"
            >
              {selectedPositions?.length === 0 ? "Select" : "Add More"}
            </Button>
          )}
        </div>
        {selectedPositions?.length > 0 && (
          <div
            style={{
              height: isDetailsState
                ? !expanded
                  ? "0px"
                  : `calc(${selectedPositions.length * 49 + 12}px)`
                : "auto",
              maxHeight: !isDetailsState ? "184px" : "auto",
            }}
            className={`flex flex-col gap-1 transition-all duration-500 ${
              isDetailsState && !expanded
                ? "overflow-hidden opacity-0"
                : `overflow-auto opacity-100 border-t border-t-primary-200 pt-4 mt-2`
            }`}
          >
            {selectedPositions?.map((posId: string) => {
              const pos = concatedPositions.find((d: any) => d.id === posId);
              return (
                <div
                  key={posId}
                  className="flex items-center px-4 py-3 rounded-lg bg-neutral-100"
                >
                  <Text className="text-neutral-600 text-sm">{pos?.name}</Text>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        {concatedPositions.length > 0 ? (
          <>
            <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
              <div className="flex gap-3 items-center">
                <PositionIcon className="fill-primary-400 w-[20px] h-[20px]" />
                <Text className="text-neutral-400 font-normal text-sm">
                  Position(s)
                </Text>
              </div>
              <Checkbox
                onChange={(e) => handleSelectAll(e.target.checked)}
                checked={
                  tempSelectedPositions?.length === concatedPositions.length
                }
              >
                <Text className="text-neutral-400 font-normal text-sm">
                  Select All
                </Text>
              </Checkbox>
            </div>
            <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
              {positions &&
                educatorPositions &&
                concatedPositions.map((pos: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => handleSelect(pos.id)}
                    className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                      tempSelectedPositions?.includes(pos.id)
                        ? "bg-primary-100"
                        : "bg-neutral-100"
                    } hover:bg-primary-200 transition`}
                  >
                    <Text
                      className={`flex-1 text-neutral-600 text-sm ${
                        tempSelectedPositions?.includes(pos.id)
                          ? "font-semibold"
                          : ""
                      }`}
                    >
                      {pos.name}
                    </Text>
                    {tempSelectedPositions?.includes(pos.id) && (
                      <CheckOutlined className="text-blue-500" />
                    )}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <Empty className="py-5" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </>
  );
};

export default PositionSelector;
