export const adminPositionTypes = {
  ADMIN_ADD_POSITION: "ADMIN_ADD_POSITION",
  ADMIN_GET_POSITION: "ADMIN_GET_POSITION",
  ADMIN_GET_POSITION_BY_ID: "ADMIN_GET_POSITION_BY_ID",
  ADMIN_DELETE_POSITION: "ADMIN_DELETE_POSITION",
  ADMIN_SET_POSITION: "ADMIN_SET_POSITION",
  ADMIN_SET_POSITION_BY_ID: "ADMIN_SET_POSITION_BY_ID",
  ADMIN_EDIT_POSITION: "ADMIN_EDIT_POSITION",
  ADMIN_GET_POSITION_USER: "ADMIN_GET_POSITION_USER",
  ADMIN_SET_POSITION_USER: "ADMIN_SET_POSITION_USER",
};

export const addPosition = (payload: any) => ({
  type: adminPositionTypes.ADMIN_ADD_POSITION,
  payload,
});

export const getPosition = () => ({
  type: adminPositionTypes.ADMIN_GET_POSITION,
});

export const setPosition = (payload: any) => ({
  type: adminPositionTypes.ADMIN_SET_POSITION,
  payload,
});

export const getPositionById = (id: any) => ({
  type: adminPositionTypes.ADMIN_GET_POSITION_BY_ID,
  payload: id,
});

export const setPositionById = (payload: any) => ({
  type: adminPositionTypes.ADMIN_SET_POSITION_BY_ID,
  payload,
});

export const deletePosition = (payload: any) => ({
  type: adminPositionTypes.ADMIN_DELETE_POSITION,
  payload,
});

export const editPosition = (payload: any) => ({
  type: adminPositionTypes.ADMIN_EDIT_POSITION,
  payload,
});

export const getPositionUser = () => ({
  type: adminPositionTypes.ADMIN_GET_POSITION_USER,
});

export const setPositionUser = (payload: any) => ({
  type: adminPositionTypes.ADMIN_SET_POSITION_USER,
  payload,
});
