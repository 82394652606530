import apiRoutes from '../../utils/constants/apiRoutes';
import ApiService from '../apiService';
import handleApiCall from '../handleApi';

export const addCompanyService = async (companyData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.educatorCompany, companyData));

export const getCompanyService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.educatorCompany));

export const getCompanyByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.educatorCompanyInfo.replace(':id', id)),
  );

export const editCompanyService = async (id: string, companyInfo: any) =>
  handleApiCall(() =>
    ApiService.put(
      apiRoutes.educatorCompanyInfo.replace(':id', id),
      companyInfo,
    ),
  );

export const deleteCompanyByIdService = (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.educatorCompanyInfo.replace(':id', id)),
  );
