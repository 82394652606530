const adminRole = "ADMIN";
const userRole = "USER";
const educatorRole = "EDUCATOR";
const ownerRole = "OWNER";

const userRoles = {
  adminRole,
  userRole,
  educatorRole,
  ownerRole,
};

export { userRoles, userRole, educatorRole, adminRole ,ownerRole};
