import { createSelector } from "@reduxjs/toolkit";

import { EducatorDepartmentStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectDepartmentState = (state: StateType): EducatorDepartmentStateType =>
  state.adminDepartmentReducer;

export const selectDepartments = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.departments
);

export const selectDepartmentsLoaded = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.loaded
);

export const selectDepartment = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.department
);

export const selectDepartmentWithUsers = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.departmentsWithUsers
);

export const selectDepartmentLoading = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.loading
);

export const selectDepartmentLoaded = createSelector(
  selectDepartmentState,
  (state: EducatorDepartmentStateType) => state.loaded
);
