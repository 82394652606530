import { call, all, put, takeLatest, take } from "redux-saga/effects";

import {
  setCompany,
  setCompanyById,
  companyTypes as types,
  getCompany,
  getCompanyById,
  setDeleteAdmin,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  addCompanyService,
  getCompanyByIdService,
  getCompanyService,
  editCompanyService,
  deleteCompanyByIdService,
} from "../../../services/owner/company";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";
import { deleteUserByIdService } from "../../../services/educator/user";

export default function* ownerCompanySagas() {
  yield all([
    takeLatest(types.OWNER_ADD_COMPANY, handleAddCompany),
    takeLatest(types.OWNER_GET_COMPANY, handleGetCompany),
    takeLatest(types.OWNER_GET_COMPANY_BY_ID, handleGetCompanyById),
    takeLatest(types.OWNER_DELETE_COMPANY, handleDeleteCompany),
    takeLatest(types.OWNER_EDIT_COMPANY, handleEditCompany),
    takeLatest(types.OWNER_DELETE_ADMIN, handleDeleteAdmin),
  ]);
}

function* handleAddCompany(action: any) {
  const { data }: AxiosResponse = yield call(
    addCompanyService,
    action.payload.company
  );
  if (data) {
    showNotification("success", "Successfully created company");
    action.payload.navigate("/owner/company");
    yield put(getCompany());
  }
}

function* handleGetCompany() {
  const { data }: AxiosResponse = yield call(getCompanyService);
  if (data) {
    yield put(setCompany({ companies: data }));
  }
}

function* handleGetCompanyById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getCompanyByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setCompanyById({ company: data }));
  }
}

function* handleDeleteCompany(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteCompanyByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", "Company deleted successfully!");
    action.payload.navigate("/owner/company");
    yield put(getCompany());
  }
}

function* handleEditCompany(action: any) {
  const { data }: AxiosResponse = yield call(
    editCompanyService,
    action.payload.id,
    action.payload.companyInfo
  );
  if (data) {
    showNotification("success", "Company edited successfully!");
    yield put(getCompany());
    yield put(getCompanyById({ id: action.payload.id }));
  }
}

function* handleDeleteAdmin(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteUserByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setDeleteAdmin({ deletedUserId: action.payload.id }));
    showNotification("success", "User deleted successfully!");
  }
}
