import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Form,
  Row,
  Col,
  Modal,
} from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useParams, Link } from "react-router-dom";
import logoImage from "../../../assets/images/logo-large.svg";
import { ReactComponent as Camera } from "../../../assets/images/camera.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const Settings = (props: any) => {
  const {
    user,
    actions: { getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    form.setFieldsValue({
      name: user.name || null,
      email: user.email,
      dateOfBirth: user.dateOfBirth ? dayjs(user.dateOfBirth) : null,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        if (!!values.name && !!values.name.trim())
          formData.append("name", values.name.trim());

        formData.append("email", values.email);
        if (values.dateOfBirth) {
          formData.append(
            "dateOfBirth",
            values.dateOfBirth.format("YYYY-MM-DD")
          );
        }
        if (profilePicture) {
          formData.append("profilePicture", profilePicture);
        }
        editUser({ id, user: formData });
        setIsChanged(false);
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row className="bg-neutral-100">
      <Col span={12} className="flex items-center justify-center relative">
        <Link
          to="/"
          className="absolute top-16 left-16 text-neutral-600 font-semibold text-sm"
        >
          <ArrowLeftOutlined className="text-neutral-300 mr-2" /> My Settings
        </Link>
        {user && (
          <div className="flex flex-col w-[350px]">
            <div className="flex justify-center mb-4">
              <div className="relative bg-white p-2 rounded-[100px] shadow-sm">
                <Avatar size={170} icon={<UserOutlined />} src={avatarUrl} />
                <Button
                  icon={<Camera />}
                  className="absolute bottom-4 right-1 text-primary-600 border-none shadow-md p-0 min-w-10 h-10 rounded-3xl"
                  onClick={showModal}
                />
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
              }}
            >
              <Form.Item
                name="name"
                label="Name"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
                rules={[
                  { required: !!user.name, message: "Please input your name!" },
                  {
                    validator: (_, value) => {
                      if (!!value && value.trim() === "") {
                        return Promise.reject(
                          new Error("Name cannot be whitespace!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
                rules={[
                  {
                    required: !!user.dateOfBirth,
                    message: "Please select your date of birth!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                  disabledDate={(current) =>
                    !current || current.isAfter(dayjs())
                  }
                  allowClear={false}
                />
              </Form.Item>
              {/*
              Added for future usage
              <Form.Item
                name="language"
                label="Language"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
              >
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="EN">English</Option>
                  <Option value="BS">Bosnian</Option>
                </Select>
              </Form.Item>
*/}
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          className="max-w-[350px]"
          footer={
            <div className="flex gap-4 ">
              <Button key="cancel" onClick={handleCancel} className="w-1/2">
                Close
              </Button>
              <Upload
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
                showUploadList={false}
                className="w-1/2 "
                accept=".png,.jpg,.jpeg,.gif"
              >
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                  className="w-[142px]"
                >
                  Browse Files
                </Button>
              </Upload>
            </div>
          }
        >
          <div className="flex flex-col items-center justify-cente">
            <UploadIcon />
            <p className="text-neutral-600 text-base font-medium mt-6">
              Upload a cover image
            </p>
            <p className="text-xs text-neutral-500 font-normal mb-5">
              PNG, JPG, GIF up to 5MB
            </p>
          </div>
        </Modal>
      </Col>
      <Col
        span={12}
        className="bg-white h-screen flex justify-center items-center"
      >
        <img src={logoImage} alt="auth-image" />
      </Col>
    </Row>
  );
};

export default Settings;
