import { useEffect, useState } from "react";
import { Card, Button, Typography, Popconfirm, Skeleton, Input } from "antd";

import { useNavigate } from "react-router-dom";
import { QuizProps } from "../../../../utils";
import EmptyState from "../../../shared/EmptyState";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";

const { Text } = Typography;

const ViewQuiz = (props: any) => {
  const {
    quizzes,
    loading,
    loaded,
    actions: { getQuiz, deleteQuiz },
  } = props;

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuizzes, setFilteredQuizzes] = useState(quizzes);

  useEffect(() => {
    setFilteredQuizzes(
      quizzes.filter((quizze: any) =>
        quizze.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, quizzes]);

  useEffect(() => {
    if (loaded) return;
    getQuiz();
  }, []);

  const confirmDeleteQuiz = (id: string) => {
    deleteQuiz({ id });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className="h-full">
      {quizzes && quizzes.length > 0 ? (
        <>
          <div className="flex flex-row gap-9 sticky">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              type="primary"
              className="w-64 h-[50px] text-base leading-[18px] font-semibold"
              onClick={() => navigate("/educator/quiz/create")}
            >
              Create New Quiz
            </Button>
          </div>

          <div className="grid grid-cols-3 gap-4 mt-4 overflow-y-scroll max-h-[calc(100vh-242px)]">
            {filteredQuizzes?.length > 0 &&
              filteredQuizzes.map((quiz: QuizProps) => (
                <Card key={quiz.id} className="max-w-[350px] p-6 shadow-md">
                  <div className="flex flex-col justify-between h-full gap-2">
                    <div className="flex-1">
                      <Text className="text-neutral-600 font-semibold text-base line-clamp-3">
                        {quiz.title}
                      </Text>
                    </div>
                    <div className="flex items-center gap-2 my-3">
                      <div
                        className="h-[30px] w-[30px] bg-primary-600 text-white font-semibold text-sm rounded-3xl text-center leading-[30px]"
                        style={{
                          boxShadow: "inset 0 2px 3px 0 rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {quiz.questions.length}
                      </div>
                      <Text className="text-neutral-600 ">Quiz Questions</Text>
                    </div>
                    <div className="flex gap-2">
                      {quiz.isMandatory ? (
                        <CheckCircleOutlined className="text-success-550 text-3xl leading-8" />
                      ) : (
                        <CloseCircleOutlined className="text-red-500 text-3xl leading-8" />
                      )}
                      {""}
                      <Text className="text-base text-neutral-600 leading-8">
                        Mandatory
                      </Text>{" "}
                    </div>
                    <div className="border-t border-primary-200 pt-4 flex gap-4 mt-3">
                      <Button
                        onClick={() => navigate("/educator/quiz/" + quiz.id)}
                        type="primary"
                        className="w-full"
                      >
                        Edit
                      </Button>
                      <Popconfirm
                        title="Delete quiz"
                        description="Are you sure to delete this quiz?"
                        onConfirm={() => confirmDeleteQuiz(quiz.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" danger={true} className="w-full">
                          Delete
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default ViewQuiz;
