import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Typography, Checkbox, Empty, Tooltip } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import {
  selectDepartments,
  selectDepartmentsLoaded,
} from "../../redux/educator/selector";
import { departmentTypes as types } from "../../redux/educator/actions";
import { ReactComponent as DepartmentIcon } from "../../assets/images/reusable-items/department.svg";

const { Text } = Typography;

const FilterByDepartments = ({
  onSelectionChange,
  selectedDepartments,
  setSelectedDepartments,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedDepartments, setTempSelectedDepartments] = useState<
    string[]
  >([]);
  const departments = useSelector(selectDepartments);
  const departmentsLoaded = useSelector(selectDepartmentsLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentsLoaded && isModalVisible) {
      dispatch({ type: types.GET_DEPARTMENT });
    }
  }, [isModalVisible]);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedDepartments(tempSelectedDepartments);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedDepartments);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedDepartments(selectedDepartments);
  };

  const handleSelect = (dept: any) => {
    setTempSelectedDepartments((prevSelected: string[]) =>
      prevSelected.includes(dept)
        ? prevSelected.filter((item) => item !== dept)
        : [...prevSelected, dept]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allDepartmentIds = departments.map((dept: any) => dept.id);
      setTempSelectedDepartments(allDepartmentIds);
    } else {
      setTempSelectedDepartments([]);
    }
  };

  const openModal = () => {
    setTempSelectedDepartments(selectedDepartments);
    setIsModalVisible(true);
  };

  return (
    <>
      <Tooltip title="Filter By Departments">
        <Button
          type="text"
          className={`h-fit ${
            selectedDepartments.length > 0 && "bg-primary-200"
          }`}
          onClick={openModal}
        >
          <DepartmentIcon
            className={`w-5 h-5 ${
              selectedDepartments.length > 0
                ? "fill-primary-600"
                : "fill-primary-400"
            }`}
          />
        </Button>
      </Tooltip>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        {departments.length > 0 ? (
          <>
            <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
              <div className="flex gap-3 items-center">
                <DepartmentIcon className="fill-primary-400 w-[20px] h-[20px]" />
                <Text className="text-neutral-400 font-normal text-sm">
                  Department(s)
                </Text>
              </div>
              <Checkbox
                onChange={(e) => handleSelectAll(e.target.checked)}
                checked={
                  tempSelectedDepartments?.length === departments?.length
                }
              >
                <Text className="text-neutral-400 font-normal text-sm">
                  Select All
                </Text>
              </Checkbox>
            </div>
            <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
              {departments &&
                departments?.map((dept: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => handleSelect(dept.id)}
                    className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                      tempSelectedDepartments?.includes(dept.id)
                        ? "bg-primary-100"
                        : "bg-neutral-100"
                    } hover:bg-primary-200 transition`}
                  >
                    <Text
                      className={`flex-1 text-neutral-600 text-sm ${
                        tempSelectedDepartments?.includes(dept.id)
                          ? "font-semibold"
                          : ""
                      }`}
                    >
                      {dept.name}
                    </Text>
                    {tempSelectedDepartments?.includes(dept.id) && (
                      <CheckOutlined className="text-blue-500" />
                    )}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </>
  );
};

export default FilterByDepartments;
