export const adminDepartmentTypes = {
  ADMIN_ADD_DEPARTMENT: "ADMIN_ADD_DEPARTMENT",
  ADMIN_GET_DEPARTMENT: "ADMIN_GET_DEPARTMENT",
  ADMIN_GET_DEPARTMENT_BY_ID: "ADMIN_GET_DEPARTMENT_BY_ID",
  ADMIN_DELETE_DEPARTMENT: "ADMIN_DELETE_DEPARTMENT",
  ADMIN_SET_DEPARTMENT: "ADMIN_SET_DEPARTMENT",
  ADMIN_SET_DEPARTMENT_BY_ID: "ADMIN_SET_DEPARTMENT_BY_ID",
  ADMIN_EDIT_DEPARTMENT: "ADMIN_EDIT_DEPARTMENT",
  ADMIN_GET_DEPARTMENT_USER: "ADMIN_GET_DEPARTMENT_USER",
  ADMIN_SET_DEPARTMENT_USER: "ADMIN_SET_DEPARTMENT_USER",
};

export const addDepartment = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_ADD_DEPARTMENT,
  payload,
});

export const getDepartment = () => ({
  type: adminDepartmentTypes.ADMIN_GET_DEPARTMENT,
});

export const setDepartment = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_SET_DEPARTMENT,
  payload,
});

export const getDepartmentById = (id: any) => ({
  type: adminDepartmentTypes.ADMIN_GET_DEPARTMENT_BY_ID,
  payload: id,
});

export const setDepartmentById = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_SET_DEPARTMENT_BY_ID,
  payload,
});

export const deleteDepartment = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_DELETE_DEPARTMENT,
  payload,
});

export const editDepartment = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_EDIT_DEPARTMENT,
  payload,
});

export const getDepartmentUser = () => ({
  type: adminDepartmentTypes.ADMIN_GET_DEPARTMENT_USER,
});

export const setDepartmentUser = (payload: any) => ({
  type: adminDepartmentTypes.ADMIN_SET_DEPARTMENT_USER,
  payload,
});
