import { produce } from "immer";

import { adminDepartmentTypes as types } from "../actions";
import { EducatorDepartmentStateType } from "../../../utils";

const defaultState: EducatorDepartmentStateType = {
  departments: [],
  departmentsWithUsers: [],
  department: null,
  loading: false,
  loaded: false,
};

const handleSetDepartment = (state: any, payload: any) => {
  state.department = payload.department;
  return state;
};

const handleSetDepartments = (state: any, payload: any) => {
  state.departments = payload.departments;
  state.loaded = true;
  state.loading = false;
  return state;
};

const handleSetDepartmentsWithUsers = (state: any, payload: any) => {
  state.departments = payload.departments;
  state.loaded = true;
  state.loading = false;
  return state;
};

export default produce(
  (state: EducatorDepartmentStateType = defaultState, action) => {
    switch (action.type) {
      case types.ADMIN_SET_DEPARTMENT:
        return handleSetDepartments(state, action.payload);
      case types.ADMIN_SET_DEPARTMENT_BY_ID:
        return handleSetDepartment(state, action.payload);
      case types.ADMIN_SET_DEPARTMENT_USER:
        return handleSetDepartmentsWithUsers(state, action.payload);
      case types.ADMIN_GET_DEPARTMENT:
        return {
          ...state,
          loading: true,
        };
      case types.ADMIN_GET_DEPARTMENT_USER:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
