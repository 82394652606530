export const companyTypes = {
  OWNER_ADD_COMPANY: "OWNER_ADD_COMPANY",
  OWNER_GET_COMPANY: "OWNER_GET_COMPANY",
  OWNER_GET_COMPANY_BY_ID: "OWNER_GET_COMPANY_BY_ID",
  OWNER_DELETE_COMPANY: "OWNER_DELETE_COMPANY",
  OWNER_SET_COMPANY: "OWNER_SET_COMPANY",
  OWNER_SET_COMPANY_BY_ID: "OWNER_SET_COMPANY_BY_ID",
  OWNER_EDIT_COMPANY: "OWNER_EDIT_COMPANY",
  OWNER_DELETE_ADMIN: "OWNER_DELETE_ADMIN",
  OWNER_SET_DELETE_ADMIN: "OWNER_SET_DELETE_ADMIN",
};

export const addCompany = (payload: any) => ({
  type: companyTypes.OWNER_ADD_COMPANY,
  payload,
});

export const getCompany = () => ({
  type: companyTypes.OWNER_GET_COMPANY,
});

export const setCompany = (payload: any) => ({
  type: companyTypes.OWNER_SET_COMPANY,
  payload,
});

export const getCompanyById = (id: any) => ({
  type: companyTypes.OWNER_GET_COMPANY_BY_ID,
  payload: id,
});

export const setCompanyById = (payload: any) => ({
  type: companyTypes.OWNER_SET_COMPANY_BY_ID,
  payload,
});

export const deleteCompany = (payload: any) => ({
  type: companyTypes.OWNER_DELETE_COMPANY,
  payload,
});

export const editCompany = (payload: any) => ({
  type: companyTypes.OWNER_EDIT_COMPANY,
  payload,
});

export const deleteAdmin = (id: any) => ({
  type: companyTypes.OWNER_DELETE_ADMIN,
  payload: id,
});

export const setDeleteAdmin = (id: any) => ({
  type: companyTypes.OWNER_SET_DELETE_ADMIN,
  payload: id,
});
