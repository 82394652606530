import { Button, Tooltip, Row, Col, Input, Skeleton } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import ProgressInfoBox from "../components/ProgressInfoBox";
import FilterButtons from "../../../shared/FilterButtons";
import Chart from "../components/Chart";
import TimelineSelection from "../components/TimelineSelection";
import MenuItems from "../../../../assets/images/menu-items";
import ListHeader from "../components/ListHeader";
import CardProgress from "../components/CardProgress";
import PerformanceListItem from "../components/PerformanceUserListItem";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import FilterByDepartments from "../../../shared/FilterByDepartments";
import FilterByPositions from "../../../shared/FilterByPositions";

const EducatorDashboardCoursesScreen = (props: any) => {
  const {
    courses,
    singleCourse,
    benchmarkLoaded,
    benchmark,
    actions: {
      getAnalytics,
      getAnalyticsCourseById,
      getBenchmarkAnalytics,
      resetSingleCourseAnalytics,
      exportExcel,
    },
  } = props;

  const navigate = useNavigate();

  const [filter, setFilter] = useState("ALL");
  const [timeLineValue, setTimeLineValue] = useState();
  const [singleCourseActive, setSingleCourseActive] = useState(false);
  const [coursesChartData, setCoursesChartData] = useState([]);
  const [statistics, setStatistics] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredSingleCourseData, setFilteredSingleCourseData] = useState([]);
  const [singleCourseTitle, setSingleCourseTitle] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

  const filterProps = [
    { key: "All", value: "ALL" },
    { key: "Finished", value: "FINISHED" },
    { key: "In Progress", value: "INPROGRESS" },
  ];

  const timeLineOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  useEffect(() => {
    document.body.classList.add("educator-dashboard-layout");

    return () => {
      document.body.classList.remove("educator-dashboard-layout");
    };
  }, []);

  useEffect(() => {
    getAnalytics({
      type: "course",
    });
  }, []);

  useEffect(() => {
    if (courses.loaded) {
      const completedCourses =
        courses?.data?.filter((course: any) => course.progress === "100")
          ?.length || 0;
      const completedCoursesPercentage = Math.round(
        (completedCourses / courses?.data?.length) * 100
      );
      const inProgressCourses =
        courses?.data?.filter((course: any) => course.progress !== "100")
          ?.length || 0;
      const inProgressCoursesPercentage = Math.round(
        (inProgressCourses / courses?.data?.length) * 100
      );
      setStatistics({
        completedCourses,
        completedCoursesPercentage,
        inProgressCourses,
        inProgressCoursesPercentage,
      });
    }
  }, [courses]);

  useEffect(() => {
    if (singleCourseActive) {
      let filtered = singleCourse?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((user: any) => user.progress === "100");
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((user: any) => user.progress !== "100");
      }

      if (searchTerm) {
        filtered = filtered.filter((user: any) =>
          user.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredSingleCourseData(filtered);
    } else {
      let filtered = courses?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((course: any) => course.progress === "100");
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((course: any) => course.progress !== "100");
      }

      if (searchTerm) {
        filtered = filtered.filter((course: any) =>
          course.courseTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredCourses(filtered);
    }
  }, [searchTerm, filter, courses, singleCourse]);

  useEffect(() => {
    if (!benchmarkLoaded) {
      getBenchmarkAnalytics();
    }
  }, []);

  useEffect(() => {
    if (benchmarkLoaded) {
      coursesWeekData();
    }
  }, [benchmarkLoaded]);

  const handleChangeTimeline = (value: string) => {
    if (value === "week") {
      coursesWeekData();
      return;
    }
    if (value === "month") {
      coursesMonthData();
      return;
    }
    if (value === "year") {
      coursesYearData();
      return;
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const coursesWeekData = () => {
    const weekCoursesData = benchmark?.courses?.slice(0, 7);
    const chartData = getChartDataForWeek(weekCoursesData);
    setCoursesChartData(chartData);
  };

  const coursesMonthData = () => {
    const monthCoursesData = benchmark?.courses?.slice(0, 30);
    const chartData = getChartDataForMonth(monthCoursesData);
    setCoursesChartData(chartData);
  };

  const coursesYearData = () => {
    const yearCoursesData = benchmark?.courses?.slice(0, 365);
    const chartData = getChartDataForYear(yearCoursesData);
    setCoursesChartData(chartData);
  };

  const getChartDataForWeek = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        weekday: "short",
      }),
    }));
  };

  const getChartDataForMonth = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).getDate(),
    }));
  };

  const getChartDataForYear = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        month: "short",
      }),
    }));
  };

  const handleCourseSelection = (course: any) => {
    getAnalyticsCourseById({ id: course.courseId });
    setSingleCourseActive(true);
    setSingleCourseTitle(course?.courseTitle);
  };

  const exportCourseExcelFile = () => {
    exportExcel({
      query: `entity=course&timeFrame=all`,
    });
  };

  const exportCurrentStateExcelFile = () => {
    exportExcel({
      query: `entity=course&timeFrame=today`,
    });
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);

    let query = "";
    for (let i = 0; i < departments.length; i++) {
      query += "departmentIds=" + departments[i] + "&";
    }
    for (let i = 0; i < selectedPositions.length; i++) {
      query += "positionIds=" + selectedPositions[i] + "&";
    }

    getAnalytics({
      type: "course",
      query,
    });
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);

    let query = "";
    for (let i = 0; i < positions.length; i++) {
      query += "positionIds=" + positions[i] + "&";
    }
    for (let i = 0; i < selectedDepartments.length; i++) {
      query += "departmentIds=" + selectedDepartments[i] + "&";
    }

    getAnalytics({
      type: "course",
      query,
    });
  };

  const CoursesList = ({
    courses,
    filteredCourses,
    handleCourseSelection,
  }: any) => {
    if (!courses || courses?.loading) {
      return <Skeleton active />;
    }

    if (courses?.loaded && filteredCourses?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Course className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available courses.
          </div>

          <Button
            onClick={() => navigate("/educator/course/create")}
            className="w-full bg-primary-600 h-10 text-base font-semibold text-white"
          >
            Create New Course
          </Button>
        </div>
      );
    }

    if (courses?.loaded) {
      return filteredCourses?.map((course: any, index: number) => (
        <CardProgress
          key={index}
          classes=""
          title={course.courseTitle}
          progress={course.progress}
          isFinished={course?.progress === "100"}
          footerButton={
            <Button
              className="bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
              onClick={() => handleCourseSelection(course)}
            >
              View Course
            </Button>
          }
          size="small"
        ></CardProgress>
      ));
    }
    return <></>;
  };

  const SingleCourseList = ({
    singleCourse,
    filteredSingleCourseData,
  }: any) => {
    if (!singleCourse || singleCourse?.loading) {
      return <Skeleton active />;
    }

    if (singleCourse?.loaded && filteredSingleCourseData?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Course className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available users.
          </div>
        </div>
      );
    }

    if (singleCourse?.loaded && filteredSingleCourseData?.length > 0) {
      return filteredSingleCourseData?.map((user: any) => (
        <PerformanceListItem
          key={user.userId}
          user={user}
          completedEntities={user.completedLessons}
          totalEntities={user.totalLessons}
        ></PerformanceListItem>
      ));
    }
  };

  const backToAllCourses = () => {
    setSingleCourseActive(false);
    setSingleCourseTitle("");
    resetSingleCourseAnalytics();
  };

  return (
    <Row>
      <Col lg={12} className="pr-[80px]">
        <div>
          <div className="text-primary-600 text-xl font-semibold mb-2">
            Performance
          </div>
          <div className="text-neutral-500 text-sm">
            Monitor the performance of your Courses and filter the data by time
            frame (week, month, year).
          </div>

          <div className="mt-5 flex justify-between gap-4">
            <div className="flex gap-4">
              <TimelineSelection
                selectedOption={timeLineValue}
                onSelectionChange={handleChangeTimeline}
                options={timeLineOptions}
              ></TimelineSelection>
              <Tooltip title="Export">
                <Button
                  onClick={exportCourseExcelFile}
                  icon={<DownloadOutlined />}
                  className="text-primary-500 leading-4 font-bold bg-neutral-100 border-none"
                ></Button>
              </Tooltip>
            </div>
            <Button
              color="primary"
              type="link"
              onClick={() => navigate("/educator/benchmark")}
            >
              All Graphs
            </Button>
          </div>
          <div className="mt-3">
            <Chart
              chartData={coursesChartData}
              xAxisDataKey="dateDay"
              type="performance"
            ></Chart>
          </div>
          <div className="py-7">
            <Row className="h-full" gutter={24}>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.completedCoursesPercentage}
                  heading="Completed Courses"
                  totalNumber={statistics?.completedCourses}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.inProgressCoursesPercentage}
                  heading="In progress"
                  totalNumber={statistics?.inProgressCourses}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col lg={12} className="pl-[80px]">
        {!singleCourseActive && (
          <Tooltip title="Export">
            <Button
              style={{
                position: "absolute",
                top: "-40px",
                right: "0px",
              }}
              onClick={exportCurrentStateExcelFile}
              icon={<DownloadOutlined />}
              className="text-primary-500 leading-4 font-bold bg-transparent border-none"
            ></Button>
          </Tooltip>
        )}
        {singleCourseActive && (
          <div
            onClick={() => backToAllCourses()}
            className="full-overview-selector text-primary-500 font-medium cursor-pointer"
          >
            &lt; Back to full overview
          </div>
        )}
        <div className="bg-primary-100 w-full">
          <div className="bg-white p-5 w-full analytics-selector flex flex-col gap-4 rounded-lg">
            <div>
              <ListHeader
                title={singleCourseActive ? singleCourseTitle : "Courses"}
                Icon={MenuItems.Course}
              />
            </div>
            <div className="analyitcs-searchbox sticky">
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon className="" />}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            ></FilterButtons>
            <div className="flex mt-4">
              <FilterByDepartments
                onSelectionChange={handleDepartmentChange}
                selectedDepartments={selectedDepartments}
                setSelectedDepartments={setSelectedDepartments}
              />
              <FilterByPositions
                onSelectionChange={handlePositionChange}
                selectedPositions={selectedPositions}
                setSelectedPositions={setSelectedPositions}
              />
            </div>
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col gap-3 max-h-[calc(100vh-450px)] overflow-y-auto">
              <>
                {singleCourseActive ? (
                  <SingleCourseList
                    singleCourse={singleCourse}
                    filteredSingleCourseData={filteredSingleCourseData}
                  />
                ) : (
                  <CoursesList
                    courses={courses}
                    filteredCourses={filteredCourses}
                    handleCourseSelection={handleCourseSelection}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EducatorDashboardCoursesScreen;
