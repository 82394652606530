import { Button, Typography, Modal, Row, Col, Empty } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import ItemList from "../../../shared/CompanyStructure/ItemList";
import ItemForm from "../../../shared/CompanyStructure/ItemForm";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;

const RatingsScreen = (props: any) => {
  const {
    ratings,
    loading,
    loaded,
    actions: {
      adminGetRating,
      adminAddRatingQuestion,
      adminDeleteRating,
      adminEditRating,
    },
  } = props;

  const [newQuestion, setNewQuestion] = useState("");
  const [editingQuestion, setEditingQuestion] = useState<any>(null);
  const [editingName, setEditingName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    adminGetRating();
  }, []);

  const handleAddQuestion = async () => {
    if (newQuestion.trim() === "") return;
    if (ratings.length === 3) {
      showNotification("error", "You can only create 3 questions");
      setNewQuestion("");

      return;
    }

    await adminAddRatingQuestion({ ratingQuestion: { question: newQuestion } });

    setNewQuestion("");
  };

  const handleEditQuestion = (department: any) => {
    setEditingQuestion(department);
    setEditingName(department.question);
  };

  const handleSaveEdit = async () => {
    if (editingName.trim() === "") return;

    await adminEditRating({
      id: editingQuestion.id,
      question: { question: editingName },
    });

    setEditingQuestion(null);
    setEditingName("");
  };

  const handleDeleteQuestion = async (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this rating question?",
      onOk: async () => {
        await adminDeleteRating({ id });
      },
    });
  };

  return (
    <Row className="h-full  pb-10">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text="Add up to 3 questions that will be displayed to the user when they complete each lesson."
        />
      </Col>
      <Col lg={16} className="gap-4 h-full flex flex-col max-w-[600px]">
        <div>
          <div className="w-full mb-2">
            <Text className="text-neutral-600 font-semibold text-sm">
              Rating Question(s)
            </Text>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col gap-4">
            {ratings && ratings.length > 0 ? (
              <>
                <Text className="text-neutral-600 font-normal text-sm">
                  Created Question(s)
                </Text>
                <ItemList
                  itemType="Question"
                  items={ratings}
                  editingItem={editingQuestion}
                  setEditingItem={setEditingQuestion}
                  editingName={editingName}
                  onEditItem={handleEditQuestion}
                  onSaveItem={handleSaveEdit}
                  onDeleteItem={handleDeleteQuestion}
                  onChangeName={(e: any) => setEditingName(e.target.value)}
                />
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            <ItemForm
              itemType="Question"
              newItem={newQuestion}
              onChangeNewItem={(e: any) => setNewQuestion(e.target.value)}
              onAddItem={handleAddQuestion}
            />
          </div>
        </div>
        <div className="flex w-full gap-10">
          <Button
            className="w-1/2 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
            onClick={() => navigate("/admin/company")}
          >
            Save & Exit
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default RatingsScreen;
