import { Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';

import OwnerCompanyScreen from '../../modules/owner/Company/View';
import WildCardNavigation from '../../modules/shared/WildCardNavigation';
import OwnerSidebar from '../../modules/owner/Sidebar/Sidebar';
import OwnerHeader from '../../modules/owner/Header/Header';
import CreateCompany from '../../modules/owner/Company/Create';
import CompanyDetails from '../../modules/owner/Company/Details';

const { Content } = Layout;

const OwnerLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh', padding: '0' }}>
      <OwnerSidebar></OwnerSidebar>

      <Layout>
        <OwnerHeader></OwnerHeader>

        <Content className="educator-container">
          <Routes>
            <Route path="company" element={<OwnerCompanyScreen />} />
            <Route path="company/create" element={<CreateCompany />} />
            <Route path="company/:id" element={<CompanyDetails />} />
            <Route path="*" element={<WildCardNavigation />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default OwnerLayout;
