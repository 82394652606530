import { Avatar, Typography } from "antd";
import {
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const PerformanceListItem = ({
  question,
  handleSingleLessonOnClick
}: any) => {

  const handleClick = () => {
    if(question.userRatings.length > 0) 
    handleSingleLessonOnClick(question);
  };

  return (
    <div className={`flex gap-3 pr-2 ${question.userRatings.length>0 ? 'cursor-pointer' : ''}`} onClick={handleClick}>
      <div className="w-full h-12 bg-neutral-100 rounded-lg flex items-center px-4 gap-2">
        <div className="flex items-center gap-2">
          <Avatar
            className="text-sm"
            style={{
              fontSize: "0.85rem",
              height: 26,
              width: 26,
            }}
            icon={<QuestionCircleOutlined />}
          />
          <Text className="text-neutral-600 font-semibold text-sm leading-4 ml-3">
            {question.questionText}
          </Text>
        </div>
      </div>
      
        <div
          className="flex bg-primary-200 rounded-lg px-4 items-center text-primary-700"
        >
          {question.userRatings.length >0 ? question.averageRating: '-'}
        </div>
     
    </div>
  );
};

export default PerformanceListItem;
