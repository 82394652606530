import { call, all, put, takeLatest } from "redux-saga/effects";

import { learnerSetRating, learnerRatingTypes as types } from "../actions";
import { AxiosResponse } from "axios";
import showNotification from "../../../services/notificationService";
import {
  getRatingService,
  rateLessonService,
} from "../../../services/learner/rating";

export default function* learnerRatingSagas() {
  yield all([
    takeLatest(types.LEARNER_RATE_LESSON, handleRateLesson),
    takeLatest(types.LEARNER_GET_RATING, handleGetRatingQuestions),
  ]);
}

function* handleRateLesson(action: any) {
  const { data }: AxiosResponse = yield call(
    rateLessonService,
    action.payload.id,
    action.payload.rating
  );
  if (data) {
    showNotification("success", "Lesson rated successfully");
  }
}

function* handleGetRatingQuestions() {
  const { data }: AxiosResponse = yield call(getRatingService);
  if (data) {
    yield put(learnerSetRating({ ratings: data }));
  }
}
