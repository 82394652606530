import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { StateType } from '../../../../utils/types';
import { addCompany } from '../../../../redux/owner/actions';

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      addCompany,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
