import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Form,
  Input,
  Typography,
  Upload,
} from "antd";

import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";

const { Text } = Typography;
const { TextArea } = Input;

const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const CompanyInfoEdit = ({
  companyInfo,
  setIsEditing,
  editCompanyInfo,
}: {
  companyInfo: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editCompanyInfo: (payload: any) => {
    type: string;
    payload: any;
  };
}) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [newCompanyInfoName, setnewCompanyInfoName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [fileList, setFileList] = useState<any>(null);
  const [notificationText, setNotificationText] = useState("");
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(
    companyInfo?.isNotificationEnabled
  );
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    companyInfo && setAvatarUrl(`${cloudfrontKey}/${companyInfo?.imageKey}`);
    setnewCompanyInfoName(companyInfo?.name);
    setNotificationText(
      companyInfo?.notificationText && companyInfo?.notificationText !== "null"
        ? companyInfo?.notificationText
        : undefined
    );

    form.setFieldsValue({
      name: companyInfo?.name,
      logo: companyInfo?.imageKey,
      notification:
        companyInfo?.notificationText &&
        companyInfo?.notificationText !== "null"
          ? companyInfo?.notificationText
          : undefined,
    });
  }, []);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        if (
          companyInfo?.name === newCompanyInfoName &&
          companyInfo?.notificationText === notificationText &&
          companyInfo?.isNotificationEnabled === isNotificationEnabled
        ) {
          throw Error;
        }

        setSubmittable(true);
      })
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleUploadChange = (info: any) => {
    const file = info.file;
    if (file) {
      setFileList(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const onFinish = () => {
    if (isNotificationEnabled && !notificationText) {
      return form.setFields([
        {
          name: "notification",
          errors: ["Please type notification"],
        },
      ]);
    }

    const formData = new FormData();
    formData.append("name", newCompanyInfoName);
    if (fileList) {
      formData.append("logo", fileList);
    }
    formData.append(
      "notificationText",
      notificationText ? notificationText : (null as any)
    );
    formData.append("isNotificationEnabled", isNotificationEnabled);

    editCompanyInfo({ id: companyInfo?.id, companyInfo: formData });
    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex gap-4 mb-6">
        <CompanyStructureItems.Step1 />
        <Text className="text-neutral-600 font-normal text-lg">
          Company Info
        </Text>
      </div>

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        size="large"
      >
        <Text className="text-neutral-600 font-semibold text-sm">
          Company Name
        </Text>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please type company name!" }]}
        >
          <Input
            value={newCompanyInfoName}
            placeholder="Type Company Name"
            onChange={(e) => setnewCompanyInfoName(e.target.value)}
            className="flex-1 rounded-lg my-2"
          />
        </Form.Item>
        <Text className="text-neutral-600 font-semibold text-sm">
          Company Logotype
        </Text>
        <Form.Item
          name="logo"
          rules={[{ required: true, message: "Please select company logo!" }]}
        >
          <div className="bg-white rounded-md shadow-md pb-10 my-2 flex flex-col justify-center items-center">
            {avatarUrl ? (
              <Avatar size={130} src={avatarUrl} className="my-[16px]" />
            ) : (
              <div className="flex flex-col items-center justify-center pt-10">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium mt-6">
                  Upload a logotype
                </p>
                <p className="text-xs text-neutral-500 font-normal mb-5">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}

            <div className="flex gap-4">
              <Upload
                name="logo"
                maxCount={1}
                beforeUpload={() => false}
                accept="image/png, image/jpeg, image/gif"
                onChange={handleUploadChange}
                showUploadList={false}
              >
                <Button type="primary" className="w-[142px]">
                  Browse Files
                </Button>
              </Upload>
            </div>
          </div>
        </Form.Item>
        <Text className="text-neutral-600 font-semibold text-sm">
          Notification for passive users
        </Text>
        <Form.Item
          name="notification"
          rules={[
            {
              required: isNotificationEnabled,
              message: "Please type notification!",
            },
          ]}
        >
          <div className="flex gap-6 my-2">
            <TextArea
              disabled={!isNotificationEnabled}
              value={notificationText}
              placeholder="Type Notification"
              onChange={(e) => setNotificationText(e.target.value)}
              autoSize={{ minRows: 5, maxRows: 10 }}
              className="rounded-lg disabled:bg-white"
            />
            <div className="flex items-center justify-center">
              <Checkbox
                checked={isNotificationEnabled}
                onChange={(e) => {
                  setIsNotificationEnabled(e.target.checked);
                }}
              >
                Enable
              </Checkbox>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="flex w-full gap-10 mt-4">
            <Button
              className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>

            <Button
              htmlType="submit"
              disabled={!submittable}
              className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[50px]"
            >
              Save & Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompanyInfoEdit;
