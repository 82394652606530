import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../utils/types";
import { setCurrentStep } from "../../../redux/shared/actions";
import { selectCompanyInfo } from "../../../redux/admin/selector";
import {
  adminEditCompanyInfo,
  adminGetCompanyInfoById,
} from "../../../redux/admin/actions";

const mapStateToProps = (state: StateType) => ({
  companyInfo: selectCompanyInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      adminGetCompanyInfoById,
      adminEditCompanyInfo,
      setCurrentStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
