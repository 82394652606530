import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, Avatar, Tooltip, Typography } from "antd";
import MenuItems from "../../../assets/images/menu-items";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../redux/auth/selector";
import { getLoggedUser } from "../../../redux/auth/actions";
import "../../../assets/styles/sidebar.scss";
import { userTypes } from "../../../redux/learner/actions";
import { selectCompanyInfo } from "../../../redux/admin/selector";
import { adminGetCompanyInfoById } from "../../../redux/admin/actions";

const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;
const { Sider } = Layout;
const { Text } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const pathToKeyMap: Record<string, string> = {
  "/admin/company": "company",
  "/admin/department": "departments",
  "/admin/position": "positions",
  "/admin/user": "users",
  "/admin/sign-up": "user",
  "/admin/rating": "ratings",
};

const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const companyInfo = useSelector(selectCompanyInfo);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [siderWidth, setSiderWidth] = useState(360);
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const items = [
    getItem(
      "Company Structure",
      "companyStructureSubMenu",
      <MenuItems.CompanyStructure />,
      [
        getItem(
          "Company",
          "company",
          <MenuItems.CompanyInfo />,
          undefined,
          () => navigate("/admin/company")
        ),
        getItem(
          "Departments",
          "departments",
          <MenuItems.Department />,
          undefined,
          () => navigate("/admin/department")
        ),
        getItem(
          "Positions",
          "positions",
          <MenuItems.Position />,
          undefined,
          () => navigate("/admin/position")
        ),
        getItem("Users", "users", <MenuItems.Users />, undefined, () =>
          navigate("/admin/user")
        ),
        getItem("Add user", "user", <MenuItems.User />, undefined, () =>
          navigate("/admin/sign-up")
        ),
        getItem("Ratings", "ratings", <MenuItems.Rating />, undefined, () =>
          navigate("/admin/rating")
        ),
      ]
    ),

    getItem(
      "Logout",
      "logout",
      <LogoutOutlined style={{ fontSize: 18 }} />,
      undefined,
      () => dispatch({ type: userTypes.LOGOUT, payload: { navigate } })
    ),
  ];

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    user?.companyId &&
      dispatch(adminGetCompanyInfoById({ id: user?.companyId }));
  }, [user]);

  useEffect(() => {
    setSelectedKey(location.pathname);

    const key = pathToKeyMap[location.pathname];
    if (
      [
        "company",
        "departments",
        "positions",
        "users",
        "user",
        "ratings",
      ].includes(key)
    ) {
      setOpenKeys(["companyStructureSubMenu"]);
    }
  }, [location.pathname, selectedKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1024) {
        setSiderWidth(200);
        return;
      }
      if (screenWidth < 1800) {
        setSiderWidth(250);
        return;
      }
    };

    window.addEventListener("resize", handleResize);

    // Set the initial width
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
      theme="light"
      width={siderWidth}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="h-20 w-full bg-primary-850">
          <div
            className="px-5 py-6 flex justify-items-center items-center h-full gap-3 cursor-pointer"
            onClick={() => navigate("/admin/company")}
          >
            {!companyInfo?.imageKey ? (
              <Avatar size={48} icon={<UserOutlined />} />
            ) : (
              <Avatar
                size={48}
                src={`${cloudfrontKey}/${companyInfo?.imageKey}`}
              />
            )}
            <div className="text-white">{companyInfo?.name}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-7 mt-7">
        <div className="px-5 flex justify-items-center items-center h-full  gap-3">
          <Tooltip title="Profile settings">
            <Avatar
              size={48}
              src={
                user?.profilePictureKey &&
                process.env.REACT_APP_CLOUDFRONT_KEY +
                  "/" +
                  user?.profilePictureKey
              }
              icon={<UserOutlined />}
              className="cursor-pointer min-w-12"
              onClick={() => navigate("/settings/" + user?.id)}
            />
          </Tooltip>
          <div>
            <Text>{user?.name ?? user?.email}</Text>
            <div className="text-primary-600">Admin</div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          items={items}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[pathToKeyMap[location.pathname]]}
        />
      </div>
    </Sider>
  );
};

export default AdminSidebar;
