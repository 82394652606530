import { Form, Input, Button, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import type { RegisterProps } from "../../../utils/types";

const { Text } = Typography;

const Register = (props: any) => {
  const {
    actions: { register },
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [departmentIds, setDepartmentIds] = useState<string[]>([]);
  const [positionIds, setPositionIds] = useState<string[]>([]);
  const [role, setRole] = useState("");
  const [companyId, setCompanyId] = useState<string>("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setToken(queryParams.get("token") || "");
    setEmail(queryParams.get("email") || "");
    setDepartmentIds(queryParams.getAll("departmentIds"));
    setPositionIds(queryParams.getAll("positionIds"));
    setRole(queryParams.get("role") || "");
    setCompanyId(queryParams.get("companyId") || "");
  }, [location.search]);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email, form]);

  const onFinishRegister = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (!password) {
      return Promise.resolve();
    }

    const groups = [
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /\d/.test(password),
      /[^A-Za-z0-9]/.test(password),
    ];
    const validGroups = groups.filter(Boolean).length;

    if (validGroups >= 3) {
      if (password.length < 8) {
        return form.setFields([
          {
            name: "password",
            errors: ["Password must have at least 8 characters."],
          },
        ]);
      }
      return register({
        user: {
          email,
          password,
          departmentIds: departmentIds,
          positionIds: positionIds,
          token: token,
          role: role,
          companyId,
        },
        navigate,
      });
    }

    form.setFields([
      {
        name: "password",
        errors: [
          "Password must have characters from at least 3 of 4 groups: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), special characters.",
        ],
      },
    ]);
  };

  return (
    <div className="w-[351px]">
      <div className="flex flex-col gap-3 mb-[50px]">
        <Text className="text-neutral-600 text-xl font-medium">
          Forgot your password?
        </Text>
        <Text className="text-neutral-500 text-sm font-normal">
          Please create a password to complete your account setup and start
          using our services.
        </Text>
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinishRegister}
        autoComplete="off"
        size="large"
        initialValues={{ email }}
      >
        <Text className="text-neutral-600 text-sm font-semibold">
          Email address
        </Text>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
          className="mt-2"
        >
          <Input
            type="email"
            autoComplete="off"
            name="email"
            placeholder="Input your Email Adress"
            value={email}
            readOnly
          />
        </Form.Item>
        <Text className="text-neutral-600 text-sm font-semibold">Password</Text>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
          className="mt-2"
        >
          <Input.Password
            type="password"
            autoComplete="off"
            name="Password"
            placeholder="Input your password"
          />
        </Form.Item>
        <Text className="text-neutral-600 text-sm font-semibold">Confirm</Text>
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match!"));
              },
            }),
          ]}
          className="mt-2"
        >
          <Input.Password
            type="password"
            autoComplete="off"
            name="confirm"
            placeholder="Confirm your Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-primary-600 h-[50px] text-base leading-[18px] font-semibold"
          >
            Sign up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;
