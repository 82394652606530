import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Row,
  Col,
  Form,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const EditUserScreen = (props: any) => {
  const {
    user,
    departments,
    positions,
    actions: { getDepartment, getPosition, getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [
    isUserDepartmentIncludedInDepartmentsArray,
    setIsUserDepartmentIncludedInDepartmentsArray,
  ] = useState(false);
  const [
    isUserPositionIncludedInPositionsArray,
    setIsUserPositionIncludedInPositionsArray,
  ] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getDepartment();
    getPosition();
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;

    user?.assignments?.departments.length > 0 &&
      setIsUserDepartmentIncludedInDepartmentsArray(
        departments?.some((dep: any) =>
          dep?.id?.includes(user?.assignments?.departments?.[0]?.id)
        )
      );

    user?.assignments?.positions.length > 0 &&
      setIsUserPositionIncludedInPositionsArray(
        positions?.some((pos: any) =>
          pos?.id?.includes(user?.assignments?.positions?.[0]?.id)
        )
      );
  }, [user, positions, departments]);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    form.setFieldsValue({
      name: user.name || null,
      email: user.email,
      dateOfBirth: user.dateOfBirth ? dayjs(user.dateOfBirth) : null,
      department: user.assignments?.departments[0]?.id,
      position: user.assignments?.positions[0]?.id,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        if (!!values.name && !!values.name.trim())
          formData.append("name", values.name.trim());
        formData.append("email", values.email);
        if (values.dateOfBirth)
          formData.append(
            "dateOfBirth",
            values.dateOfBirth.format("YYYY-MM-DD")
          );
        formData.append(
          "departmentIds",
          values.department !== undefined ? values.department : null
        );

        formData.append(
          "positionIds",
          values.position !== undefined ? values.position : null
        );

        if (profilePicture) {
          formData.append("profilePicture", profilePicture);
        }
        editUser({ id, user: formData });
        setIsChanged(false);
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const validateDepartmentAndPosition = (_: any, value: any) => {
    const departmentId = form.getFieldValue("department");
    const positionId = form.getFieldValue("position");

    if (!departmentId && !positionId) {
      return Promise.reject(
        new Error("At least one department or one position must be selected")
      );
    }

    Object.keys(form.getFieldsValue()).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }

      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
    return Promise.resolve();
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={
            <Link
              to="/educator/user"
              className="text-neutral-600 font-semibold text-sm"
            >
              <ArrowLeftOutlined className="text-neutral-300 mr-2" /> Back to
              users
            </Link>
          }
        />
      </Col>
      <Col lg={16} className="h-full flex flex-col max-w-[600px]">
        {user && (
          <>
            <div className="flex justify-center mb-4">
              <div className="relative">
                <Avatar size={150} icon={<UserOutlined />} src={avatarUrl} />
                <Upload
                  className="absolute bottom-0 right-0"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadChange}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  <Button
                    shape="circle"
                    icon={<CameraOutlined />}
                    className="bg-blue-500 text-white"
                  />
                </Upload>
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
                department: user.assignments?.departments[0]?.id,
                position: user.assignments?.positions[0]?.id,
              }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: !!user.name, message: "Please input user name!" },
                  {
                    validator: (_, value) => {
                      if (!!value && value.trim() === "") {
                        return Promise.reject(
                          new Error("Name cannot be whitespace!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[
                  {
                    required: !!user.dateOfBirth,
                    message: "Please select your date of birth!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                  disabledDate={(current) =>
                    !current || current.isAfter(dayjs())
                  }
                  allowClear={false}
                />
              </Form.Item>
              {/*
              <Form.Item name="language" label="Language">
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="English">English</Option>
                  <Option value="Bosnian">Bosnian</Option>
                </Select>
              </Form.Item>
*/}
              <Form.Item
                name="department"
                label="Department"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
              >
                <Select
                  size="large"
                  className="w-full min-h-[50px]"
                  allowClear
                  placeholder="Select Department"
                >
                  {departments?.length > 0 &&
                    (isUserDepartmentIncludedInDepartmentsArray
                      ? departments?.map((dept: any) => (
                          <Option key={dept?.id} value={dept?.id}>
                            {dept?.name}
                          </Option>
                        ))
                      : departments
                          .concat(user?.assignments?.departments?.[0])
                          .map(
                            (dept: any) =>
                              dept?.id && (
                                <Option key={dept?.id} value={dept?.id}>
                                  {dept?.name}
                                </Option>
                              )
                          ))}

                  {departments?.length === 0 && (
                    <Option
                      key={user?.assignments?.departments?.[0]?.id}
                      value={user?.assignments?.departments?.[0]?.id}
                    >
                      {user?.assignments?.departments?.[0]?.name}
                    </Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                name="position"
                label="Position"
                rules={[
                  { required: false },
                  { validator: validateDepartmentAndPosition },
                ]}
              >
                <Select
                  size="large"
                  className="w-full min-h-[50px]"
                  placeholder="Select Position"
                  allowClear
                >
                  {positions?.length > 0 &&
                    (isUserPositionIncludedInPositionsArray
                      ? positions?.map((pos: any) => (
                          <Option key={pos?.id} value={pos?.id}>
                            {pos?.name}
                          </Option>
                        ))
                      : positions.concat(user?.assignments?.positions?.[0]).map(
                          (pos: any) =>
                            pos?.id && (
                              <Option key={pos?.id} value={pos?.id}>
                                {pos?.name}
                              </Option>
                            )
                        ))}

                  {positions?.length === 0 &&
                    user?.assignments?.positions?.[0]?.id && (
                      <Option
                        key={user?.assignments?.positions?.[0]?.id}
                        value={user?.assignments?.positions?.[0]?.id}
                      >
                        {user?.assignments?.positions?.[0]?.name}
                      </Option>
                    )}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditUserScreen;
