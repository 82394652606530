import { useEffect, useState } from "react";
import { Avatar, Card, Image, Input, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { io, Socket } from "socket.io-client";

import DiscussionCard from "./DisucussionCard";
import { formatTimeAgo } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selector";
import { discussionTypes } from "../../../redux/learner/actions/discussion";
import DiscussionComments from "./DiscussionComments";
import CreateComment from "./CreateComment";
import { DiscussionCommentType, DiscussionType } from "../../../utils";
import { selectComments } from "../../../redux/learner/selector/discussion";
import showNotification from "../../../services/notificationService";

const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;
const { Text } = Typography;

const DiscussionQuestionCard = ({
  activeCard,
  isCreator,
  deleteDiscussion
}: {
  activeCard: DiscussionType;
  isCreator?: boolean;
  deleteDiscussion?: (id: string) => void;
}) => {
  const discussionId = activeCard.id;
  const [showAddReplyInput, setShowAddReplyInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const user = useSelector(selectUser);
  const comments = useSelector(selectComments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const token = localStorage.getItem("accessToken");
    const newSocket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSocket(newSocket);

    newSocket.on("connect", () => {
      // Join the discussion room
      newSocket.emit("joinDiscussion", discussionId);
    });

    newSocket.on("discussion:comment", (comment) => {
      dispatch({
        type: discussionTypes.UPDATE_COMMENTS,
        payload: {
          comment: comment,
        },
      });
    });

    newSocket.on("discussion:commentUpdated", (comment) => {
      dispatch({
        type: discussionTypes.SET_EDITED_COMMENT,
        payload: {
          comment: comment,
        },
      });
    });

    newSocket.on("discussion:commentDeleted", (comment) => {
      dispatch({
        type: discussionTypes.SET_DELETED_COMMENT,
        payload: {
          comment,
        },
      });
    });

    newSocket.on("error", (error) => {
      console.error("Socket error:", error);
      showNotification("error", error.message);
    });

    return () => {
      newSocket.emit("leaveDiscussion", discussionId); // Leave the room before disconnecting
      newSocket.disconnect();
    };
  }, [discussionId, user]);

  useEffect(() => {
    dispatch({
      type: discussionTypes.SET_COMMENTS,
      payload: {
        comments: activeCard?.discussionComments,
      },
    });
  }, [discussionId]);

  useEffect(() => {
    setShowAddReplyInput(false);
  }, [activeCard]);

  const handleCommentSubmit = async () => {
    dispatch({
      type: discussionTypes.CREATE_COMMENT,
      payload: {
        id: discussionId,
        comment: newComment,
      },
    });
    setNewComment("");
    setShowAddReplyInput(false);
  };

  return (
    <Card className={`p-6 duration-200 shadow-md mt-14 border-none`}>
      <DiscussionCard content={activeCard} isOpened={true} currentUser={user} isCreator={isCreator} deleteDiscussion={deleteDiscussion}/>
      <Card className={`mt-6 border-none bg-neutral-100 `}>
        <div className="flex flex-col gap-6 p-6">
          <div className="flex gap-2">
            <div>
              {!activeCard?.user?.profilePictureKey ? (
                <Avatar size={40} icon={<UserOutlined />} />
              ) : (
                <Avatar
                  size={40}
                  src={`${cloudfrontKey}/${activeCard?.user?.profilePictureKey}`}
                />
              )}
            </div>
            <div className="flex flex-col justify-between">
              <Text className="text-base leading-[18px] font-semibold text-neutral-600 break-words">
                {activeCard?.user?.name ?? activeCard?.user?.email}
              </Text>
              <div className="text-sm leading-4 text-neutral-500">
                {formatTimeAgo(activeCard?.createdAt)}
              </div>
            </div>
          </div>

          <div className="text-base text-neutral-600 font-semibold break-words">
            {activeCard?.question}
          </div>
        </div>
      </Card>
      {comments?.length > 0 && (
        <div className="border-b border-primary-300 my-6"></div>
      )}
      <div className="text-primary-600  my-6">
        {comments?.length > 0
          ? `All Comments (${comments?.length})`
          : "No comments yet. Be the first to comment!"}
      </div>

      <DiscussionComments
        comments={comments}
        currentUserId={user?.id}
        isAssignment={false}
      />

      <CreateComment
        showAddReplyInput={showAddReplyInput}
        setShowAddReplyInput={setShowAddReplyInput}
        newComment={newComment}
        setNewComment={setNewComment}
        handleCommentSubmit={handleCommentSubmit}
        isAssignment={false}
      />
    </Card>
  );
};
export default DiscussionQuestionCard;
