import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setDepartment,
  setDepartmentById,
  setDepartmentUser,
  adminDepartmentTypes as types,
  getDepartmentUser,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  addDepartmentService,
  getDepartmentService,
  getDepartmentUserService,
  getDepartmentByIdService,
  deleteDepartmentByIdService,
  editDepartmentService,
} from "../../../services/educator/department";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* adminDepartmentSagas() {
  yield all([
    takeLatest(types.ADMIN_ADD_DEPARTMENT, handleAddDepartment),
    takeLatest(types.ADMIN_GET_DEPARTMENT, handleGetDepartment),
    takeLatest(types.ADMIN_GET_DEPARTMENT_BY_ID, handleGetDepartmentById),
    takeLatest(types.ADMIN_DELETE_DEPARTMENT, handleDeleteDepartment),
    takeLatest(types.ADMIN_EDIT_DEPARTMENT, handleEditDepartment),
    takeLatest(types.ADMIN_GET_DEPARTMENT_USER, handleGetDepartmentWithUsers),
  ]);
}

function* handleAddDepartment(action: any) {
  const { data }: AxiosResponse = yield call(
    addDepartmentService,
    action.payload.department
  );
  if (data) {
    showNotification("success", "Department added successfully");
    yield put(getDepartmentUser());
  }
}

function* handleGetDepartment() {
  const { data }: AxiosResponse = yield call(getDepartmentService);
  if (data) {
    yield put(setDepartment({ departments: data }));
  }
}

function* handleGetDepartmentById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getDepartmentByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setDepartmentById({ department: data }));
  }
}

function* handleDeleteDepartment(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteDepartmentByIdService,
    action.payload.id
  );
  if (data) {
    yield put(getDepartmentUser());
    showNotification("success", "Department deleted successfully");
  }
}

function* handleEditDepartment(action: any) {
  const { data }: AxiosResponse = yield call(
    editDepartmentService,
    action.payload.id,
    action.payload.department
  );
  if (data) {
    yield put(getDepartmentUser());
    showNotification("success", "Department updated successfully");
  }
}

function* handleGetDepartmentWithUsers() {
  const { data }: AxiosResponse = yield call(getDepartmentUserService);
  if (data) {
    yield put(setDepartmentUser({ departments: data }));
  }
}
