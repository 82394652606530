import { produce } from "immer";

import { lessonDemandTypes as types } from "../actions";
import { LearnerLessonDemandStateType, LessonDemand } from "../../../utils";

const defaultState: LearnerLessonDemandStateType = {
  lessonDemands: [],
  lessonDemand: null,
  lessonDemandsLoaded: false,
  currentlessonDemandLoaded: false,
  loading: false,
};

const handleSetLessonDemands = (
  state: LearnerLessonDemandStateType,
  payload: any
) => {
  state.lessonDemands = payload.lessonDemands;
  state.loading = false;
  state.lessonDemandsLoaded = true;
  return state;
};

const handleSetLessonDemandById = (
  state: LearnerLessonDemandStateType,
  payload: any
) => {
  state.lessonDemand = payload.lessonDemand;
  state.loading = false;
  state.currentlessonDemandLoaded = true;
  return state;
};

const handleSetChangeResolvedLessonDemand = (state: any, { id }: any) => {
  state.lessonDemands = state.lessonDemands.map((lessonDemand: LessonDemand) =>
    lessonDemand.id === id
      ? { ...lessonDemand, isResolved: !lessonDemand.isResolved }
      : lessonDemand
  );
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: LearnerLessonDemandStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_LESSON_DEMANDS:
        return handleSetLessonDemands(state, action.payload);
      case types.SET_LESSON_DEMAND_BY_ID:
        return handleSetLessonDemandById(state, action.payload);
      case types.SET_CHANGE_RESOLVED_STATUS_LESSON_DEMAND:
        return handleSetChangeResolvedLessonDemand(state, action.payload);
      case types.GET_LESSON_DEMANDS:
        return {
          ...state,
          loading: true,
        };
      case types.GET_LESSON_DEMAND_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
