import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  selectDiscussionLoaded,
  selectDiscussionLoading,
  selectDiscussions,
} from "../../../../redux/learner/selector/discussion";
import {
  deleteDiscussionById,
  deleteNewsFeed,
  getDiscussions,
  getLearnerNewsFeed,
} from "../../../../redux/learner/actions";
import { selectLearnerDiscussionsNewsFeed } from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  discussions: selectDiscussions(state),
  discussionsNewsFeed: selectLearnerDiscussionsNewsFeed(state),
  loading: selectDiscussionLoading(state),
  loaded: selectDiscussionLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getDiscussions,
      deleteDiscussionById,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
