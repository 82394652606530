import { produce } from "immer";

import { adminCompanyInfoTypes as types } from "../actions";
import { CompanyStateType } from "../../../utils/types/Educator/company/CompanyState";

const defaultState: CompanyStateType = {
  companyInfo: {
    id: "",
    name: "",
    imageKey: "",
    createdAt: "",
    updatedAt: "",
  },
};

const handleSetCompanyInfo = (
  state: CompanyStateType,
  payload: CompanyStateType
) => {
  state.companyInfo = payload.companyInfo;
  return state;
};

export default produce((state: CompanyStateType = defaultState, action) => {
  switch (action.type) {
    case types.ADMIN_SET_COMPANY_INFO_BY_ID:
      return handleSetCompanyInfo(state, action.payload);
    default:
      return state;
  }
});
