import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Card, Form, Input, Select } from "antd";
import EducatorSelector from "../../../shared/EducatorSelector";

const { Text } = Typography;
const { TextArea } = Input;

const CreateLessonDemandScreen = (props: any) => {
  const {
    actions: { createLessonDemand },
  } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedEducators, setSelectedEducators] = useState<string[]>([]);

  const handleEducatorChange = (educators: string[]) => {
    setSelectedEducators(educators);
  };

  const onFinish = () => {
    createLessonDemand({
      lessonDemand: { title, description, assignedTo: selectedEducators[0] },
      navigate,
    });
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        if (!selectedEducators.length) throw Error;
        setSubmittable(true);
      })
      .catch(() => setSubmittable(false));
  }, [form, values, selectedEducators]);

  return (
    <>
      <div className="xl:flex  pb-[60px]">
        <div className="xl:w-1/3 md:pb-4 xl:p-0">
          <div className="flex flex-col flex-wrap gap-4 max-w-[500px] xl:max-w-[240px]">
            <Text className="text-neutral-500 font-normal text-base">
              If you would like for Educator to prepare a specific Lesson you
              can demand a lesson by filling the information on the right.
            </Text>
          </div>
        </div>
        <div className="xl:w-2/3">
          <Card className="p-8 shadow-md xl:max-w-[600px] border-none">
            <div className="h-full flex flex-col justify-between">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                size="large"
              >
                <Text className="text-neutral-600 text-sm font-semibold">
                  Title
                </Text>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: "Please add title!" }]}
                  className="mt-2 discussion-input"
                >
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                    name="title"
                    placeholder="Type here"
                    className="shadow-md rounded-lg"
                  />
                </Form.Item>

                <Text className="text-neutral-600 text-sm font-semibold">
                  Select Educator
                </Text>
                <EducatorSelector
                  onSelectionChange={handleEducatorChange}
                  selectedOptions={selectedEducators}
                  setSelectedOptions={setSelectedEducators}
                  isSingleSelect={true}
                />

                <Text className="text-neutral-600 text-sm font-semibold">
                  Add Description
                </Text>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please add description!",
                    },
                  ]}
                  className="mt-2 discussion-input"
                >
                  <TextArea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Type demand description here"
                    autoSize={{ minRows: 6, maxRows: 10 }}
                    className="shadow-md font-light"
                  />
                </Form.Item>

                <Form.Item>
                  <div className="w-full flex justify-between gap-4">
                    <Button
                      onClick={() => navigate("/user/lesson-demand")}
                      className="w-full bg-white h-[50px] border border-primary-200 text-primary-600"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full bg-primary-600 h-[50px]"
                      disabled={!submittable}
                    >
                      Continue
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CreateLessonDemandScreen;
